/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useFormik, setNestedObjectValues } from 'formik';
import { CircularProgress, MenuItem } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import { format, parse } from 'date-fns';
import { modalErro } from '../../shared/Modals/modalError/modal-erro';

import { ModalCessaoDireitos } from './components/ModalCessaoDireitos';

import { IAutor } from '../../shared/interfaces/autor.interface';

import validator from '../../validators/AutorValidacao';

import { IconeVisualizarSenha, IconeVisualizarSenhaSelect } from '../../assets/icons';

import { AESwitch, AESelect, AEButton, ModalService, AETextFormikField, AETextField } from '../../shared/components';
import { obterUFs } from '../../services/uf.service';
import { criarAutor } from '../../services/autores/autor.service';
import {
	EstadoCivil,
	PerfilDeAutorInput,
	Profissao,
	useGetEstadoCivisLazyQuery,
	useGetProfissoesLazyQuery,
} from '../../generated/graphql';

import './styles.scss';
import { modalSucesso } from '../../shared/Modals/modalSucesso/modal-sucesso';

const defaultAutor = {
	id: '',
	created_at: '',
	updated_at: '',
	nome_completo: '',
	nome_exibicao: '',
	CPF: '',
	temCPF: true,
	documentType: '',
	documentNumber: '',
	email: '',
	autoriza_divulgacao_email: false,
	cargo: '',
	link_do_curriculo: '',
	autoriza_divulgacao_link: false,
	foto: undefined,
	exibirFoto: false,
	data_nascimento: '',
	estado_civil: '',
	nacionalidade: '',
	profissao: '0',
	CRM: '',
	UF: '0',
	CEP: '',
	endereco: '',
	numero: '',
	complemento: '',
	cidade: '',
	estado: '',
	celular: '',
	temCelularBrasil: true,
	senha: '',
	confirmarSenha: '',
	nome_projeto: '',
	link_projeto: '',
	aceiteTermo: false,
	isInvalidCep: false,
	isMedico: false,
};

export const NovaContaPage = () => {
	const [mostrarSenha, setMostrarSenha] = useState(false);
	const [mostrarConfirmarSenha, setMostrarConfirmarSenha] = useState(false);

	const [profissoes, setProfissoes] = useState<Profissao[]>([]);
	const [estadoCivis, setEstadoCivis] = useState<EstadoCivil[]>([]);
	const [ufs] = useState(obterUFs());
	const [habilitaEndereco, setHabilitaEndereco] = useState(false);
	const [aceite, setAceite] = useState(false);
	const [buscandoCep, setBuscandoCep] = useState(false);
	const { search: searchParams } = useLocation();

	const history = useHistory();

	const [getProfissoes] = useGetProfissoesLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!response.profissaos) {
				return;
			}

			const _profissoes: Profissao[] = response.profissaos?.map((item) => {
				return {
					id: item.id,
					titulo: item.titulo,
					PossuiCRM: item.PossuiCRM,
				} as Profissao;
			});

			setProfissoes(_profissoes);

			formik.setValues((values) => {
				values.PerfilProfissao = '0';
				return values;
			});
		},
	});

	const [getEstadoCivis] = useGetEstadoCivisLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!response.estadoCivils) {
				return;
			}

			const _estadoCivis: EstadoCivil[] = response.estadoCivils?.map((item) => {
				return {
					id: item.id,
					titulo: item.titulo,
				} as EstadoCivil;
			});

			setEstadoCivis(_estadoCivis);

			formik.setValues((values) => {
				values.estado_civil = '0';
				return values;
			});
		},
	});

	const buscarPorCEP = (cep: string) => {
		setBuscandoCep(true);
		return fetch(`https://meueinstein.einstein.br:35007/api/appointmentscheduler/api/drivethru/user/cep/${cep}`)
			.then((response) => response.json())
			.catch((error) => console.error(error))
			.finally(() => setBuscandoCep(false));
	};

	useEffect(() => {
		getProfissoes();
	}, []);

	useEffect(() => {
		getEstadoCivis();
	}, []);

	const formik = useFormik<IAutor>({
		initialValues: defaultAutor,
		validationSchema: validator.criarSchemaValidacao(),
		onSubmit: async (values: PerfilDeAutorInput) => {
			try {
				const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

				delete (values as any).isMedico;
				delete (values as any).isInvalidCep;

				let dataNascimento = values.data_nascimento;

				/** dd/MM/yyyy */
				const dataNascimentoRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
				if (typeof values.data_nascimento === 'string' && !!dataNascimentoRegex.test(values.data_nascimento)) {
					dataNascimento = parse(values.data_nascimento, 'dd/MM/yyyy', new Date());
					dataNascimento = format(dataNascimento, DATE_FORMAT);
				}

				const splitedAceite = searchParams && searchParams.includes('=') ? searchParams.split('=')[1] : '';

				const response = await criarAutor({
					...values,
					Autoriza_termo_direito: aceite,
					data_termo: format(new Date(), DATE_FORMAT),
					data_nascimento: dataNascimento,
					autorConvidado: splitedAceite,
				});

				if (!!response.statusCode && response.statusCode !== 200) {
					throw new Error();
				}

				modalSucesso({
					titulo: 'Cadastro realizado com sucesso.',
					mensagens: ['Realize o seu login para acessar a sua conta'],
					callback: () => {
						history.push('/');
					},
				});
			} catch (error) {
				modalErro('Ocorreu algum problema durante o seu cadastro.', error);
			}
		},
	});

	const showModalCessaoDireitos = () => {
		ModalService.show({
			content: (
				<ModalCessaoDireitos
					aceiteStatus={aceite}
					handleAceite={async (valor: boolean) => {
						if (!valor) return;

						setAceite(valor);

						await formik.submitForm();
					}}
				/>
			),
		});
	};

	useEffect(() => {
		const _ehMedico = profissoes.find(
			(profile) => profile.id.toString() === formik.values.PerfilProfissao && profile.PossuiCRM,
		);

		formik.setValues({
			...formik.values,
			isMedico: !!_ehMedico ? true : false,
			CRM: !!_ehMedico ? '' : formik.values.CRM,
			UF: !!_ehMedico ? '0' : formik.values.UF,
		});
	}, [formik.values.PerfilProfissao]);

	return (
		<div data-nova-conta-page data-form-page>
			<h2 data-title>Criar conta</h2>

			<form data-form>
				<div data-col>
					<AETextFormikField
						id="nome_completo"
						placeholder="Nome completo"
						label="Nome*"
						data-width="100"
						formik={formik}
					/>
					<div data-inner-row-right data-width="100">
						<span data-info>{formik.values.nome_exibicao.length}/145</span>
					</div>
				</div>

				<div data-col>
					<div data-row data-width="100">
						<AETextFormikField
							id="nome_exibicao"
							placeholder="Nome que aparecerá na Academia"
							label="Nome para divulgação*"
							formik={formik}
							data-width="nome-exibicao"
						/>

						{!!formik.values.temCPF ? (
							<InputMask
								mask="999.999.999-99"
								name="CPF"
								id="CPF"
								onBlur={formik.handleBlur}
								value={formik.values.CPF}
								onChange={formik.handleChange}
								alwaysShowMask={false}
								className={formik.errors.CPF && 'colorError'}
							>
								{() => (
									<AETextField
										label="CPF*"
										data-form-row-cpf
										placeholder="Seu CPF sem pontos e traços"
										className={formik.errors.CPF && 'colorError'}
										data-width="cpf"
										data-form-control
										name="CPF"
										id="CPF"
										onBlur={formik.handleBlur}
										error={Boolean(formik.touched.CPF ? formik.errors.CPF : null)}
										helperText={formik.touched.CPF && formik.errors.CPF ? formik.errors.CPF : null}
									/>
								)}
							</InputMask>
						) : (
							<div data-row>
								<AETextFormikField
									id="documentType"
									label="Nome do Documento*"
									data-form-control
									data-width="33"
									formik={formik}
								/>

								<AETextFormikField
									id="documentNumber"
									label="Número do Documento*"
									data-form-control
									data-width="33"
									formik={formik}
								/>
							</div>
						)}
					</div>

					<div data-inner-row-right data-width="100">
						{!!formik.values.temCPF ? (
							<AEButton
								variant="text"
								color="primary"
								data-toggle-btn
								onClick={() => {
									formik.setTouched(
										setNestedObjectValues(
											[formik.values.CPF, formik.values.documentNumber, formik.values.documentType],
											false,
										),
									);
									formik.setValues({
										...formik.values,
										CPF: '',
										documentNumber: '',
										documentType: '',
										temCPF: false,
									});
								}}
							>
								Não tenho CPF
							</AEButton>
						) : (
							<AEButton
								variant="text"
								color="primary"
								data-toggle-btn
								onClick={() => {
									formik.setTouched(
										setNestedObjectValues(
											[formik.values.CPF, formik.values.documentNumber, formik.values.documentType],
											false,
										),
									);
									formik.setValues({
										...formik.values,
										CPF: '',
										documentNumber: '',
										documentType: '',
										temCPF: true,
									});
								}}
							>
								Tenho CPF
							</AEButton>
						)}
					</div>
				</div>

				<div data-row>
					<AETextFormikField
						id="email"
						placeholder="meuemail@einstein.br"
						label="E-mail*"
						type="email"
						data-width="100"
						data-form-control
						formik={formik}
					/>

					<div data-switch-btn className={formik.errors.autoriza_divulgacao_email && 'marginError'}>
						<AESwitch
							color="primary"
							id="autoriza_divulgacao_email"
							name="autoriza_divulgacao_email"
							checked={formik.values.autoriza_divulgacao_email}
							onChange={formik.handleChange}
						></AESwitch>
						<span data-label>Mostrar na Academia</span>
					</div>
				</div>

				<div data-col>
					<AETextFormikField id="cargo" label="Cargo*" data-width="100" data-form-control formik={formik} />

					<p data-width="100" data-info>
						Informe o cargo simplificado, não é necessário informar o nome do Einstein, Hospital, HIAE ou SBIBAE. Ex.:
						Médico Cardiologista
					</p>
				</div>

				<div data-col>
					<div data-row>
						<AETextFormikField
							id="link_do_curriculo"
							label="Link do Currículo"
							type="text"
							data-width="100"
							data-form-control
							formik={formik}
						/>

						<div data-switch-btn className={formik.errors.autoriza_divulgacao_link && 'marginError'}>
							<AESwitch
								color="primary"
								id="autoriza_divulgacao_link"
								name="autoriza_divulgacao_link"
								checked={formik.values.autoriza_divulgacao_link}
								onChange={formik.handleChange}
							></AESwitch>
							<span data-label>Mostrar na Academia</span>
						</div>
					</div>

					<p data-width="100" data-info>
						Link do Linkedin, Google Schoolar, Plataforma Lattes ou sua página pessoal/consultório
					</p>
				</div>

				<div data-row>
					<InputMask
						mask="99/99/9999"
						name="data_nascimento"
						id="data_nascimento"
						onBlur={formik.handleBlur}
						value={formik.values.data_nascimento}
						onChange={formik.handleChange}
						alwaysShowMask={false}
						className={formik.errors.data_nascimento && 'colorError'}
					>
						{() => (
							<AETextField
								id="data_nascimento"
								name="data_nascimento"
								data-form-control
								data-width="50"
								label="Data de Nascimento*"
								onBlur={formik.handleBlur}
								error={Boolean(formik.touched.data_nascimento ? formik.errors.data_nascimento : null)}
								helperText={
									formik.touched.data_nascimento && formik.errors.data_nascimento ? formik.errors.data_nascimento : null
								}
							/>
						)}
					</InputMask>

					<div data-form-control data-width="50">
						<label>Estado Civil*</label>
						<AESelect
							name="estado_civil"
							variant="outlined"
							value={formik.values.estado_civil}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							error={Boolean(formik.touched.estado_civil ? formik.errors.estado_civil : null)}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value="0">Selecione</MenuItem>
							{estadoCivis.map((item, idx) => (
								<MenuItem key={idx} value={item.id} selected={item.id.toString() === formik.values.estado_civil}>
									{item.titulo}
								</MenuItem>
							))}
						</AESelect>
					</div>
				</div>

				<div data-row>
					<AETextFormikField
						id="nacionalidade"
						label="Nacionalidade*"
						data-form-control
						data-width="50"
						formik={formik}
					/>

					<div data-form-control data-width="50">
						<label>Profissão</label>
						<AESelect
							name="PerfilProfissao"
							variant="outlined"
							value={formik.values.PerfilProfissao || ''}
							onChange={formik.handleChange}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value="0">Selecione</MenuItem>
							{profissoes.map((item, idx) => (
								<MenuItem key={idx} value={item.id}>
									{item.titulo}
								</MenuItem>
							))}
						</AESelect>
					</div>
				</div>

				{formik.values.isMedico && (
					<div data-row>
						<AETextFormikField id="CRM" label="CRM" data-form-control data-width="50" formik={formik} />

						<div data-form-control data-width="50">
							<label>UF</label>
							<AESelect
								name="UF"
								variant="outlined"
								value={formik.values.UF}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value="0">Selecione</MenuItem>
								{ufs.map((item, idx) => (
									<MenuItem key={idx} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</AESelect>
						</div>
					</div>
				)}

				<div data-row data-width="100">
					<InputMask
						mask="99999-999"
						name="CEP"
						id="CEP"
						onBlur={formik.handleBlur}
						value={formik.values.CEP}
						disabled={buscandoCep}
						data-cep
						data-width="cep"
						onChange={(ev) => {
							ev.stopPropagation();
							const { name, value } = ev.target;
							const _cep = value.replace(/_/g, '');

							formik.setValues((values: any) => {
								values[name] = _cep;
								return values;
							});

							if (_cep.length < 9) {
								return;
							}

							formik.setValues((values: any) => {
								values.endereco = '';
								values.cidade = '';
								values.estado = '';
								values.numero = '';
								values.complemento = '';
								return values;
							});

							buscarPorCEP(_cep)
								.then((promise) => promise)
								.then((response) => {
									const endereco = response.value;

									if (!endereco.cep) {
										//setHabilitaEndereco(true);
										formik.setValues({
											...formik.values,
											isInvalidCep: true,
										});
										return;
									}

									setHabilitaEndereco(false);

									formik.setValues((values: any) => {
										values.endereco = endereco.logradouro;
										values.cidade = endereco.localidade;
										values.estado = endereco.uf;
										values.isInvalidCep = false;
										return values;
									});
								})
								.then((response) => setBuscandoCep(false))
								.catch(() => setHabilitaEndereco(true));
						}}
						alwaysShowMask={false}
						className={formik.errors.CEP && 'colorError'}
					>
						{() => (
							<div data-form-row-cep data-width="cep">
								<AETextField
									data-form-row-cep
									data-width="cep"
									label="CEP*"
									className={formik.errors.CEP && 'colorError'}
									name="CEP"
									id="CEP"
									onBlur={formik.handleBlur}
									error={Boolean(formik.touched.CEP ? formik.errors.CEP : null)}
									helperText={formik.touched.CEP && formik.errors.CEP ? formik.errors.CEP : null}
									disabled={buscandoCep}
								>
									{!!buscandoCep && <CircularProgress data-loading />}
								</AETextField>
							</div>
						)}
					</InputMask>

					<AETextFormikField
						id="endereco"
						label="Rua*"
						disabled={!habilitaEndereco}
						data-width="rua"
						data-form-control
						formik={formik}
					/>
				</div>

				<div data-row>
					<AETextFormikField
						id="numero"
						label="Número*"
						type="number"
						data-width="cep"
						data-form-control
						formik={formik}
					/>

					<AETextFormikField id="complemento" label="Complemento" data-width="rua" data-form-control formik={formik} />
				</div>

				<div data-col>
					<div data-row data-width="100">
						<AETextFormikField
							id="cidade"
							label="Cidade*"
							disabled={!habilitaEndereco}
							data-width="cidade"
							data-form-control
							formik={formik}
						/>

						<AETextFormikField
							id="estado"
							label="Estado*"
							disabled={!habilitaEndereco}
							data-width="estado"
							data-form-control
							formik={formik}
						/>

						{formik.values.temCelularBrasil ? (
							<InputMask
								mask="(99) 9 9999-9999"
								name="celular"
								id="celular"
								onBlur={formik.handleBlur}
								value={formik.values.celular}
								onChange={formik.handleChange}
								alwaysShowMask={false}
								className={formik.errors.celular && 'colorError'}
							>
								{() => (
									<AETextField
										label="Celular*"
										data-width="telefone"
										data-form-control
										className={formik.errors.celular && 'colorError'}
										name="celular"
										id="celular"
										onBlur={formik.handleBlur}
										error={Boolean(formik.touched.celular ? formik.errors.celular : null)}
										helperText={formik.touched.celular && formik.errors.celular ? formik.errors.celular : null}
									/>
								)}
							</InputMask>
						) : (
							<InputMask
								mask="+55 999 999 9999"
								name="celular"
								id="celular"
								onBlur={formik.handleBlur}
								value={formik.values.celular}
								onChange={formik.handleChange}
								alwaysShowMask={false}
								className={formik.errors.celular && 'colorError'}
							>
								{() => (
									<AETextField
										label="Telefone Internacional*"
										data-width="telefone"
										data-form-control
										className={formik.errors.celular && 'colorError'}
										name="celular"
										id="celular"
										onBlur={formik.handleBlur}
										error={Boolean(formik.touched.celular ? formik.errors.celular : null)}
										helperText={formik.touched.celular && formik.errors.celular ? formik.errors.celular : null}
									/>
								)}
							</InputMask>
						)}
					</div>

					<div data-inner-row-right data-width="100">
						{!!formik.values.temCelularBrasil ? (
							<AEButton
								variant="text"
								color="primary"
								data-toggle-btn
								onClick={() => {
									formik.setTouched(setNestedObjectValues([formik.values.celular], false));
									formik.setValues({
										...formik.values,
										celular: '',
										temCelularBrasil: false,
									});
								}}
							>
								Não tenho telefone do país (Brasil)
							</AEButton>
						) : (
							<AEButton
								variant="text"
								color="primary"
								data-toggle-btn
								onClick={() => {
									formik.setTouched(setNestedObjectValues([formik.values.celular], false));
									formik.setValues({
										...formik.values,
										celular: '',
										temCelularBrasil: true,
									});
								}}
							>
								Tenho telefone do país (Brasil)
							</AEButton>
						)}
					</div>
				</div>

				<div data-col>
					<div data-row data-password>
						<div data-form-control data-password-field data-width="50">
							<AETextFormikField
								id="senha"
								data-width="50"
								data-form-control
								type={mostrarSenha ? 'text' : 'password'}
								label="Criar senha*"
								formik={formik}
							>
								<img
									onClick={() => setMostrarSenha(!mostrarSenha)}
									data-toggle-password
									src={mostrarSenha ? IconeVisualizarSenhaSelect : IconeVisualizarSenha}
									alt="teste"
									width={20}
									height={20}
								/>
							</AETextFormikField>
						</div>

						<div data-form-control data-password-field data-width="50">
							<AETextFormikField
								id="confirmarSenha"
								data-width="50"
								data-form-control
								data-password-field
								type={mostrarConfirmarSenha ? 'text' : 'password'}
								label="Confirmar senha*"
								formik={formik}
							>
								<img
									onClick={() => setMostrarConfirmarSenha(!mostrarConfirmarSenha)}
									data-toggle-password
									src={mostrarConfirmarSenha ? IconeVisualizarSenhaSelect : IconeVisualizarSenha}
									alt="teste"
									width={20}
									height={20}
								/>
							</AETextFormikField>
						</div>
					</div>

					<p data-width="100" data-info>
						Sua senha deve conter no mínimo 8 caracteres, com número, letras minúsculas maiúscula, letras maiúsculas e
						caracteres especiais
					</p>
				</div>

				<span data-separator data-width="100"></span>

				<h2 data-title>
					Você faz parte de algum projeto institucional, como Projeto Hematolog? <br />
					Se sim, informe abaixo. Os dados serão exibidos junto ao seu nome.
				</h2>

				<div data-row>
					<AETextFormikField
						id="nome_projeto"
						label="Nome do Projeto"
						data-width="50"
						data-form-control
						formik={formik}
					/>

					<AETextFormikField
						id="link_projeto"
						label="Link do Projeto"
						data-width="50"
						data-form-control
						formik={formik}
					/>
				</div>

				<div data-row data-width="100" data-row-right>
					<AEButton
						id="btn-envia-informacoes"
						type="submit"
						onClick={async (e) => {
							e.preventDefault();

							await formik.validateForm();

							if (Object.keys(formik.errors).length > 0) {
								formik.setTouched(setNestedObjectValues(formik.errors, true));
								return;
							}

							showModalCessaoDireitos();
						}}
						color="primary"
						variant="contained"
					>
						Enviar informações
					</AEButton>
				</div>
			</form>
		</div>
	);
};
