import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Header, MenuVertical, Footer } from '../';
import { useAuth } from '../../contexts/Auth';
import useRedirect from '../../services/utils/redirect';
import { useUsuarioService } from '../../shared/services/Usuario';

import './styles.scss';

interface ContainerPageProps {}

export const ContainerPage: React.FC<ContainerPageProps> = (props) => {
	const { usuarioLogado, setArea } = useAuth();
	const [openMenu, setOpenMenu] = useState(true);
	const history = useHistory();
	const redirectAfterLogin = useRedirect();
	const service = useUsuarioService();

	useEffect(() => {
		const actualPage = window.location.href;

		try {
			if (
				!!usuarioLogado &&
				!usuarioLogado.avatarLater &&
				!usuarioLogado.avatar &&
				!actualPage.includes('anexo-de-foto')
			) {
				history.push('/anexo-de-foto');
				return;
			}

			redirectAfterLogin();
		} catch (error) {
			service.logout();
		}

		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Header
				data-header-principal
				usuarioLogado={usuarioLogado}
				setArea={setArea}
				setOpenMenu={(value) => {
					setOpenMenu(value);
				}}
				openMenu={openMenu}
			/>

			<main data-container-page>
				{!!usuarioLogado && <MenuVertical open={openMenu}></MenuVertical>}
				<div data-page-wrapper>
					<div data-wrapper-content-principal>{props.children}</div>
				</div>
			</main>

			<Footer></Footer>
		</>
	);
};
