import React from 'react';

// import DangerIcon from "../../../";

import "./styles.scss";

type MessageType = 'danger' | 'info' | 'success' | 'warn';

interface MessageBoxProps {
	type: MessageType;
	data: string;
}

export const MessageBox: React.FC<MessageBoxProps> = (props) => {

		// const renderIcon = (type:MessageType) => {
		// 	switch(type) {
		// 		case 'danger': 
		// 			return <DangeIcon />
		// 		case 'info': 
		// 			return <InfoIcon />
		// 		case 'success': 
		// 			return <SuccessIcon />
		// 		default: 
		// 			return <WarnIcon />
		// 	}	
		// }

	return <div data-message-box={props.type}>
		{/* {renderIcon(props.type)} */}
		{props.data}
	</div>;
};
