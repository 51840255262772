export const handleRequest = (promise: Promise<Response>) => {
  return promise
    .then((response) => {
      if (response.status !== 200) {
        return response
          .json()
          .then((response) => Promise.reject(response.message));
      }

      return Promise.resolve(response.json());
    })
    .catch((error) => Promise.reject(error));
};
