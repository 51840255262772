import { isValid } from "date-fns";
import { parse } from "date-fns";
import * as Yup from "yup";
import { validarCPF } from "./util/validarCpf";
import { validarURL } from "./util/validarUrl";

class AutorValidacao {
  criarSchemaValidacao() {
    return Yup.object().shape({
      nome_completo: Yup.string()
        .required("*campo obrigatório")
        .max(145, "*tamanho excedido")
        .matches(/^[aA-zZ-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]+$/, "*caracteres especiais não são permitidos"),
      nome_exibicao: Yup.string()
        .required("*campo obrigatório")
        .matches(/^[aA-zZ-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]+$/, "*caracteres especiais não são permitidos"),
      CPF: Yup.string().when("temCPF", {
        is: true,
        then: Yup.string()
          .required("*campo obrigatório")
          .test({
            message: "*documento inválido",
            test: (Cpf) => validarCPF(Cpf),
          }),
      }),
      documentNumber: Yup.string().when("temCPF", {
        is: false,
        then: Yup.string().required("*campo obrigatório"),
      }),
      documentType: Yup.string().when("temCPF", {
        is: false,
        then: Yup.string().required("*campo obrigatório"),
      }),
      email: Yup.string()
        .email("*formato inválido")
        .required("*campo obrigatório"),
      cargo: Yup.string()
        .required("*campo obrigatório")
        .matches(/^[aA-zZ-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]+$/, "*caracteres especiais não são permitidos"),
      link_do_curriculo: Yup.string().test({
        message: "*link inválido",
        test: (linkCurriculo) =>
          linkCurriculo ? validarURL(linkCurriculo || "") : true,
      }),
      data_nascimento: Yup.date()
        .transform((value, originalValue) => {
          try {
            const regexDataNascimento =
            //eslint-disable-next-line
              /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

            if (!regexDataNascimento.test(originalValue)) {
              return new Date();
            }

            const parsedDate = parse(originalValue, "dd/MM/yyyy", new Date());

            if (parsedDate.toString() === "Invalid Date") {
              return new Date();
            }

            const validated = isValid(parsedDate) ? parsedDate : new Date();

            return validated;
          } catch (error) {
            return new Date();
          }
        })
        .test({
          message: "não pode ser menor de 18 anos",
          test: (value) => {
            if (!value) {
              return false;
            }

            const anoDataNascimento = value.getFullYear();
            const anoAtual = new Date().getFullYear();

            const totalAnos = anoAtual - anoDataNascimento;

            return totalAnos >= 18 && totalAnos <= 100;
          },
        }),
      estado_civil: Yup.string().required("*campo obrigatório"),
      nacionalidade: Yup.string()
        .required("*campo obrigatório")
        .matches(/^[aA-zZ\s]+$/, "*caracteres especiais não são permitidos"),
      PerfilProfissao: Yup.string().required("*campo obrigatório"),
      CRM: Yup.string().when("isMedico", {
        is: true,
        then: Yup.string().required("*campo obrigatório"),
      }),
      CEP: Yup.string()
        .required("*campo obrigatório")
        .test({
          message: "*cep inválido",
          test: (CEP) =>
            CEP && CEP.replace(/[^a-z0-9]/gi, "").length === 8 ? true : false,
        })
        .when("isInvalidCep", {
          is: true,
          then: Yup.string().test({
            message: "*cep inválido",
            test: () => {
              return false;
            },
          }),
        }),
      endereco: Yup.string().required("*campo obrigatório"),
      numero: Yup.number().required("*campo obrigatório"),
      cidade: Yup.string().required("*campo obrigatório"),
      estado: Yup.string()
        .required("*campo obrigatório")
        .matches(/^[aA-zZ\s]+$/, "*caracteres especiais não são permitidos"),
      celular: Yup.string()
        .required("*campo obrigatório")
        .when("temCelularBrasil", {
          is: true,
          then: Yup.string().test({
            message: "*número inválido",
            test: (value) => {
              return (
                //eslint-disable-next-line
                !!value && /^\([1-9]{2}\) 9 ([0-9]{4}\-[0-9]{4})$/.test(value)
              );
            },
          }),
        })
        .when("temCelularBrasil", {
          is: false,
          then: Yup.string().test({
            message: "*número inválido",
            test: (value) => {
              return (
                !!value && /^\+(\d{2}) (\d{3}) (\d{3}) (\d{4})$/.test(value)
              );
            },
          }),
        }),
      senha: Yup.string()
        .required("*campo obrigatório")
        .test({
          message: "*necessário um caractere minúsculo",
          test: (value) => {
            return !!value && /[a-z]/.test(value);
          },
        })
        .test({
          message: "*necessário um caractere maiúsculo",
          test: (value) => {
            return !!value && /[A-Z]/.test(value);
          },
        })
        .test({
          message: "*necessário um caractere numérico",
          test: (value) => {
            return !!value && /[0-9]/.test(value);
          },
        })
        .test({
          message: "*necessário um caractere especial",
          test: (value) => {
            return (
              !!value &&
              /(!|@|#|\$|%|ˆ|&|\*|\+|=|\)|\(|\\}|\\{|\[|\\]|\?|<|>)/.test(value)
            );
          },
        })
        .min(8, "*necessário ao menos 8 caracteres"),
      confirmarSenha: Yup.string()
        .required("*campo obrigatório")
        .oneOf([Yup.ref("senha"), null], "*senhas não coincidem"),
    });
  }
}

export default new AutorValidacao();
