export interface Estado {
  id: string;
  name: string;
}

export const obterUFs = () => {
  const estados: Estado[] = [
    { id: "AC", name: "AC" },
    { id: "AL", name: "AL" },
    { id: "AM", name: "AM" },
    { id: "AP", name: "AP" },
    { id: "BA", name: "BA" },
    { id: "CE", name: "CE" },
    { id: "DF", name: "DF" },
    { id: "ES", name: "ES" },
    { id: "GO", name: "GO" },
    { id: "MA", name: "MA" },
    { id: "MG", name: "MG" },
    { id: "MS", name: "MS" },
    { id: "MT", name: "MT" },
    { id: "PA", name: "PA" },
    { id: "PB", name: "PB" },
    { id: "PE", name: "PE" },
    { id: "PI", name: "PI" },
    { id: "PR", name: "PR" },
    { id: "RJ", name: "RJ" },
    { id: "RN", name: "RN" },
    { id: "RO", name: "RO" },
    { id: "RR", name: "RR" },
    { id: "RS", name: "RS" },
    { id: "SC", name: "SC" },
    { id: "SE", name: "SE" },
    { id: "SP", name: "SP" },
    { id: "TO", name: "TO" },
  ];

  return estados;
};
