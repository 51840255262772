/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { ConvitePendentesPayload, ConvitePendentesData, useGetConvitesPendenteLazyQuery } from '../generated/graphql';

export const useConvitesPendentes = () => {
	const [dataResult, setResult] = useState<ConvitePendentesPayload>({
		total: 0,
		data: [] as ConvitePendentesData[],
	});

	const [search] = useGetConvitesPendenteLazyQuery({
		onCompleted: (response) => {
			const total = response.findConvitesPendente?.total || 0;
			const data = response.findConvitesPendente?.data || [];
			setResult({ total, data });
		},
	});

	useEffect(() => {
		search();
	}, []);

	return {dataResult};
};
