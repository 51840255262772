import React from "react";

interface InfoFieldProps {
  desktop: string;
  mobile: string;
  length?: number;
  maxLength?: number;
}

export const InfoField: React.FC<InfoFieldProps> = (props) => {
  return (
    <div data-inner-row data-justify-content="spaceBetween" data-info>
      <span data-viewer-device="desktop" data-info>
        {props.desktop}
      </span>
      <span data-viewer-device="mobile" data-info>
        {props.mobile}
      </span>
      {(!props.maxLength || props.maxLength > 0) && (
        <span data-info>
          {props.length}/{props.maxLength}
        </span>
      )}
    </div>
  );
};
