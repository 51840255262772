import React from "react";

import "./styles.scss";

export const Footer: React.FC = () => {
  return (
    <footer data-footer>
      <div data-wrapper>
        <section>
          <h3>Dúvidas, sugestões, críticas ou elogios?</h3>

          <div data-footer-row>
            <label>Telefone:</label>
            <span>55 11 2151-3732</span>
          </div>

          <div data-footer-row>
            <label>E-mail:</label>
            <span>academia.digital@einstein.br</span>
          </div>
        </section>

        <section>
          <h3>Horários de Atendimento</h3>

          <div data-footer-row>
            <label>Segunda a sexta-feira: 07h às 20h.</label>
            <label>Sábado: 07h às 13h.</label>
            <label>Domingo e Feriados: Não há atendimento.</label>
          </div>
        </section>
      </div>
    </footer>
  );
};
