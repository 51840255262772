import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";

import debounce from "../../../../../../shared/commom/debounceEvent";
import { useMeusConteudosContext } from "../../../../../../contexts/MeusConteudos";

interface AutoSearchOptions {
  id: string;
  label: string;
}

const style: any = {
  root: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    right: "52px",
  },
};

const useStyles = makeStyles(() => ({
  ...style,
}));

export const AutoSearch: React.FC = () => {
  const styleClasses = useStyles();

  const [list, setList] = useState<AutoSearchOptions[]>([]);
  const { handleChange } = useMeusConteudosContext()

  useEffect(() => {
    const autoSearch = document.querySelector("#auto-search");
    autoSearch?.addEventListener("keyup", debounce(handleInputChange, 500));
  }, []);

  function handleInputChange(ev: any) {
    ev.preventDefault();

    handleChange("keyWord", ev.target.value);
  }

  return (
    <Autocomplete
      id="auto-search"
      options={list}
      open={true}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Pesquisar"
          fullWidth
          InputLabelProps={{ classes: styleClasses }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon data-svg-blue-600 />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
