import * as Yup from 'yup';

class LoginValidator {
  criarSchemaValidacao() {
    return Yup.object().shape({
      usuario: Yup.string().required('*campo obrigatório'),
      senha: Yup.string().required('*campo obrigatório'), 
      });
  }
}

export default new LoginValidator();