import { SwitchProps } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/styles';

const { disable, white } = require('../../../ds/_palette.module.scss');

/**
 * Customização do MaterialUI Switch para nossa aplicação
 */
export const AESwitch: React.ComponentType<SwitchProps> = withStyles(() =>
  createStyles({
    root: {
      width: '3.5rem',
      height: '1.8rem',
      padding: 0,
      margin: 0,

      '&.MuiSwitch-root': {
        borderRadius: '.9rem',
      },
      '&.MuiSwitch-root span': {
        opacity: 1,
      },
    },
    switchBase: {
      padding: '.3rem',

      '&$checked': {
        transform: 'translateX(17px)',
        color: white,

        '& + $track': {
          backgroundColor: '#0E65BD',

          opacity: 1,
        },
      },
    },
    thumb: {
      width: '1.2rem',
      height: '1.2rem',
      color: white,
    },
    track: {
      backgroundColor: disable,
    },
    checked: {},
  }),
)(Switch);
