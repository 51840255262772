import React, { useState, useEffect, useContext } from "react";
import { MeusConteudosState, useMeusConteudosContext } from "../../../../../../contexts/MeusConteudos";

import { useGetTotalObjetosEducacionaisAExpirarLazyQuery } from "../../../../../../generated/graphql"

interface TotalConteudosAExpirarButtonProps {
  activeButton: "Lista de Conteudos" | "A expirar";
  handleActiveButton(ev: "Lista de Conteudos" | "A expirar"): void;
}

export const TotalConteudosAExpirarButton: React.FC<TotalConteudosAExpirarButtonProps> = (props) => {
  const [totalConteudosAExpirar, setTotalConteudosAExpirar] = useState(0);
  const { setState, state } = useMeusConteudosContext();

  const [getTotal] = useGetTotalObjetosEducacionaisAExpirarLazyQuery({
    onCompleted: (response) => {
      response.findTotalObjetosEducacionaisAExpirar && 
        setTotalConteudosAExpirar(response.findTotalObjetosEducacionaisAExpirar.total);
    }
  });

  const handleLoadContentExpired = () => {
    props.handleActiveButton("A expirar");

    setState({
      ...state,
      isExpired: true
    });
  }

  useEffect(() => {
    getTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <button
      type="button"
      className={props.activeButton === "A expirar" ? "button-active" : ""}
      onClick={handleLoadContentExpired}
    >
      A expirar ({totalConteudosAExpirar})
    </button>
  );
};
