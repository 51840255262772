import { Button, CircularProgress, createStyles, withStyles } from "@material-ui/core";
import { ReactElement } from "react";
import './styles.scss';


/**
 * Customização do MaterialUI Button para nossa aplicação
 */
export const AEButton = withStyles((theme) => createStyles({
  root: {
    '&.MuiButtonBase-root': {
      borderRadius: '8rem',
      padding: '1.1rem 3.7rem',

      '&.MuiButton-textPrimary': {
        minWidth: 'unset',
        padding: 0,

        '&:hover': {
          backgroundColor: 'transparent',
        },
      }
    },
  }
}))(Button);

interface Props {
  loading: boolean;
  children: ReactElement<any, any>
}

export const AEButtonLoding: React.FC<Props> = ({
  loading,
  children
}) => {
  return (
    <div data-button-loading>
      {children}
      {loading && (
        <div data-loading>
          <CircularProgress size={14} color="primary" />
        </div>
      )}
    </div>
  );
};
