export const criarAutor = (payload: any) => {
	const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace('/graphql', '')}/perfil-de-autors/my-account`;
	return fetch(URL, {
		method: 'POST',
		headers: new Headers({ 'content-type': 'application/json' }),
		body: JSON.stringify(payload),
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
};
