import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { EtapasCadastro } from './components/EtapasCadastro';
import { DadosObjeto } from './components/DadosObjeto';
import { Anexo } from './components/Anexo';
import { Concluir } from './components/Concluir';
import { Breadcrumbs } from '../../shared/components';

import {
	useGetObjetoEducacionalEdicaoPorIdLazyQuery,
	ObjetoEducacionalFragment,
	useGetHistoricoAvaliacaoAtualOePorIdLazyQuery,
	Enum_Statusdepublicacao_Enum_Status,
} from '../../generated/graphql';
import { useAuth } from '../../contexts/Auth';

import { IconeMeusConteudosBread } from '../../assets/icons';

import Loader from '../../shared/components/Loader';

import './styles.scss';

interface ParamsInterface {
	id: string;
}

export const NovoConteudoPage: React.FC = () => {
	const { usuarioLogado } = useAuth();
	const [path, setPath] = useState('');
	const history = useHistory();
	useEffect(() => {
		const _path = window.location.pathname;
		setPath(_path);
		window.scrollTo({ top: 0, behavior: 'smooth' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname]);

	const [oeId, setOeId] = useState<string>();
	const params = useParams<ParamsInterface>();
	const [oe, setOe] = useState<ObjetoEducacionalFragment | undefined>();
	const [oeStatusRascunho, setOeStatusRascunho] = useState(false);
	const [loading, setLoading] = useState(true);
	const [autorAcesso, setAutorAcesso] = useState(false);

	const [getObjetoEducacional] = useGetObjetoEducacionalEdicaoPorIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!!response.findById) {
				response.findById.DataProducao = response.findById.DataProducao + 'T03:00:00';
				response.findById.DataAtualizacaoProducao = response.findById.DataAtualizacaoProducao + 'T03:00:00';
				response.findById.ValidadeConteudo = response.findById.ValidadeConteudo + 'T03:00:00';
				setOe(response.findById);
				getStatusOe({
					variables: {
						oeId: response.findById.id!,
					},
				});
			}
			if (!!response.findById && response.findById.Autores && oeId && !!usuarioLogado && !!usuarioLogado.autor) {
				const usuarioAutor = response.findById.Autores.find((a) => a.id === usuarioLogado.autor['id'].toString());
				!!usuarioAutor ? setAutorAcesso(true) : setAutorAcesso(false);
			}
		},
	});

	const [getStatusOe] = useGetHistoricoAvaliacaoAtualOePorIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (
				!!response.findHistoricoAvaliacaoAtualByOeId.enum_status &&
				response.findHistoricoAvaliacaoAtualByOeId.enum_status !== Enum_Statusdepublicacao_Enum_Status.EmRascunho
			) {
				history.push(`/conteudo/${oeId}/concluido`);
			}
			if (
				!!response.findHistoricoAvaliacaoAtualByOeId.enum_status &&
				response.findHistoricoAvaliacaoAtualByOeId.enum_status === Enum_Statusdepublicacao_Enum_Status.EmRascunho
			) {
				setOeStatusRascunho(true);
			}
			setLoading(false);
		},
	});

	useEffect(() => {
		if (params && params.id) {
			setOeId(params.id);
		}
		if (!params.id) {
			setLoading(false);
			setAutorAcesso(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	useEffect(() => {
		if (!!oeId) {
			getObjetoEducacional({
				variables: {
					id: oeId,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oeId]);

	return (
		<>
			{!!loading && <Loader show={!!loading} message="Aguarde enquanto o conteúdo é carregado..." />}
			{!loading && !autorAcesso && (
				<Loader
					show={!!loading}
					message="Você não possui acesso a este conteúdo, aguarde para ser redirecionado..."
					redirectUrl="/meus-conteudos"
				/>
			)}
			{!loading && !!autorAcesso && (
				<div data-novo-conteudo-page data-form-page>
					<Breadcrumbs
						icon={IconeMeusConteudosBread}
						menus={[
							{ name: 'Meus Conteúdos', link: '/meus-conteudos' }, 
							{ name: !oeId ? 'Criar conteúdo' : 'Editar conteúdo', active: true }
						]}
					></Breadcrumbs>

					{path === '/conteudo' && !!usuarioLogado && (
						<>
							<EtapasCadastro formStep={1} />
							<DadosObjeto user={usuarioLogado} oeStatusRascunho={oeStatusRascunho} />
						</>
					)}
					{!!oeId && path === `/conteudo/${oeId}` && !!usuarioLogado && (
						<>
							<EtapasCadastro formStep={1} />
							<DadosObjeto oe={oe} user={usuarioLogado} oeStatusRascunho={oeStatusRascunho} />
						</>
					)}
					{!!oeId && oe && path === `/conteudo/${oeId}/anexo` && !!usuarioLogado && (
						<>
							<EtapasCadastro formStep={2} />
							<Anexo oe={oe} user={usuarioLogado} />
						</>
					)}
					{!!oeId && oe && path === `/conteudo/${oeId}/concluir` && !!usuarioLogado && (
						<>
							<EtapasCadastro formStep={3} />
							<Concluir oe={oe} step={3} user={usuarioLogado} />
						</>
					)}
					{!!oeId && oe && path === `/conteudo/${oeId}/concluido` && !!usuarioLogado && (
						<>
							<EtapasCadastro formStep={4} />
							<Concluir oe={oe} step={4} user={usuarioLogado} />
						</>
					)}
				</div>
			)}
		</>
	);
};
