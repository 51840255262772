import React, { useState } from 'react';
import { useAuth } from '../../contexts/Auth';
import { Form, Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';

import { AEButton, AETextField, ModalService } from '../../shared/components';
import { useUsuarioService } from '../../shared/services/Usuario';
import { LogoEinstein } from '../../assets/icons';

import validator from '../../validators/LoginValidator';

import { ModalSolicitaMudancaSenha } from './components/ModalSolicitaMudancaSenha';

import { modalErro } from '../../shared/Modals/modalError/modal-erro';

import { IconeVisualizarSenha, IconeVisualizarSenhaSelect } from '../../assets/icons';

import './styles.scss';

interface IUser {
	usuario: string;
	senha: string;
}

const values = {
	usuario: '',
	senha: '',
};

export const LoginPage: React.FC = () => {
	const history = useHistory();
	const { search } = useLocation();
	const service = useUsuarioService();
	const { setAuthorization, setDataUser } = useAuth();
	const [loading, setLoading] = useState(false);
	const [mostrarSenha, setMostrarSenha] = useState(false);

	const handleLogin = (data: IUser) => {
		setLoading(true);
		service.logout();

		service
			.login(data.usuario, data.senha)
			.then(({ usuarioLogado, authorization }) => {
				setAuthorization(authorization);

				setDataUser({ ...usuarioLogado });

				setLoading(false);

				if (search.includes('redirect_url')) {
					const _search = search.split('=');
					const url = _search[1];

					window.location.href = `/${url}`;
					return;
				}

				history.push('/homepage');
			})
			.catch((error) => {
				setLoading(false);
				modalErro(error.message ? error.message : `Login inválido! Verifique o usuário e senha informados.`);
			});
	};

	return (
		<>
			<Formik initialValues={values} onSubmit={handleLogin} validationSchema={validator.criarSchemaValidacao()}>
				{({ values, handleChange, handleBlur, touched, errors }) => (
					<Form data-container-login>
						<div data-login-wrapper>
							<div data-login-inner>
								<div data-login-header>
									<img src={LogoEinstein} alt="Albert Einstein" />
								</div>

								<div data-login-form>
									<h2>CENTRAL DE CADASTRO DE CONTEÚDO ACADEMIA DIGITAL EINSTEIN</h2>

									<h3>Insira seus dados para entrar:</h3>

									<AETextField
										data-login-field-form
										name="usuario"
										id="usuario"
										value={values.usuario}
										onChange={handleChange}
										placeholder="Digite seu e-mail"
										label="E-mail"
										onBlur={handleBlur}
										error={Boolean(touched.usuario ? errors.usuario : null)}
										helperText={Boolean(touched.usuario ? errors.usuario : null) && '* campo obrigatório'}
									/>
									<div data-form-control data-password-field data-width="50">
										<AETextField
											data-login-field-form
											name="senha"
											id="senha"
											value={values.senha}
											onChange={handleChange}
											placeholder="Digite sua senha"
											label="Senha"
											type={mostrarSenha ? 'text' : 'password'}
											onBlur={handleBlur}
											error={Boolean(touched.senha ? errors.senha : null)}
											helperText={Boolean(touched.senha ? errors.senha : null) && '* campo obrigatório'}
										>
											<img
												onClick={() => setMostrarSenha(!mostrarSenha)}
												data-toggle-password
												src={mostrarSenha ? IconeVisualizarSenhaSelect : IconeVisualizarSenha}
												alt="teste"
												width={20}
												height={20}
											/>
										</AETextField>
									</div>
									<div data-row data-row-forget-password>
										<AEButton
											data-link-forget-password
											color="primary"
											variant="text"
											onClick={() => {
												ModalService.show({
													content: <ModalSolicitaMudancaSenha />,
												});
											}}
										>
											Esqueci minha senha
										</AEButton>
									</div>

									<AEButton disabled={loading} data-login-form-access color="primary" variant="contained" type="submit">
										{loading ? 'Entrando...' : 'Entrar'}
									</AEButton>

									<span data-login-label>Ainda não é cadastrado?</span>

									<AEButton
										id="btn-nova-conta"
										data-new-account
										color="primary"
										variant="outlined"
										type="button"
										onClick={async (ev) => {
											ev.stopPropagation();
											service.logout();

											window.location.href = '/nova-conta';
										}}
									>
										Criar uma conta
									</AEButton>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};
