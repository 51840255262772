import * as Yup from "yup";

export const oeTextoValidacao = Yup.object().shape({
  Titulo: Yup.string()
    .required("*campo obrigatório")
    .max(50, "*tamanho excedido"),
  Descricao: Yup.string()
    .required("*campo obrigatório")
    .max(500, "*tamanho excedido"),
  Resumo: Yup.string()
    .required("*campo obrigatório")
    .max(112, "*tamanho excedido"),
  AreasConhecimento: Yup.array().test({
    test: (value) => validateArray(value),
  }),
  PublicoAlvo: Yup.array().test({
    test: (value) => validateArray(value),
  }),
  Rotulos: Yup.array().test({
    test: (value) => validateArray(value),
  }),
  palavras_chave: Yup.array().test({
    test: (value) => validateArrayPalavrasChave(value),
  }),
  TipoObjeto: Yup.string().required("*campo obrigatório"),
  DataProducao: Yup.string().required("*campo obrigatório"),
  ValidadeConteudo: Yup.string().required("*campo obrigatório"),
});

const validateArray = (value: any) => 
  value && !!value.length;

const validateArrayPalavrasChave = (value: any) => 
  value && !!value.length && value.length >= 3;

