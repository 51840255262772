import React, { useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import { ModalService } from "../../../../shared/components/Modal/_index";
import { AEButton, AETextField } from "../../../../shared/components";

import "./styles.scss";

export const ModalSolicitaMudancaSenha: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [response, setResponse] = useState<any>({});

  const formik = useFormik({
    initialValues: {
      email: "",
      canal: "CCC",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Email inválido").required("Campo obrigatório"),
    }),
    onSubmit: (dados) => {
      fetch(`${process.env.REACT_APP_CHANGE_PASSWORD_REQUEST_URL}`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(dados),
      }).then((res) => {
        if (response.status === undefined) {
          response.status = 404;
        }

        setResponse(res);
        setFailed(false);
        setSuccess(false);

        if (res.status === 200) {
          setSuccess(true);
        } else {
          setFailed(true);
        }
      });
    },
  });

  return (
    <div data-solicitacao-senha-modal>
      {success && !failed && (
        <div data-link-enviado-modal>
          <h3>Link Enviado</h3>

          <p data-descricao>O link foi enviado para o e-mail cadastrado.</p>

          <div data-row data-row-buttons>
            <AEButton
              type="button"
              color="primary"
              variant="contained"
              onClick={() => ModalService.hide()}
            >
              Ok
            </AEButton>
          </div>
        </div>
      )}

      {!success && failed && (
        <div data-solicitacao-senha-error-modal>
          <h3 data-erro-titulo>
            {response.status === 404
              ? "E-mail não cadastrado"
              : `Erro inesperado (${response.status})`}
          </h3>

          <p data-descricao>
            Digite um e-mail válido para receber o link de redefinição de senha.
          </p>

          <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <div data-row data-row-email>
              <AETextField
                id="email"
                name="email"
                placeholder="E-mail"
                type="email"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.email ? formik.errors.email : null
                )}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
              />
            </div>

            <div data-row data-row-buttons>
              <AEButton
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  window.location.href = "/nova-conta";
                }}
              >
                Criar Conta
              </AEButton>

              <AEButton type="submit" color="primary" variant="contained">
                Enviar
              </AEButton>
            </div>
          </form>
        </div>
      )}

      {!success && !failed && (
        <div>
          <h3>Recuperar senha</h3>

          <p data-descricao>
            Digite o email cadastrado para receber um link de redefinição de
            senha.
          </p>

          <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <div data-row data-row-email>
              <AETextField
                id="email"
                name="email"
                placeholder="E-mail"
                type="email"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.email ? formik.errors.email : null
                )}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
              />
            </div>

            <div data-row data-row-buttons>
              <AEButton
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => ModalService.hide()}
              >
                Cancelar
              </AEButton>

              <AEButton type="submit" color="primary" variant="contained">
                Enviar
              </AEButton>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ModalSolicitaMudancaSenha;
