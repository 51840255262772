import { format } from "date-fns";

export const formatarStringDataParaDiaMesAno = (data: string) => {
  let dataFormatada = "";

  if (typeof data === "object") {
    dataFormatada = format(data, "yyyy-MM-dd");
  }

  if (typeof data === "string" && data.includes("/")) {
    const parts = data.split("/");
    dataFormatada = `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  if (typeof data === "string" && data.includes("-")) {
    const dataSemTimezone = data.includes("T") ? data.substring(0, 10) : data;
    const parts = dataSemTimezone.split("-");

    dataFormatada = `${parts[2]}/${parts[1]}/${parts[0]}`;
  }
  

  return dataFormatada;

}

export const formatarDataRequesicao = (data: string) => {
  let dataFormatada = "";

  if (typeof data === "object") {
    dataFormatada = format(data, "yyyy-MM-dd");
  }

  if (typeof data === "string" && data.includes("/")) {
    const parts = data.split("/");
    dataFormatada = `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  if (typeof data === "string" && data.includes("-")) {
    const dataSemTimezone = data.substring(0, 10);
    const parts = dataSemTimezone.split("-");
    dataFormatada = `${parts[0]}-${parts[1]}-${parts[2]}`;
  }

  return dataFormatada;
};