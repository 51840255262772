import React, { useState } from 'react';
import { createStyles, makeStyles, Popover, Typography } from '@material-ui/core';
import { AEIconButton, IAEIconButton } from '../IconButton';
import './styles.scss';


interface IAEButtonSelect {
  icon?: IAEIconButton['icon'];
  onChange(index: number): void;
  menu: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    popover: {
      '& .MuiPopover-paper': {
        boxShadow: '0 3px 6px #00000029',
        borderRadius: 0,

        '& p': {
          border: '0 solid #E6EBEF',
          borderTopWidth: 1,

          '&:first-child': {
            border: 0
          }
        }
      }
    },
    typography: {
      padding: 8,
      fontFamily: 'unset',
      fontSize: 12,
      letterSpacing: 0,
      color: '#424343',
      cursor: 'pointer'
    },
  }),
);

export const AEButtonSelect: React.FC<IAEButtonSelect> = ({ icon, menu, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const handle = (id: number) => () => {
    onChange(id);
    setAnchorEl(null);
  }

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div data-button-select>
      <AEIconButton icon={icon || 'editar'} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        {menu.map((item, idx) => (
          <Typography
            onClick={handle(idx)}
            className={classes.typography}
            key={idx} >
            {item}
          </Typography>
        ))}
      </Popover>
    </div>
  );
}