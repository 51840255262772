export const useSort = () => {
	const sortList = <TEntity extends unknown>(list: TEntity[], key: keyof TEntity, ascing: boolean) => {
		const _ = list.sort((a, b) => {
			if (a[key] > b[key]) return ascing ? 1 : -1;
			if (a[key] < b[key]) return !ascing ? -1 : 1;
			return 0;
		});

		return _;
	};

	return sortList;
};
