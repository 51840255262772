import React, { useEffect } from "react";

import { AEButton } from "../../../../../../shared/components";

import { useMeusConteudosContext } from "../../../../../../contexts/MeusConteudos";

export const CleanFilter: React.FC = () => {
  const { handleClearFilter, isFilteredEmpty, setFilteredEmpty, filtersSelected } = useMeusConteudosContext();

  useEffect(() => {
    setFilteredEmpty(!filtersSelected || !filtersSelected.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelected]);

  return (
    <>
      {!isFilteredEmpty && (
        <AEButton
          variant="text"
          color="primary"
          data-empty-search
          onClick={() => {
            handleClearFilter();
            setFilteredEmpty(true);
          }}
        >
          Limpar todos os filtros
        </AEButton>
      )}
    </>
  );
};
