import React, { useState } from 'react';
import { ConvitePendentesPayload, useApproveInvitationMutation } from '../../../../generated/graphql';

import { AEButton } from '../../../../shared/components';
import { ResultConvitesPendentesData } from '../ResultConvitesPendentesData';

import './styles.scss';

interface ModalConvitesPendentesProps {
	data: ConvitePendentesPayload;
}

export const ModalConvitesPendentes: React.FC<ModalConvitesPendentesProps> = (props) => {
	const [selecionados, setSelecionados] = useState<any[]>([]);

	const [approveInvitation] = useApproveInvitationMutation({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			window.location.reload();
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const handleVisualizarConteudo = () => {
		if (!!selecionados && selecionados.length === 1) {
			const autorConvidado = props.data.data?.find((item) => item.id === selecionados[0]);
			if (!!autorConvidado) {
				window.location.href = `/conteudo/${autorConvidado.oe_id}/visualizar`;
			}
		}
	};

	const handleAprovarConteudos = async () => {
		await approveInvitation({
			variables: {
				ids: [...selecionados],
			},
		});
	};

	return (
		<div data-modal-convite-pendente>
			<div data-row>
				<h2 data-title>{props.data.total} conteúdos pendentes</h2>
			</div>

			<div data-row data-content>
				<ResultConvitesPendentesData
					result={props.data}
					handleSelecionados={(value) => {
						setSelecionados(value);
					}}
				/>
			</div>

			<div data-row data-buttons>
				<AEButton
					color="primary"
					variant="outlined"
					onClick={handleVisualizarConteudo}
					disabled={!selecionados.length || selecionados.length > 1}
				>
					Visualizar
				</AEButton>

				<AEButton
					color="primary"
					variant="contained"
					onClick={handleAprovarConteudos}
					type="button"
					disabled={!selecionados.length}
				>
					Aprovar
				</AEButton>
			</div>
		</div>
	);
};
