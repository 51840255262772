export const formatCPFOrCNPJ = (documento: string | undefined): string => {
  if (!documento) return "";

  if (documento.includes(".")) {
    return documento;
  }

  if (documento.length === 11) {
    documento = documento.replace(/[^\d]/g, "");
    return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (documento.length === 14) {
    documento = documento.replace(/[^\d]/g, "");
    return documento.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return documento;
  }
};

export const splitTitle = (value: string, charLength: number) => {
  return value && value.length > charLength
    ? `${value.substring(0, charLength)}...`
    : (value as string);
};
