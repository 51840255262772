import { Select, SelectProps } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import "./styles.scss";

const { disable } = require("../../../ds/_palette.module.scss");

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};


export const AESelect: React.ComponentType<SelectProps> = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",

    "&.MuiMenu-paper": {
      maxHeight: "4rem !important",
    },

    "&.MuiOutlinedInput-input": {
      padding: "0 16px",
      height: "100%",
    },

    "&.Mui-disabled": {
      backgroundColor: disable,
    },
  },
  
}))((props: SelectProps) => <Select {...props} data-select MenuProps={MenuProps}/>);
