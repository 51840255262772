import { Dispatch, SetStateAction, useEffect, useState } from "react";

type Response<T> = [T, Dispatch<SetStateAction<T>>];

export default function usePersistedState<T>(
  key: string,
  initialState: T
): Response<T> {
  const [state, setState] = useState(() => {
    return decryptLocalStorage(key, initialState);
  });

  useEffect(() => {
    if (state) {
      localStorage.setItem(key, JSON.stringify(state));
    } else {
      localStorage.removeItem(key);
    }
  }, [key, state]);

  return [state, setState];
}

export const decryptLocalStorage = (key: string, initialState?: any) => {
  const storageValue = localStorage.getItem(key);
  if (storageValue && typeof storageValue === "string") {
    const storageValueDecrypt = storageValue;
    return JSON.parse(storageValueDecrypt);
  }

  return initialState;
};
