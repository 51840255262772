import { ISelect } from "../interfaces"



export const convertArrayToSelects = <T = unknown>(
  list: T[] = [], 
  tuples: [keySelect: keyof ISelect, keyType: keyof T][]
) => 
  list.map(item => {
    const select = {} as ISelect;

    for(const tuple of tuples) {
      select[tuple[0]] = item[tuple[1]] as any;
    }

    return select;
  });
