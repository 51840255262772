import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IUsuarioLogado, useAuth } from "../../../../contexts/Auth";
import {
  ObjetoEducacionalFragment,
  useCreateHistoricoAvaliacaoObjetoEducacionalMutation,
  useGetStatusPorTituloLazyQuery,
  Enum_Statusdepublicacao_Enum_Status,
} from "../../../../generated/graphql";
import {
  TOAST_ACTION,
  TOAST_SERVERITY,
} from "../../../../redux/toast/toast.reducer";

import { AEButton } from "../../../../shared/components";

import useCreateHistoricoStatusOe from "../../../../hooks/useCreateHistoricoStatusOe";

import "./styles.scss";
interface ConcluirProps {
  step: number;
  oe: ObjetoEducacionalFragment;
  user: IUsuarioLogado;
}

export const Concluir: React.FC<ConcluirProps> = (props) => {
  const history = useHistory();
  const { usuarioLogado } = useAuth();
  const [processing, setProcessing] = useState<boolean>(false);

  const { getStatusAndCreateHistorico } = useCreateHistoricoStatusOe();

  const setStatus = async () => {
    try {
      setProcessing(true);
      await getStatusAndCreateHistorico({
        oeId: props.oe.id,
        userId: usuarioLogado?.idUsuario!,
        statusEnum: Enum_Statusdepublicacao_Enum_Status.EmAnalise,
      });

      history.push(`/conteudo/${props.oe?.id}/concluido`);
    } catch (error) {
      setProcessing(false);
    }
  };

  return (
    <div data-step-conclusao data-width="100">
      {props.step === 3 && (
        <div data-concluir>
          <h2 data-concluir>Confirmação de envio</h2>
          <p>
            Para concluir o envio do seu objeto educacional, clique no botão de
            enviar abaixo.
          </p>
        </div>
      )}
      {props.step === 4 && (
        <div data-concluido>
          <h2 data-concluido>Conteúdo cadastrado com sucesso</h2>
          <p>
            O conteúdo foi enviado para análise. Fique atento às notificações de
            status.
          </p>
        </div>
      )}

      <div data-buttons>
        <AEButton
          color="primary"
          variant="outlined"
          disabled={processing}
          onClick={() => {
            props.step === 3
              ? history.push(`/conteudo/${props.oe.id}/anexo`)
              : history.push(`/conteudo/${props.oe.id}/visualizar`);
          }}
        >
          {props.step === 3 ? "Voltar" : "Visualizar"}
        </AEButton>
        <AEButton
          color="primary"
          variant="contained"
          disabled={processing}
          onClick={() => {
            props.step === 3 ? setStatus() : history.push(`/meus-conteudos`);
          }}
        >
          {props.step === 3 ? "Enviar" : "Ir para Meus Conteúdos"}
        </AEButton>
      </div>
    </div>
  );
};
