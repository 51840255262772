import { useState } from "react";

export const useAPI = <T extends unknown>() => {
  const [data, setData] = useState<T | null>(null);

  const request = async (url: string) => {
    const response = await fetch(url, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    });

    const _data = await response.json();

    setData(_data);

    return _data;
  };

  return { request, data };
};
