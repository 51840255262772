// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryText": "#898b8e",
	"secondaryText": "#373f45",
	"primary": "#084f92",
	"primaryDark": "#0e65bd",
	"secondary": "#697177",
	"success": "#1bb28e",
	"successDark": "#afe3d7",
	"error": "#bd331d",
	"errorDark": "#ebc5be",
	"alert": "#feb641",
	"alertDark": "#faedc8",
	"inactiveText": "#8e959a",
	"separatorColor": "#e6ebef",
	"backgroundColor": "#f6f9fb",
	"disable": "#dce1e5",
	"light-gray": "#084f920d",
	"white": "#fff",
	"tooltip": "#3b3b3b"
};
export default ___CSS_LOADER_EXPORT___;
