import React, { ChangeEvent, useState } from "react";
import { IUsuarioLogado, useAuth } from "../../contexts/Auth";
import { useHistory } from "react-router";
import Resizer from "react-image-file-resizer";

import { AEButton, AESwitch } from "../../shared/components";
import { IconeUploadFoto } from "../../assets/icons";

import "./styles.scss";

export const AnexoFotoPage: React.FC = () => {
  const { setUsuario, usuarioLogado } = useAuth();
  const [autoriza, setAutoriza] = useState(false);
  const [imagemInvalida, setImagemInvalida] = useState(false);
  const [formData, setformData] = useState<any>();
  const [avatarPreview, setAvatarPreview] = useState(
    usuarioLogado?.avatar || ""
  );
  const history = useHistory();

  const handleSave = () => {
    fetch(`${process.env.REACT_APP_ROOT}/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${usuarioLogado?.jwt}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data: any) => {
        setUsuario({
          ...usuarioLogado,
          avatar: data[0].url,
        } as IUsuarioLogado);

        window.location.href = "/homepage";
      });
  };

  const handleSaveLater = () => {
    setUsuario({
      ...usuarioLogado,
      avatarLater: true,
    } as IUsuarioLogado);

    history.push("/homepage");
  };

  const formatoEhValido = (tipoImagem: string) =>
    ["jpeg", "png", "jpg"].find((extensao) => tipoImagem.includes(extensao));

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const tamanhoPermitido = 500000;
    const tamanho = {
      maximo: 300,
      minimo: 200,
    };
    const dpi = 100;
    const rotacao = 0;

    if (!files || !files.length) {
      return;
    }

    const imagem = files[0];

    setImagemInvalida(!formatoEhValido(imagem.type));

    if (!formatoEhValido(imagem.type)) {
      return;
    }

    if (imagem.size > tamanhoPermitido) {
      Resizer.imageFileResizer(
        imagem,
        tamanho.maximo,
        tamanho.maximo,
        imagem.type,
        dpi,
        rotacao,
        (uri) => handleFormData(uri as File),
        "file",
        tamanho.minimo,
        tamanho.minimo
      );

      return;
    }

    handleFormData(imagem);
  };

  const handleFormData = (imagem: File) => {
    const reader = new FileReader();
    const formDataAtual = new FormData();

    const reader2 = new FileReader();

    if (!formatoEhValido(imagem.type)) {
      return;
    }

    reader.readAsArrayBuffer(imagem as Blob);
    reader.onloadend = function (
      this: FileReader,
      ev: ProgressEvent<FileReader>
    ) {
      formDataAtual.append(
        "files",
        new Blob([this.result as ArrayBuffer], { type: imagem.type }),
        imagem.name
      );

      formDataAtual.append("ref", "user");
      formDataAtual.append("field", "Avatar");
      formDataAtual.append("refId", usuarioLogado?.idUsuario!);
      formDataAtual.append("source", "users-permissions");

      setformData(formDataAtual);

      reader2.readAsDataURL(imagem);
      reader2.onloadend = function (
        this: FileReader,
        ev: ProgressEvent<FileReader>
      ) {
        setAvatarPreview(this.result as string);
      };
    };
  };

  const dispatchUploadClick = () => {
    (
      document.querySelector('[name="files"]') as HTMLInputElement
    ).dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div data-anexo-foto-page>
      <h3>Foto</h3>

      <h4>Foto para divulgação</h4>

      <section data-anexo-foto-content>
        <div data-input-file onClick={dispatchUploadClick}>
          <input
            name="files"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileSelection}
          />
          <img src={IconeUploadFoto} alt="" />

          <span>Clique aqui para selecionar um arquivo</span>
        </div>

        {imagemInvalida && <div>Imagem inválida</div>}

        <div data-anexo-info>
          Recomendações: foto da cintura para cima, preferencialmente de jaleco
          ou uniforme. Procure se posicionar no centro da tela para que fique
          inteiramente dentro da foto, sem correr o perigo de ter alguma parte
          cortada para fora da imagem. Você pode se inspirar no exemplo ao lado.
        </div>

        <div data-avatar>
          <img src={avatarPreview} alt="" />
        </div>

        <label data-toggle-autoriza>
          <AESwitch
            color="primary"
            id="toggleAutoriza"
            name="toggleAutoriza"
            checked={autoriza}
            onChange={(ev) => setAutoriza(ev.target.checked)}
          ></AESwitch>
          <span>Mostrar na Academia</span>
        </label>
      </section>

      <div data-buttons>
        <AEButton color="primary" variant="outlined" onClick={handleSaveLater}>
          Enviar depois
        </AEButton>
        <AEButton color="primary" variant="contained" onClick={handleSave}>
          Salvar
        </AEButton>
      </div>
    </div>
  );
};
