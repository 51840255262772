import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";

import { ModalSolicitaMudancaSenha } from "../Login/components/ModalSolicitaMudancaSenha";
import { ModalService } from "../../shared/components/Modal/_index";
import { useValidaSenha } from "../../hooks/useValidaSenha";

import "./styles.scss";
import { AEButton, AETextField } from "../../shared/components";

interface RedefinicaoSenha {
  senha: string;
  confirmacaoSenha: string;
  hash: string;
}

export const TrocarSenhaPage: React.FC<any> = (props) => {
  const [isVisivel] = useState(false);
  const [isConfirmaVisivel] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [expired, setExpired] = useState(false);
  const [response, setResponse] = useState<any>({});
  const { validarSenha } = useValidaSenha();

  const formik = useFormik<RedefinicaoSenha>({
    initialValues: {
      senha: "",
      confirmacaoSenha: "",
      hash: props.match.params.hash,
    },
    validationSchema: yup.object().shape({
      senha: yup
        .string()
        .required("Campo obrigatório")
        .test({
          test: (value) => {
            const erros = validarSenha(value || "");

            if (!erros.length) {
              return true;
            }

            return new yup.ValidationError(erros.join(", "), null, "senha");
          },
        }),
      confirmacaoSenha: yup
        .mixed()
        .test("senha", "Senha não confere", function () {
          return this.parent.senha === this.parent.confirmacaoSenha;
        }),
    }),
    onSubmit: (dados) => {
      fetch(`${process.env.REACT_APP_CHANGE_PASSWORD_URL}`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(dados),
      }).then(() => {
        switch (response.status) {
          case 200:
            setSuccess(true);
            break;

          case 410:
            setExpired(true);
            break;

          case 404:
          case 500:
            setFailed(true);
            break;
        }
      });
    },
  });

  useEffect(() => {
    async function _fetch() {
      const response = await fetch(
        `${process.env.REACT_APP_CHANGE_PASSWORD_HASH_VERIFY_URL}`,
        {
          method: "POST",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            hash: props.match.params.hash,
          }),
        }
      );

      setResponse(response);

      switch (response.status) {
        case 200:
          break;

        case 404:
          setFailed(true);
          break;

        case 410:
          setExpired(true);
          break;
      }
    }

    _fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mostrarModalSolicitacaoMudanca = (e: any) => {
    e.preventDefault();

    ModalService.show({
      content: <ModalSolicitaMudancaSenha />,
    });
  };

  const mostrarMensagemLinkExpirado = () => {
    return (
      <div data-password-reset>
        <h3>Link expirado</h3>
        <p>
          <AEButton color="primary" variant="contained" onClick={mostrarModalSolicitacaoMudanca}>
            Clique aqui
          </AEButton>{" "}
          para solicitar novo envio.
        </p>
      </div>
    );
  };

  const mostrarMensagemUrlInvalida = () => {
    return (
      <div data-password-reset>
        <h3>Essa url não é válida</h3>
      </div>
    );
  };

  const mostrarMensagemSucesso = () => {
    return (
      <div data-password-reset>
        <h3>A senha foi trocada com sucesso!</h3>
      </div>
    );
  };

  const renderizarFormulario = () => {
    return (
      <div data-password-reset>
        <h3>Cadastrar nova senha</h3>
        <form onSubmit={formik.handleSubmit}>
          <div data-row>
            <div data-col-2>
              <input
                type="hidden"
                name="hash"
                value={props.match.params.hash}
              />

              <AETextField
                id="senha"
                name="senha"
                type={isVisivel ? "text" : "password"}
                label="Nova senha"
                placeholder="Digite a senha"
                onBlur={formik.handleBlur}
                value={formik.values.senha}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.senha ? formik.errors.senha : null
                )}
                helperText={
                  formik.touched.senha && formik.errors.senha
                    ? formik.errors.senha
                    : null
                }
              ></AETextField>
            </div>

            <div data-col-2>
              <AETextField
                id="confirmacaoSenha"
                name="confirmacaoSenha"
                type={isConfirmaVisivel ? "text" : "password"}
                label="Confirme nova senha"
                placeholder="Digite a senha"
                onBlur={formik.handleBlur}
                value={formik.values.confirmacaoSenha}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.confirmacaoSenha
                    ? formik.errors.confirmacaoSenha
                    : null
                )}
                helperText={
                  formik.touched.confirmacaoSenha &&
                  formik.errors.confirmacaoSenha
                    ? formik.errors.confirmacaoSenha
                    : null
                }
              ></AETextField>
            </div>
          </div>

          <div data-row>
            <div data-col-1>
              <AEButton
                data-modal-button
                color="primary"
                variant="contained"
                type="submit"
              >
                Ok
              </AEButton>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (() => {
    return (
      <>
        {expired && mostrarMensagemLinkExpirado()}
        {failed && mostrarMensagemUrlInvalida()}
        {success && mostrarMensagemSucesso()}
        {!expired && !failed && !success && renderizarFormulario()}
      </>
    );
  })();
};
