import React, { useEffect, useState } from 'react';

import {
	DriveInsumoObjetoEducacional,
	useGetDriveInsumosByObjetoEducacionalIdLazyQuery,
} from '../../../../generated/graphql';

import './styles.scss';

interface DriveInsumosProps {
	objetoEducacionalId: string;
}

export const DriveInsumos: React.FC<DriveInsumosProps> = (props) => {
	const [links, setLinks] = useState<DriveInsumoObjetoEducacional[] | null>(null);
	const [arquivos, setArquivos] = useState<DriveInsumoObjetoEducacional[] | null>(null);

	const [getList] = useGetDriveInsumosByObjetoEducacionalIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (response.findDriveInsumosByObjetoEducacional) {
				const _arquivos = response.findDriveInsumosByObjetoEducacional.filter((item) => item.tipo === 'documento');
				const _links = response.findDriveInsumosByObjetoEducacional.filter((item) => item.tipo === 'link');

				setLinks(
					_links.map(
						(item) =>
							({
								id: item.id,
								extensao: item.extensao,
								nome: item.nome,
								url: item.url,
							} as DriveInsumoObjetoEducacional),
					),
				);

				setArquivos(
					_arquivos.map(
						(item) =>
							({
								id: item.id,
								extensao: item.extensao,
								nome: item.nome,
								url: item.url,
							} as DriveInsumoObjetoEducacional),
					),
				);
			}
		},
	});

	useEffect(() => {
		getList({
			variables: {
				id: props.objetoEducacionalId,
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{((!!links && !!links.length) || (!!arquivos && !!arquivos.length)) && (
				<div data-drive-insumo-files>
					<div data-row>
						{!!links && !!links.length && (
							<div data-drive-insumo-type>
								<strong>Links</strong>
								{links.map((item, idx) => (
									<div key={idx}>
										<div data-uploaded-file>
											<a href={`${item.url}`}>{item.url}</a>
										</div>
									</div>
								))}
							</div>
						)}

						{!!arquivos && !!arquivos.length && (
							<div data-drive-insumo-type>
								<strong>Aquivos</strong>
								{arquivos.map((item, idx) => (
									<div key={idx}>
										<div data-uploaded-file>
											<label>{item.nome}</label>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};
