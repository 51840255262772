import React, { useEffect, useState } from 'react';

import { ISelect } from '../../../../../../shared/interfaces';
import './styles.scss';

export interface ISelectCategory {
	label: string;
	value: string;
	descricao: string;
}

interface CategoriasComponentProps {
	rotulosJaSelecionados: ISelect[];
	listaCategorias: ISelectCategory[];
	handleCategorias(categorias: ISelect[]): void;
	errors: boolean;
}

export const CategoriasComponent: React.FC<CategoriasComponentProps> = (props) => {
	const [rotulosSelecionados, setRotulosSelecionados] = useState<ISelect[]>([]);

	const handleAdicionarCategoria = (categoria: ISelect) => {
		const rotulosArray = [...rotulosSelecionados];

		const existeNoArray = rotulosArray.findIndex((item) => item.value === categoria.value);

		if (existeNoArray >= 0) {
			rotulosArray.splice(existeNoArray, 1);
			setRotulosSelecionados(rotulosArray);
			return;
		}

		if (rotulosArray.length && rotulosArray.length === 2) {
			return;
		}

		rotulosArray.push({
			label: categoria.label,
			value: categoria.value,
		});
		setRotulosSelecionados(rotulosArray);
	};

	useEffect(() => {
		if (props.rotulosJaSelecionados) {
			setRotulosSelecionados(props.rotulosJaSelecionados);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		props.handleCategorias(rotulosSelecionados);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rotulosSelecionados]);

	return (
		<div data-form-categorias-component>
			<p className={props.errors ? 'error' : ''}>
				<label>Categoria*</label>
			</p>
			<div data-form-categorias-container>
				{props.listaCategorias &&
					props.listaCategorias.map((categoria) => (
						<div
							key={categoria.value}
							data-form-categorias-item
							className={rotulosSelecionados.find((item) => item.value === categoria.value) ? 'selected' : ''}
							onClick={() => handleAdicionarCategoria(categoria)}
						>
							{categoria.label}
							{categoria.descricao && categoria.descricao !== '' && (
								<div data-form-categorias-tooltip>{categoria.descricao}</div>
							)}
						</div>
					))}
			</div>
			<span className={props.errors ? 'error' : ''}>Selecione até 2 categorias</span>
		</div>
	);
};
