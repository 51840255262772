/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react';
import { ISelect } from '../shared/interfaces';

import {
	ObjetoEducacionalWithHistoricoAvaliacaoConnection,
	useGetObjetosEducacionaisBySearchLazyQuery,
} from '../generated/graphql';
import { IHateoas } from '../services/utils/response-data';

export interface MeusConteudosState {
	status: ISelect;
	categoria: ISelect;
	keyWord: string;
	isExpired: boolean;
	start: number;
	limit: number;
	sort: any;
	order: any;
}

interface MeusConteudosContextData {
	state: MeusConteudosState;
	setState(values: MeusConteudosState): void;
	filtersSelected: FilterSelected[] | undefined;
	handleClearFilter(): void;
	isFilteredEmpty: boolean;
	setFilteredEmpty(value: boolean): void;
	setFiltersSelected(values: FilterSelected[]): void;
	handleChange<KeyType extends keyof MeusConteudosState>(key: KeyType, value: any): void;
	dataResult: ObjetoEducacionalWithHistoricoAvaliacaoConnection;
	loading: boolean;
}

const stateDefault = {
	categoria: {
		value: '0',
		label: 'Categoria',
	},
	status: {
		value: '0',
		label: 'Status',
	},
	keyWord: '',
	isExpired: false,
	start: 0,
	limit: 25
} as MeusConteudosState;

export interface FilterSelected {
	id: string;
	name: string;
	type: keyof MeusConteudosState;
}

export const useMeusConteudosContext = () => useContext(MeusConteudosContext);

const MeusConteudosContext = createContext<MeusConteudosContextData>({
	state: { ...stateDefault },
} as MeusConteudosContextData);

export const MeusConteudosProvider: React.FC = ({ children }) => {
	const [state, setState] = useState<MeusConteudosState>({ ...stateDefault } as MeusConteudosState);
	const [loading, setLoading] = useState(false);
	const [dataResult, setResult] = useState<ObjetoEducacionalWithHistoricoAvaliacaoConnection>({
		total: 0,
		totalCount: 0,
		data: [],
		error: '',
	});
	const [filtersSelected, setFiltersSelected] = useState<FilterSelected[] | undefined>(undefined);
	const [isFilteredEmpty, setFilteredEmpty] = useState(false);

	const [searchConteudos] = useGetObjetosEducacionaisBySearchLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			const total = response.findObjetosEducacionaisWithHistoricoAvaliacao?.total || 0;
			const totalCount = response.findObjetosEducacionaisWithHistoricoAvaliacao?.totalCount || 0;
			const data = response.findObjetosEducacionaisWithHistoricoAvaliacao?.data || [];
			const error = response.findObjetosEducacionaisWithHistoricoAvaliacao?.error || '';
			setResult({ total, totalCount, data, error });
			setLoading(false);
		},
		onError: (error) => {
			console.error(error);
			setLoading(false);
		},
	});

	useEffect(() => {
		if (filtersSelected === undefined) {
			return;
		}

		setLoading(true);

		async function load(filtersSelected: FilterSelected[]) {
			await searchConteudos({
				variables: {
					limit: state.limit,
					start: state.start,
					where: {
						status: filtersSelected.filter((item) => item.type === 'status').map((item) => item.id),
						categorias: filtersSelected.filter((item) => item.type === 'categoria').map((item) => item.id),
						isExpired: state.isExpired,
						keyWord: state.keyWord,
					},
					order_by: `${state.sort || 'titulo'}_${state.order || 'asc'}` as any,
				},
			});
		}

		load(filtersSelected);
	}, [state, filtersSelected]);

	const handleChange = <KeyType extends keyof MeusConteudosState>(key: KeyType, value: any) => {
		setState({ ...state, [key]: value });
	};

	const handleClearFilter = () => {
		setState({ ...stateDefault });
		setFiltersSelected([]);
		localStorage.removeItem('ccc_filtro_meus_conteudos');
	};

	return (
		<MeusConteudosContext.Provider
			value={{
				state,
				setState,
				handleChange,
				filtersSelected,
				setFiltersSelected,
				handleClearFilter,
				isFilteredEmpty,
				setFilteredEmpty,
				dataResult,
				loading,
			}}
		>
			{children}
		</MeusConteudosContext.Provider>
	);
};

export default MeusConteudosProvider;
