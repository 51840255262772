import React, { useEffect } from "react";
import { useHistory } from "react-router";

import {
  Logo_Einstein_Redondo_Branco,
  Loading
} from "../../../assets/icons";

import "./styles.scss";

export interface LoaderProps {
  show: boolean;
  message?: string;
  redirectUrl?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { show, message } = props;
  const history = useHistory();
  useEffect(()=>{
    if(!!props.redirectUrl){
      setTimeout(() => {
        history.push(`${props.redirectUrl}`);
      }, 3000);
    }
  },[props.redirectUrl])

  document
    .querySelector<HTMLElement>("[data-loader]")
    ?.addEventListener("animationend", (el) => {
      (el.target as HTMLElement).style.display = "none";
    });

  return (
    <div data-loader data-show={show} style={{
      height: !!show ? "100%" : "0%",
      width: !!show ? "100%" : "0%",
    }}>
      <img src={Logo_Einstein_Redondo_Branco} alt="" />
      <p>{message}</p>
      <img src={Loading} alt="" />
    </div>
  );
};

export default Loader;
