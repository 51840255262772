/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { ConvidarAutores } from './components/ConvidarAutores';

import './styles.scss';

import { MultiSelect } from 'react-multi-select-component';

import { ExcluiItem } from '../../../../assets/icons';
import { useAuth } from '../../../../contexts/Auth';
import { PerfilDeAutor, AutorConvidado } from '../../../../generated/graphql';

interface Props {
	handleAutores(autores: any): void;
	autores: PerfilDeAutor[] | AutorConvidado[];
}

export interface Autores {
	nome: string | undefined;
	email: string | undefined;
	id: string;
}

export const GerenciarAutores = (props: Props) => {
	const [options] = useState([]);
	const [autoresSelecionados, setAutoresSelecionados] = useState<PerfilDeAutor[]>([]);

	const [naoExisteAutor, setNaoExisteAutor] = useState(false);
	const [autoresConvidados, setAutoresConvidados] = useState<AutorConvidado[]>([]);

	useEffect(() => {
		const listaTodosAutores: Autores[] = [
			...autoresConvidados.map((item) => ({
				nome: item.nome,
				email: item.email,
				id: '',
				aceita_participacao: item.aceita_participacao || '',
			})),

			...autoresSelecionados.map((item: any) => ({
				nome: item.label,
				email: item.email || '',
				id: item.value,
				aceita_participacao: item.aceita_participacao || '',
			})),
		];

		props.handleAutores(listaTodosAutores);
	}, [autoresConvidados, autoresSelecionados]);

	useEffect(() => {
		setAutoresSelecionados(
			props.autores.map(
				(item: any) =>
					({
						label: item.nome,
						email: item.email,
						value: item.id,
						aceita_participacao: item.aceita_participacao,
					} as any),
			),
		);
	}, [props.autores]);

	const { usuarioLogado } = useAuth();

	const handleGet = (value: string) => {
		if (!value || value.length < 3) {
			return;
		}

		const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace(
			'/graphql',
			'',
		)}/v2/perfil-de-autors?email_contains=${value}`;

		return fetch(URL, {
			method: 'GET',
			headers: new Headers({ 'content-type': 'application/json' }),
		})
			.then((response) => response.json())
			.then((data) => {
				if (!data.length) {
					return [];
				}
				return data
					.filter((item: any) => item.id !== usuarioLogado?.autor.id)
					.map((autor: { id: number; nome: string; email: string }) => ({
						id: autor.id,
						nome_completo: autor.nome,
						email: autor.email,
					}));
			});
	};

	const filterOptions = async (options: any, filter: any) => {
		if (!filter) {
			return options;
		}

		setNaoExisteAutor(false);

		const result = await handleGet(filter);

		if (!!result && result.length) {
			return result.map((item: any) => ({
				label: item.nome_completo,
				value: item.id,
				email: item.email,
			}));
		}
		setNaoExisteAutor(true);
		return [];
	};

	const customValueRenderer = (selected: any, _options: any) => {
		return selected.length ? selected.map(({ label }: any) => '') : '';
	};

	return (
		<div data-form-gerenciar-autores data-width="100">
			<label>Adicionar colaboradores</label>
			<div data-form-row>
				<MultiSelect
					options={options}
					value={autoresSelecionados.map((item: any) => ({
						label: item.label,
						value: item.value,
						email: item.email,
					}))}
					onChange={setAutoresSelecionados}
					labelledBy={'Select'}
					overrideStrings={{
						search: 'Pesquisar...',
						selectSomeItems: 'fulanodetal@einstein.br',
						noOptions: naoExisteAutor
							? 'Nenhum autor localizado com esse e-mail'
							: 'Digite o e-mail para buscar um autor',
					}}
					hasSelectAll={false}
					debounceDuration={400}
					filterOptions={filterOptions}
					valueRenderer={customValueRenderer}
				/>
			</div>

			<div data-form-row>
				<ul>
					{autoresSelecionados.map((value: any, idx) => (
						<li data-form-row-autores key={idx}>
							{value.label} - {value.email} - {value.aceita_participacao === true ? 'Co-autor' : 'Aguardando aceite'}
							<div
								onClick={() => {
									setAutoresSelecionados(autoresSelecionados.filter((item: any) => item.value !== value.value));
								}}
							>
								<img src={ExcluiItem} alt="Excluir" width="17px" height="21px" />
							</div>
						</li>
					))}
					{autoresConvidados.map((value, idx) => (
						<li data-form-row-autores key={idx}>
							{value.nome} - {value.email} - Convite de e-mail
							<div
								onClick={() => {
									setAutoresConvidados(autoresConvidados.filter((item) => item.email !== value.email));
								}}
							>
								<img src={ExcluiItem} alt="Excluir" width="17px" height="21px" />
							</div>
						</li>
					))}
				</ul>
			</div>

			{naoExisteAutor && (
				<ConvidarAutores
					autoresConvidados={autoresConvidados}
					handleAutoresConvidadosPorEmail={(value) => {
						setAutoresConvidados(value);
						setNaoExisteAutor(false);
					}}
				/>
			)}
		</div>
	);
};
