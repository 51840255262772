export const validarCPF = (Cpf: string | undefined) => {
  Cpf = !!Cpf ? Cpf.replace(/(\.|-)/g, "") : "0";

  if (Cpf === "") {
    return false;
  }

  if (
    Cpf.length !== 11 ||
    Cpf === "00000000000" ||
    Cpf === "11111111111" ||
    Cpf === "22222222222" ||
    Cpf === "33333333333" ||
    Cpf === "44444444444" ||
    Cpf === "55555555555" ||
    Cpf === "66666666666" ||
    Cpf === "77777777777" ||
    Cpf === "88888888888" ||
    Cpf === "99999999999"
  ) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(Cpf.charAt(i)) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(Cpf.charAt(9))) {
    return false;
  }

  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(Cpf.charAt(i)) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(Cpf.charAt(10))) {
    return false;
  }

  return true;
};