export const debouncePromise = (f: (value?: any) => Promise<unknown> | undefined, interval: number) => {
  let timer: null | NodeJS.Timeout = null;

  return (...args: []) => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(f(...args)), interval);
    });
  };
};