/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { AEButton, AETextField } from '../../../../shared/components';
import { handleRequest } from '../../../../services/fetch.service';

import './styles.scss';
import { debouncePromise } from '../../../../shared/commom/debouncePromise';

interface ISelect {
	label: string;
	value: string;
}

interface ListaPalavrasChaveProps {
	formikError: boolean;
	handleSelecionadas(arraySelecionadas: string[]): void;
	palavrasChave?: ISelect[];
}

export const ListaPalavrasChave: React.FC<ListaPalavrasChaveProps> = ({
	handleSelecionadas,
	formikError,
	palavrasChave,
}) => {
	const [nova, setNova] = useState('');
	const [selecionados, setSelecionados] = useState<ISelect[]>([]);
	const [isAdiciona, setAdiciona] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		!!palavrasChave && setSelecionados(palavrasChave);
	}, [palavrasChave]);

	useEffect(() => {
		const arraySelecionados = selecionados.reduce((acc, item) => {
			acc.push(item.value.toString());
			return acc;
		}, [] as string[]);

		handleSelecionadas(arraySelecionados);
	}, [selecionados]);

	const handleGet = (value: string) => {
		if (!value) {
			return;
		}

		const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace(
			'/graphql',
			'',
		)}/palavra-chaves/v2?titulo_contains=${value}`;

		return fetch(URL, {
			method: 'GET',
			headers: new Headers({ 'content-type': 'application/json' }),
		})
			.then((response) => response.json())
			.then((data) => {
				return new Promise((resolve) =>
					resolve(
						data.map((item: any) => ({
							value: item.id,
							label: item.titulo,
						})),
					),
				);
			});
	};

	const handleCriar = (ev: any) => {
		ev.stopPropagation();

		if (!nova) {
			return;
		}

		setError('');

		if (!!selecionados && selecionados.length === 6) {
			setError('É possível selecionar apenas 6 palavras-chave');
			return;
		}

		const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace('/graphql', '')}/palavra-chaves`;

		return handleRequest(
			fetch(URL, {
				method: 'POST',
				headers: new Headers({ 'content-type': 'application/json' }),
				body: JSON.stringify({
					titulo: nova,
				}),
			}),
		)
			.then((response) => {
				if (!response.data) {
					return;
				}

				setSelecionados([...selecionados, { value: response.data.id, label: response.data.titulo }]);
				setAdiciona(false);
				setNova('');
			})
			.catch((errorCreated) => {
				setError(errorCreated.toString());
			});
	};

	return (
		<>
			<div data-row data-width="100">
				<div data-form-row-palavra-chave>
					<p data-title-auto-complete>Palavra-Chave*</p>
					<AsyncSelect
						isSearchable
						defaultOptions
						className={formikError ? 'error' : ''}
						isMulti
						placeholder=""
						name="PalavrasChave"
						value={selecionados}
						loadOptions={debouncePromise(handleGet, 1000)}
						classNamePrefix="select"
						onChange={(lista) => {
							setAdiciona(false);
							setError('');
							if (lista.length > 6) {
								return;
							}
							setSelecionados(lista as any[]);
						}}
					/>
					<span>
						<p className={formikError ? 'error' : ''}>Selecione de 3 a 6 palavras-chave</p>
						<AEButton
							color="primary"
							variant="text"
							onClick={() => {
								setError('');
								setAdiciona(!isAdiciona);
							}}
						>
							{(isAdiciona ? '(Ocultar) ' : '+ ') + 'Adicionar palavra-chave'}
						</AEButton>
					</span>
				</div>
			</div>

			{isAdiciona && (
				<>
					<div data-form-row-nova-container>
						<div data-form-row-nova>
							<AETextField
								id="NovaPalavraChave"
								name="NovaPalavraChave"
								placeholder=""
								label="Nova palavra-chave"
								value={nova}
								onChange={(ev) => {
									const palavraChaveTitleCase = ev.target.value.charAt(0).toUpperCase() + ev.target.value.slice(1).toLocaleLowerCase();
									setNova(palavraChaveTitleCase)}
								}
							/>
							<AEButton color="primary" variant="contained" onClick={handleCriar}>
								Adicionar
							</AEButton>
						</div>

						{error && <div data-form-row-error>{error}</div>}
					</div>
				</>
			)}
		</>
	);
};
