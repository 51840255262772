/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { EArea } from "../shared/enums/area-enum";
import { ERoles } from "../shared/enums/role-enum";
import usePersistedState from "../hooks/persistedState";
import { useUsuarioService } from "../shared/services/Usuario";
import { TOKEN_SECRECT } from "../services/graphql.service";

interface AuthContextData {
  usuarioLogado?: IUsuarioLogado;
  setUsuario(usuario?: IUsuarioLogado): void;
  setArea(area?: EArea): void;
  setAuthorization(value?: string): void;
  getAuthorization(): string;
  setDataUser(value?: any): void;
  setEnableMenuVertical(value: boolean): void;
  enableMenuVertical: boolean;
}

export enum RoleUser {
  Autor = "Autor",
  "Gestão" = "Gestão"
}

export interface IUsuarioLogado {
  idUsuario?: string;
  areaLogada?: EArea;
  nomeUsuario?: string;
  areas?: EArea[];
  rolesAreaLogada?: ERoles[];
  areasAndRoles?: any;
  autor: any;
  jwt?: string;
  avatar?: string;
  isAvatar: boolean;
  avatarLater?: boolean;
  role: RoleUser;
}

export const useAuth = () => useContext(AuthContext);
const AuthContext = createContext<AuthContextData>({} as AuthContextData);
export const AuthProvider: React.FC = ({ children }) => {
  const service = useUsuarioService();
  const [usuarioLogado, setUsuarioLogado] = usePersistedState<
    IUsuarioLogado | undefined
  >(TOKEN_SECRECT, undefined);

  const [enableMenuVertical, setEnableMenuVertical] = useState(true);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        const currentToken = axios.defaults.headers?.authorization || undefined;
        const newToken =
          response.data && response.data.jwt
            ? response.data.jwt
            : response.headers?.jwt;

        if (newToken && currentToken !== newToken) {
          setAuthorization(`Bearer ${newToken}`);
        }

        if (response?.status === 403) {
          localStorage.clear();
          window.location.href = "/";
          // service.logout();
        }

        return Promise.resolve(response);
      },
      (error) => {
        if (error.response?.status === 403) {
          localStorage.clear();
          window.location.href = "/";
          service.logout();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    let headers: any;
    if (usuarioLogado) {
      headers = {
        ...axios.defaults.headers,
        area: usuarioLogado.areaLogada,
      };
    } else {
      headers = {};
    }
    axios.defaults.headers = headers;
  }, [usuarioLogado]);

  const setUsuario = (usuario: IUsuarioLogado) => {
    if (usuario) {
      usuario.areas = Object.keys(usuario?.areasAndRoles || {}) as EArea[];
    }
    setUsuarioLogado(usuario);
  };

  const setArea = (areaLogada: EArea) => {
    if (
      areaLogada &&
      usuarioLogado?.areasAndRoles &&
      areaLogada in usuarioLogado.areasAndRoles
    ) {
      const rolesAreaLogada = usuarioLogado.areasAndRoles[areaLogada];
      setUsuarioLogado({ ...usuarioLogado, rolesAreaLogada, areaLogada });
    }
  };

  const setAuthorization = (value: string) => {
    const headers = {
      ...axios.defaults.headers,
      authorization: value,
    };
    axios.defaults.headers = headers;
  };

  const getAuthorization = () => {
    const { authorization } = axios.defaults.headers;
    return authorization;
  };

  const setDataUser = (value: any) => {
    setUsuarioLogado(value);
  };

  return (
    <AuthContext.Provider
      value={{
        usuarioLogado,
        setUsuario,
        setArea,
        setAuthorization,
        getAuthorization,
        setDataUser,
        enableMenuVertical,
        setEnableMenuVertical,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
