import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import "./styles.scss";

const {
  disable,
  secondaryText,
  error,
} = require("../../../ds/_palette.module.scss");

export const AETextField: React.ComponentType<TextFieldProps> = withStyles(
  () => ({
    root: {
      "& *": {
        display: "flex",
        letterSpacing: 0,
      },

      "&.MuiFormControl-root": {
        "& input": {
          fontSize: "1.4rem",
          padding: "1.1rem 1.6rem",
          width: "100%",
          zIndex: 1,

          "&:valid + fieldset": {
            borderWidth: ".1rem",
          },

          "&:valid:focus + fieldset": {
            borderWidth: ".2rem",
          },

          "&:disabled": {
            fontWeight: "600",
          },
        },

        "& input:not(.Mui-disabled)": {},

        "& textarea": {
          fontSize: "1.4rem",
          width: "100%",
          zIndex: 1,

          "&:valid + fieldset": {
            borderWidth: ".1rem",
          },

          "&:valid:focus + fieldset": {
            borderWidth: ".2rem",
          },

          "&:disabled": {
            fontWeight: "600",
          },
        },

        "& p": {
          margin: 0,
          marginTop: ".8rem",
        },

        "& label": {
          fontSize: "1.4em",
          fontWeight: "600",
          marginBottom: ".8rem",
          color: secondaryText,
        },

        "& legend": {
          width: 0,
        },

        "& .PrivateNotchedOutline-root-3": {
          // backgroundColor: '#fff'
        },

        "& .MuiInputLabel-formControl": {
          position: "relative",
        },

        "& .PrivateNotchedOutline-legendNotched-6": {
          width: 0,
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "none",
        },

        "& .MuiInputBase-root": {
          //height: '4rem',
        },

        "&.MuiTextField-root": {
          minWidth: "2.5em",
          position: "relative",
        },

        "& .MuiFormHelperText-root": {
          fontSize: "1.2rem",
          fontStyle: "italic",
        },

        "& .MuiOutlinedInput-root": {
          borderRadius: ".8rem",
        },

        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          border: `.2rem solid ${error}`,
          position: "absolute",
          bottom: "0rem",
        },

        "& .MuiOutlinedInput-root.Mui-disabled": {
          background: disable,
        },

        "& .PrivateNotchedOutline-legendNotched-5": {
          maxWidth: 0,
        },
      },
    },
  })
)((props: TextFieldProps) => (
  <>
    <TextField
      data-text-field-styles
      {...props}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      color="secondary"
    ></TextField>
    {props.children && props.children}
  </>
));
