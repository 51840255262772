import { AxiosError } from 'axios';

export const extrairMensagensErro = (err: AxiosError | {message:string} | any): string[] => {
 

  if (typeof err === 'string') {
    return [err];
  }

  if (Array.isArray(err) && err.length && typeof err[0] === 'string') {
    return err;
  }

  if(!!err.message) {
    return err as any;
  }

  const data = (err as AxiosError).response?.data || {};
  const mensagens = data?.message || data?.mensagem || data?.mensagens || ([] as any[]);
  const mensagemParaMostar: string[] = [];

  if (typeof mensagens === 'string') {
    return [mensagens];
  }

  if (mensagens instanceof Array) {
    for (const iterator of mensagens) {
      Object.values(iterator).forEach((valor) => {
        if (typeof valor === 'object') {
          Object.values(valor as any).forEach((v) => {
            if (typeof v === 'string') {
              mensagemParaMostar.push(v as string);
            }
          });
        }
      });
    }
    return mensagemParaMostar;
  }

  return ['Erro ao processar requisição.'];
};
