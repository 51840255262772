export enum TOAST_ACTION {
  SHOW = "SHOW",
  HIDE = "HIDE",
}

export enum TOAST_SERVERITY {
  WARNING = "warning",
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
}

export interface IToastAction {
  type: TOAST_ACTION;
  payload: IToast;
}

export interface IToast {
  show: boolean;
  severity: TOAST_SERVERITY;
  description?: string;
}

const toastReducer = (
  state: IToast = { show: false, severity: TOAST_SERVERITY.INFO },
  action: IToastAction
) => {
  switch (action.type) {
    case TOAST_ACTION.SHOW:
      return {
        ...action.payload,
        show: true,
      };

    case TOAST_ACTION.HIDE:
      return {
        ...action.payload,
        show: false,
      };

    default:
      return state;
  }
};

export default toastReducer;
