import { RadioGroup, RadioGroupProps } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import './styles.scss';

export const AERadioGroup: React.ComponentType<RadioGroupProps> = withStyles(
  () => ({
    root: {
      '&.MuiFormGroup-root': {
        flexDirection: 'row',
        flexWrap: 'unset',
      },
    },
  }),
)(RadioGroup);
