interface PasswordRules {
  rule: RegExp;
  error: string | undefined | null | any;
}

export const useValidaSenha = () => {
  const validarSenha = (senha: string): PasswordRules[] => {
    if (!senha) return [];

    let requiredLowerCase = {
      rule: /([a-z])/,
      error: "um caractere minúsculo",
    };
    let requiredUppercase = {
      rule: /([A-Z])/,
      error: "um caractere maiúsculo",
    };
    let requiredDigit = {
      rule: /[0-9]/,
      error: "pelo menos um digito",
    };
    let requiredLength = {
      rule: /.{8}/,
      error: "8 caracteres",
    };
    let requiredSpecialChars = {
      rule: /(!|@|#|\$|%|ˆ|&|\*|\+|=|\)|\(|\\}|\\{|\[|\\]|\?|<|>)/,
      error: "um caractere especial ex: @#$%^&+?}{()",
    };

    const rules: PasswordRules[] = [
      requiredLowerCase,
      requiredUppercase,
      requiredDigit,
      requiredLength,
      requiredSpecialChars,
    ];

    let errorMessages: PasswordRules[] = [];

    rules.forEach((item) => {
      if (!item.rule.test(senha)) {
        errorMessages.push(item.error);
      }
    });
    return errorMessages;
  };

  return { validarSenha };
};
