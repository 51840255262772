import React, { useEffect, useState } from 'react';

import "./styles.scss";

import {
  useGetObjetoEducacionalWithQuizByIdLazyQuery,
  Quizz
} from "../../../../generated/graphql";

interface QuizComponentProps {
  oeId: string;
}

export const QuizComponent:React.FC<QuizComponentProps> = (props) => {
  const [quiz, setQuiz] = useState<Quizz[]>([]);

  const [getQuizz] = useGetObjetoEducacionalWithQuizByIdLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if(!!response.objetoEducacional?.Quizzes && !!response.objetoEducacional?.Quizzes.length) {
        setQuiz(response.objetoEducacional?.Quizzes as Quizz[]);
      }
    }
  });

  useEffect(() => {
    !!props.oeId && getQuizz({
      variables: {
        id: props.oeId
      }
    });
    //eslint-disable-next-line
  }, [props.oeId]);

  return (
    <>
    {!!quiz && !!quiz.length && (
                    
      <div data-row data-quiz>
        <div>
          <strong>Questionário</strong>
          {
          quiz.map(quizItem => (
            !!quizItem.perguntas && !!quizItem.perguntas.length && (
              quizItem.perguntas.map((quizPergunta, idx) => (
                <div data-row key={idx}>
                  <div>
                  <p>{quizPergunta.pergunta?.enunciado}</p>
                    {!!quizPergunta.pergunta?.respostas && quizPergunta.pergunta?.respostas.length && (
                      <div data-row data-quiz-respostas>
                        <div>
                        <strong>Respostas</strong>
                          {quizPergunta.pergunta.respostas.map((quizResposta, idx) => (
                        <React.Fragment key={idx}>
                      <div data-row>
                        {!!quizResposta.opcao_correta ? (
                          <p><strong>{quizResposta.opcao}</strong></p>
                        ) : (
                          <p>{quizResposta.opcao}</p>
                        )}
                      </div>
                      </ React.Fragment>
                      ))}
                      </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )
          ))
        }
        </div>
        
      </div>
    )
    }
    </>
  )
}