import React, { useState } from "react";

import { IconeMeusConteudosBread } from "../../assets/icons";
import { AETabs, Breadcrumbs } from "../../shared/components";
import { AbaTodosConteudos } from "./components/AbaTodosConteudos";
import { AbaMinhasPendencias } from "./components/AbaMinhasPendencias";
import { CriacaoConteudoButton } from "./components/CriacaoConteudoButton";
// import { TotalRascunhoButton } from "./components/TotalRascunhoButton";
import { RoleUser, useAuth } from "../../contexts/Auth";
import { ConvitesPendentes } from "./components/ConvitesPendentes";

import "./styles.scss";

const abasByRole = {
  [RoleUser.Autor]: ["Todos os Conteúdos" /*, "Em análise", "Aprovar" */],
  [RoleUser.Gestão]: ["Todos os Conteúdos", "Minhas pendências"],
};

export const MeusConteudosPage: React.FC = () => {
  const { usuarioLogado } = useAuth();

  const [abaActive, setAbaActive] = useState<
    "Todos os Conteúdos" | "Minhas pendências"
  >("Todos os Conteúdos");

  const getBreadcrumbTitle = () => {
    let title = "Conteúdos";

    if (usuarioLogado?.role === RoleUser.Autor) {
      title = "Meus Conteúdos";
    }

    return title;
  };

  return (
    <>
      {!usuarioLogado ? (
        <></>
      ) : (
        <div data-meus-conteudos-page>
          <div data-row>
            <Breadcrumbs
              icon={IconeMeusConteudosBread}
              menus={[{ name: getBreadcrumbTitle() }]}
            ></Breadcrumbs>
          </div>

          {usuarioLogado?.role === RoleUser.Autor && (
            <>
              <div data-row data-buttons>
                <CriacaoConteudoButton />
                {/* <TotalRascunhoButton /> */}
              </div>

              <div data-row>
                <ConvitesPendentes />
              </div>
            </>
          )}

          <div data-tabs-meus-conteudos>
            <AETabs
              list={abasByRole[usuarioLogado?.role]}
              callback={(ev: "Todos os Conteúdos" | "Minhas pendências") => {
                setAbaActive(ev);
              }}
            />
            <div data-tabs-content>
              {abaActive === "Todos os Conteúdos" && <AbaTodosConteudos />}
              {abaActive === "Minhas pendências" && <AbaMinhasPendencias />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
