import React from 'react';
import { useConvitesPendentes } from '../../../../hooks/useConvitesPendentes';
import { ModalService } from '../../../../shared/components';
import { ModalConvitesPendentes } from '../ModalConvitesPendentes';

import './styles.scss';

export const ConvitesPendentes: React.FC = () => {
	const { dataResult } = useConvitesPendentes();

	const handleShowModal = () => {
		ModalService.show({
			content: <ModalConvitesPendentes data={dataResult} />,
		});
	};

	return (
		<>
			{!!dataResult.total && (
				<div data-convite-pendente>
					Você tem <strong>{dataResult.total}</strong> {dataResult.total > 1 ? 'convites' : 'convite'} de participação
					em conteúdos pendentes. <span onClick={handleShowModal}>Clique aqui</span> para visualizar
				</div>
			)}
		</>
	);
};
