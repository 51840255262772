import { CircularProgress } from "@material-ui/core";
import React, {useEffect, useState } from "react";
import { RoleUser, useAuth } from "../../../../contexts/Auth";
import { FilterSelected, useMeusConteudosContext } from "../../../../contexts/MeusConteudos";

import { TotalConteudosButton, TotalConteudosAExpirarButton, FiltersSelected, SelectStatus, SelectCategoria, CleanFilter, AutoSearch, ResultData } from "./components";

import "./styles.scss";

interface AbaTodosConteudosProps {}

export const AbaTodosConteudos: React.FC<AbaTodosConteudosProps> = (props) => {
  const { usuarioLogado } = useAuth();
  const [activeButton, setActiveButton] = useState<"Lista de Conteudos" | "A expirar">("Lista de Conteudos");
  const { loading, dataResult, filtersSelected } = useMeusConteudosContext();
  const [listaFiltrosSelecionados, setListaFiltrosSelecionados] = useState<string | undefined>()

  useEffect(()=>{
    const resultFiltersSelected = (filtersSelected?.reduce((acc: string[], item) => {
      acc.push(item.name);
      return acc;
    }, []));
    !!resultFiltersSelected && setListaFiltrosSelecionados(resultFiltersSelected.join(', '))
  },[filtersSelected])

  return (
    <section data-aba-conteudos>
      <div data-aba-header>
        {usuarioLogado?.role === RoleUser.Autor && (
          <div data-aba-header-title>Lista de Conteúdos</div>
        )}
        {usuarioLogado?.role === RoleUser.Gestão && (
          <div data-aba-header-buttons>
            <TotalConteudosButton
              handleActiveButton={(ev) => setActiveButton(ev)}
              activeButton={activeButton}
            />

            <TotalConteudosAExpirarButton
              handleActiveButton={(ev) => setActiveButton(ev)}
              activeButton={activeButton}
            />
          </div>
        )}

        <div data-aba-header-search>
          <AutoSearch />
        </div>
      </div>

      <div data-aba-filters>
        <h5>Filtrar por:</h5>
        <div data-aba-select-filters>
          <SelectStatus />

          <SelectCategoria />

          <CleanFilter />
        </div>

        <div data-filters-selected>
          <FiltersSelected />
        </div>
      </div>

      <div data-aba-content>
        {
          !!loading
          ? (
          <div data-loading>
            <CircularProgress data-circular-loading/>
            <p>Buscando resultados...</p>
          </div>
          )
          : (
            dataResult.totalCount === 0
            ? (
              <div data-search-void>
                Não foram encontrados resultados para a pesquisa
                  {
                    !!listaFiltrosSelecionados
                    ? <> de <span>{' "'+listaFiltrosSelecionados+'"'}</span></>
                    : ''
                  }
              </div>
            )
            : (<ResultData />)
          )
        }
      </div>
    </section>
  );
};
