import { useEffect, useState } from "react";
import { MenuItem } from "@material-ui/core";

import { AESelect } from "../../../../../../shared/components";

import {
  CategoriaFragment,
  useGetAllCategoriasLazyQuery,
} from "../../../../../../generated/graphql";
import { useMeusConteudosContext } from "../../../../../../contexts/MeusConteudos";

export const SelectCategoria = () => {
  const [list, setList] = useState<Pick<CategoriaFragment, "id" | "titulo">[]>(
    []
  );

  const { state, handleChange, setFiltersSelected, filtersSelected } = useMeusConteudosContext();

  const [getAll] = useGetAllCategoriasLazyQuery({
    onCompleted: (response) => {
      response.rotulosConnection?.values &&
        setList(response.rotulosConnection.values);
    },
  });

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AESelect
      name="categoria"
      variant="outlined"
      data-combo-filter
      value={state.categoria.value}
      onChange={(ev) => {
        const { value } = ev.target;

        handleChange("categoria", {
          value: (value || "") as string,
          label: list.find((item) => item.id === value)?.titulo || "",
        });

        setFiltersSelected([
          ...(filtersSelected || []).filter(item => item.type !== "categoria" || item.id !== value),
          {
            id: (value as string) || "0",
            name: list.find((item) => item.id.toString() === value)?.titulo || "",
            type: "categoria",
          },
        ]);
      }}
      inputProps={{ "aria-label": "Without label" }}
    >
      <MenuItem value="0" selected={state.categoria.value === "0"}>
        Categoria
      </MenuItem>
      {list.map((item, idx) => (
        <MenuItem
          key={idx}
          value={item.id.toString()}
          selected={item.id.toString() === state.categoria.value}
        >
          {item.titulo}
        </MenuItem>
      ))}
    </AESelect>
  );
};
