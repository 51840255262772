import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';

import { IconeMeusConteudosBread } from '../../assets/icons';

import Loader from '../../shared/components/Loader';

import {
	ObjetoEducacional,
	useGetHistoricoAvaliacaoAtualOePorIdLazyQuery,
	useGetObjetoEducacionalEdicaoPorIdLazyQuery,
	useGetPalavrasChaveByOeLazyQuery,
} from '../../generated/graphql';

import { AEButton, Breadcrumbs } from '../../shared/components';
import { DriveInsumos } from './components/DriveInsumos';
import { formatarStringDataParaDiaMesAno } from '../../shared/commom/formatarData';

import { converterListaParaString } from '../../shared/commom/parser';
import { QuizComponent } from './components/Quiz';
import { AutoresComponent } from './components/Autores';
import { splitTitle } from '../../shared/commom/formatText';
import { useAuth } from '../../contexts/Auth';

import './styles.scss';
import { ISelect } from '../../shared/interfaces';

export const VisualizacaoConteudoPage: React.FC = () => {
	const params: { id: string } = useParams();
	const [oe, setOe] = useState<ObjetoEducacional>();
	const [statusOe, setStatusOe] = useState<string>('');
	const [loading, setLoading] = useState(true);
	const [autorAcesso, setAutorAcesso] = useState(false);
	const history = useHistory();
	const { usuarioLogado } = useAuth();
	const [palavrasChave, setPalavrasChave] = useState<ISelect[]>([]);

	const [getObjetoEducacional] = useGetObjetoEducacionalEdicaoPorIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (response.findById) {
				setOe(response.findById as ObjetoEducacional);
			}

			if (!!response.findById && response.findById.Autores && params.id && !!usuarioLogado && !!usuarioLogado.autor) {
				const usuarioAutor = response.findById.Autores.find((a) => a.id === usuarioLogado.autor['id'].toString());
				const usuarioAutorConvidado = !usuarioAutor
					? response.findById.autor_convidados_v2?.find(
							(item) => item.autor_convidado === usuarioLogado.autor['id'].toString(),
					  )
					: '';

				!!usuarioAutor || usuarioAutorConvidado ? setAutorAcesso(true) : setAutorAcesso(false);
			}
			setLoading(false);
		},
		onError: (err) => {
			setLoading(false);
		},
	});

	const [getStatusOe] = useGetHistoricoAvaliacaoAtualOePorIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (response.findHistoricoAvaliacaoAtualByOeId && response.findHistoricoAvaliacaoAtualByOeId.titulo) {
				setStatusOe(response.findHistoricoAvaliacaoAtualByOeId.titulo);
			}
		},
	});

	const [getPalavrasChaveByOe] = useGetPalavrasChaveByOeLazyQuery({
		nextFetchPolicy: 'network-only',
		onCompleted: (response) => {
			setPalavrasChave(
				response?.findPalavraChavesByOe?.map((item) => {
					const iSelect: ISelect = {
						label: item?.titulo || '',
						value: item?.id || '',
					};
					return iSelect;
				}) || [],
			);
		},
	});

	async function getStatus() {
		getStatusOe({
			variables: {
				oeId: params.id,
			},
		});
	}

	useEffect(() => {
		if (params.id) {
			getStatus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oe]);

	async function getOe() {
		await getObjetoEducacional({
			variables: {
				id: params.id,
			},
		});
	}

	async function getPalavrasChave() {
		await getPalavrasChaveByOe({
			variables: {
				oe: params.id,
			},
		});
	}
	useEffect(() => {
		setLoading(true);
		getOe();
		getPalavrasChave();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<>
			{!!loading && <Loader show={!!loading} message="Aguarde enquanto o conteúdo é carregado..." />}
			{!loading && !autorAcesso && (
				<Loader
					show={!!loading}
					message="Você não possui acesso a este conteúdo, aguarde para ser redirecionado..."
					redirectUrl="/meus-conteudos"
				/>
			)}
			{!loading && !!autorAcesso && (
				<div data-container>
					<div data-visualizar-conteudo>
						<div data-wrapper>
							<div data-content>
								<div data-row>
									<Breadcrumbs
										icon={IconeMeusConteudosBread}
										menus={[
											{ name: 'Conteúdos', link: '/meus-conteudos' },
											{ name: 'Para Aprovar' },
											{ name: splitTitle(oe?.Titulo || '', 39), active: true },
										]}
									></Breadcrumbs>

									<div data-status>
										<p>Status: {statusOe}</p>
									</div>
								</div>

								<div data-content-wrap>
									<div data-dados-oe>
										{!!oe && (
											<>
												{oe.Titulo && (
													<div data-row>
														<div>
															<strong>Título</strong>
															<p>{oe.Titulo}</p>
														</div>
													</div>
												)}

												{oe.Descricao && (
													<div data-row>
														<div>
															<strong>Descrição Completa</strong>
															<p
																dangerouslySetInnerHTML={{
																	__html: oe?.Descricao as string,
																}}
															/>
														</div>
													</div>
												)}

												{oe.Resumo && (
													<div data-row>
														<div>
															<strong>Descrição Resumida</strong>
															<p>{oe?.Resumo}</p>
														</div>
													</div>
												)}

												{oe.AreasConhecimento && !!oe.AreasConhecimento.length && (
													<div data-row>
														<div>
															<strong>Área de Conhecimento</strong>
															<p>{converterListaParaString(oe.AreasConhecimento, 'titulo')}</p>
														</div>
													</div>
												)}

												{!!oe.SubAreaConhecimento && !!oe.SubAreaConhecimento.length && (
													<div data-row>
														<div>
															<strong>Subárea de Conhecimento</strong>
															<p>{converterListaParaString(oe.SubAreaConhecimento, 'Nome')}</p>
														</div>
													</div>
												)}

												{!!palavrasChave && !!palavrasChave.length && (
													<div data-row>
														<div>
															<strong>Palavra-Chave</strong>
															<p>{converterListaParaString(palavrasChave, 'label')}</p>
														</div>
													</div>
												)}
											</>
										)}

										{oe?.PublicoAlvo && oe?.TipoObjeto && (
											<div data-row className="two-columns">
												{/* <div>
													<strong>Categoria</strong>
													<p>{converterListaParaString(oe.Rotulos, 'titulo')}</p>
												</div> */}

												<div>
													<strong>Público-Alvo</strong>
													<p>{converterListaParaString(oe.PublicoAlvo, 'titulo')}</p>
												</div>
												<div>
													<strong>Tipo de Mídia</strong>
													<p>{converterListaParaString(oe.TipoObjeto, 'titulo')}</p>
												</div>
											</div>
										)}

										<div data-row>
											<div>
												<strong>Há restrição de acesso do conteúdo para o público não-médico?</strong>
												<p>
													{oe?.RestricaoPublicoMedico
														? 'SIM. Somente pode ser acessado por médicos'
														: 'Não. Pode ser acessado por todos.'}
												</p>
											</div>
										</div>

										{!!oe?.Rotulos && !!oe.Rotulos.length && (
											<div data-row>
												<div>
													<strong>Categoria</strong>
													<p>{converterListaParaString(oe.Rotulos, 'titulo')}</p>
												</div>
											</div>
										)}

										{!!oe?.Autores && !!oe.Autores.length && (
											<AutoresComponent autoresDaOe={oe.Autores} oeId={params.id} />
										)}

										{oe?.DataProducao && oe?.ValidadeConteudo && (
											<div data-row className="two-columns">
												<div>
													<strong>Data de Criação</strong>
													<p>{formatarStringDataParaDiaMesAno(oe.DataProducao)}</p>
												</div>
												<div>
													<strong>Data de Validade</strong>
													<p>{formatarStringDataParaDiaMesAno(oe.ValidadeConteudo)}</p>
												</div>
											</div>
										)}

										{oe?.Infografico && !!oe.Infografico.length && (
											<div data-row>
												<div>
													<strong>{`Arquivos`}</strong>
													{oe.Infografico.map((infografico, index) => (
														<div key={infografico.id}>
															<div
																dangerouslySetInnerHTML={{
																	__html: infografico.Descricao as string,
																}}
															/>
														</div>
													))}
												</div>
											</div>
										)}

										{oe?.SecoesTexto &&
											!!oe.SecoesTexto.length &&
											oe.SecoesTexto.map((secaoTexto, index) => (
												<div key={secaoTexto.id} data-row>
													<div>
														<strong>{`Conteúdo do capítulo ${index + 1}`}</strong>
														<div
															dangerouslySetInnerHTML={{
																__html: secaoTexto.Secao as string,
															}}
														/>
													</div>
												</div>
											))}

										{oe?.VideoAudio && !!oe.VideoAudio.length && (
											<div data-row>
												<div data-video-vimeo>
													<strong>Vídeo / Áudio</strong>
													{oe.VideoAudio.map((videoAudioItem, index) => (
														<div key={index}>
															{videoAudioItem.URL && (
																<Vimeo controls={true} video={videoAudioItem.URL} className="player-video" />
															)}
														</div>
													))}
												</div>
											</div>
										)}

										<QuizComponent oeId={params.id} />

										<DriveInsumos objetoEducacionalId={params.id} />

										{oe?.comentario_drive_insumo && (
											<div data-row>
												<div>
													<strong>Comentários de observação</strong>
													<p>{oe.comentario_drive_insumo}</p>
												</div>
											</div>
										)}

										<div data-buttons>
											<AEButton color="primary" variant="outlined" onClick={() => history.push('/meus-conteudos')}>
												Ir para Meus Conteúdos
											</AEButton>

											<AEButton
												color="primary"
												variant="contained"
												type="button"
												onClick={() => history.push('/conteudo/' + params.id)}
											>
												Editar
											</AEButton>
										</div>
									</div>

									<div data-ultima-atualizacao>
										<p>Últ. Atualização: {oe?.DataAtualizacaoProducao}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
