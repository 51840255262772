import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Close from '@material-ui/icons/Close';

import './styles.scss';

interface IModalServiceAPIs {
  show: (config: {
    showComponentInFullScreen?: boolean;
    content: JSX.Element;
    notPossibleClose?: boolean;
  }) => void;
  hide: (...args: any[]) => void;
}

interface IModalControllerShowInput {
  content: JSX.Element;
  showComponentInFullScreen: boolean;
  notPossibleClose?: boolean;
}

let ModalService = {} as IModalServiceAPIs;

export const Modal: React.FC = ({ children }) => {
  const [state, setState] = useState<IModalControllerShowInput>(
    {} as IModalControllerShowInput,
  );
  const [show, setShow] = useState<boolean>(false);

  const [dimmerColor, setDimmerColor] = useState("");

  ModalService.show = (config) => {
    setState(config as IModalControllerShowInput);
    
    setDimmerColor("");
    setShow(true);
    setTimeout(() => setDimmerColor("black"), 70);
  };

  ModalService.hide = () => {
    setState({
      content: <></>,
      showComponentInFullScreen: false,
    });
    setShow(false);
  };

  const closeMe = () => {
    ModalService.hide();
  };

  const modal = (
    <>
      <div
        data-dimmer={dimmerColor}
        onClick={!state.notPossibleClose ? closeMe : () => {}}
      ></div>
      <div
        data-modal
        className={state.showComponentInFullScreen ? 'fullscreen' : ''}
      >
        {!state.notPossibleClose && (
          <IconButton aria-label="delete" size="medium" onClick={closeMe}>
            <Close data-modal-close />
          </IconButton>
        )}
        <div>{state.content}</div>
      </div>
    </>
  );

  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export { ModalService };
