import { useDispatch } from "react-redux";
import { TOAST_ACTION, TOAST_SERVERITY } from "../redux/toast/toast.reducer";

type ToastPayloadType = {
  severity: TOAST_SERVERITY;
  description: string;
}

export const useToast = () => {
  const dispatch = useDispatch();

  return (options: ToastPayloadType) => {
    dispatch({
      type: TOAST_ACTION.SHOW,
      payload: {
        ...options
      },
    });
  }
}