import React, { useEffect } from "react";

import useRedirect from "../../services/utils/redirect";
import { useUsuarioService } from "../../shared/services/Usuario";

import "./modals/modalSelecaoArea/styles.scss";
import "./styles.scss";

interface HomeProps {}

export const HomePage: React.FC<HomeProps> = () => {
  const redirectAfterLogin = useRedirect();
  const service = useUsuarioService();

  useEffect(() => {
    try {
      redirectAfterLogin();
    } catch (error) {
      service.logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1>Redirecionando...</h1>
    </>
  );
};
