import React from "react";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@material-ui/core";

import { Routes } from "./routes";
import theme from "./theme/light";
import { AuthProvider } from "./contexts/Auth";
import { Modal, Toast } from "./shared/components";
import Client from './services/graphql.service';

import { Provider } from 'react-redux';
import Store from './redux/index';

import "./App.scss";

const App: React.FC = () => {
  return (
    <ApolloProvider client={Client}>
      <Provider store={Store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Routes />
            <Modal />
            <Toast />
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
