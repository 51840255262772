import { RoleUser } from './../../contexts/Auth';

import { useAuth } from '../../contexts/Auth';

export default function useRedirect() {
	const { usuarioLogado } = useAuth();

	const redirectAfterLogin = () => {
		const obj: any = {
			Autor: '/meus-conteudos',
			Gestão: '/conteudos',
		};

		if (!usuarioLogado) {
			throw new Error();
		}

		const pathName = Object.keys(obj).some((role) => (role as RoleUser) === usuarioLogado.role)
			? obj[usuarioLogado.role]
			: null;

		if (!pathName) {
			throw new Error();
		}

		if (!!window.location.href.includes("/homepage")) {
			window.location.href = pathName;
		}
	};

	return redirectAfterLogin;
}
