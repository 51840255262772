import React, { useState } from 'react';
import { AETextField, AEIconButton } from '..';
import './styles.scss';

interface Props {
  onChange(text: string): void;
  placeholder?: string;
  noResults?: boolean;
}

export const AEBusca: React.FC<Props> = ({
  onChange,
  placeholder,
  noResults,
}) => {
  const [filtro, setFiltro] = useState('');

  const handleBusca = (e: any) => {
    e.preventDefault();
    if (noResults) {
      setFiltro('');
      onChange('');
      return;
    }
    onChange(filtro);
  };

  return (
    <form onSubmit={handleBusca} data-search>
      <AETextField
        placeholder={placeholder || 'Buscar...'}
        value={filtro}
        onChange={(e: any) => setFiltro(e.target.value)}
      />
      {noResults ? (
        <AEIconButton
          data-search-button
          color="primary"
          icon="fechar_branco"
          onClick={handleBusca}
        />
      ) : (
        <AEIconButton
          data-search-button
          color="primary"
          icon="pesquisa_branco"
          onClick={handleBusca}
        />
      )}
    </form>
  );
};
