import { extrairMensagensErro } from "../../../shared/commom/extrair-mensagens-erro";
import { AEButton, ModalService } from "../../../shared/components";
import "./styles.scss";

export const modalErro = (title: string, err?: any, callback?: Function) => {
  const erros = err ? extrairMensagensErro(err) : [];
  ModalService.show({
    content: (
      <div data-content-modal>
        <p data-modal-title>{title}</p>
        {erros.map((mensagem, index) => (
          <p key={index} data-modal-line>
            {mensagem}
          </p>
        ))}
        <div data-modal-error-buttons>
          <AEButton
            data-modal-button-close
            color="primary"
            variant="contained"
            onClick={() => {
              ModalService.hide();
              callback && callback();
            }}
          >
            Ok
          </AEButton>
        </div>
      </div>
    ),
  });
};
