import React, { useEffect, useState } from 'react';
import './styles.scss';

interface IAnimationWordsProps {
  delay?: number;
  overflowVisible?: 'x' | 'y' | 'xy';
};

type CSSOverflowAttributeValues = 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';

const overflowVisibility = {
  x: {
    overflowX: 'visible' as CSSOverflowAttributeValues
  },
  y: {
    overflowY: 'visible' as CSSOverflowAttributeValues
  },
  xy: {
    overflow: 'visible' as CSSOverflowAttributeValues
  },
  default: {
    overflow: 'hidden' as CSSOverflowAttributeValues
  }
};

/**
 * Este é um componente especial que aplica motion para textos diversos.
 * @param props
 * @returns 
 */
export const AnimateWords: React.FC<IAnimationWordsProps> = ({ delay, overflowVisible, children }) => {
  let [text, setText] = useState<string>('');
  let isOverflowVisible: keyof typeof overflowVisibility = overflowVisible || 'default';

  useEffect(() => {
    let _letters = children as string;

    _letters = _letters
      .split('')
      .map((letter, idx) => `<span ${letter !== ' ' && `class="_l${idx + 1}"`}>${letter !== ' ' ? letter : '&nbsp;'}</span>`)
      .join('')
      .split('<span false>&nbsp;</span>')
      .map(w => `<div>${w}</div>`)
      .join(' ');

      setTimeout(() => { setText(_letters) }, delay || 0);
  }, [children, delay]);

  return (
    !text ? 
      <div data-animate-words-placeholder>{ children }</div> :
      <div data-animate-words dangerouslySetInnerHTML={{ __html: text}} style={overflowVisibility[isOverflowVisible]}></div>
  );
};