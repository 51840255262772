import React from "react";
import { useMeusConteudosContext } from "../../../../../../contexts/MeusConteudos";

interface TotalConteudosButtonProps {
  activeButton: "Lista de Conteudos" | "A expirar";
  handleActiveButton(ev: "Lista de Conteudos" | "A expirar"): void;
}

export const TotalConteudosButton: React.FC<TotalConteudosButtonProps> = (
  props
) => {
  const { dataResult, setState, state } = useMeusConteudosContext();

  const handleLoadAllContent = () => {
    props.handleActiveButton("Lista de Conteudos");

    setState({
      ...state,
      isExpired: false
    });
  }

  return (
    <button
      type="button"
      className={
        props.activeButton === "Lista de Conteudos" ? "button-active" : ""
      }
      onClick={handleLoadAllContent}
    >
      Lista de Conteúdos ({dataResult.total})
    </button>
  );
};
