import { IHateoas } from "./utils/response-data";

export const buildHateos = (
  path: string,
  total: number,
  take: number,
  skip: number,
  filtro?: string
) => {
  take = +take;
  skip = +skip;

  if (!skip) skip = 0;

  if (skip && skip > total) skip = total;

  if (take && take > total) take = total;

  if (total === 0) return [];

  if (filtro) {
    path += `filtro=${filtro}&`;
  }

  const links: IHateoas[] = [];
  let self = path;
  let primeira = path;
  let ultima = path;
  let proxima = path;
  let anterior = path;

  if (take) {
    primeira += `take=${take}&`;
    self += `take=${take}&`;
    ultima += `take=${take}&`;
    proxima += `take=${take}&`;
    anterior += `take=${take}&`;

    self += `skip=${skip}&`;
    ultima += `skip=${Math.trunc(total / take) * take || 0}&`;
    if (skip + take < total) proxima += `skip=${skip + take || 0}&`;
    anterior += `skip=${skip - take || 0}&`;

    links.push({
      rel: "self",
      href: self,
      type: "GET",
    });

    if (skip - take >= 0) {
      links.push({
        rel: "anterior",
        href: anterior,
        type: "GET",
      });

      links.push({
        rel: "primeira",
        href: primeira,
        type: "GET",
      });
    }

    if (skip + take < total) {
      links.push({
        rel: "proxima",
        href: proxima,
        type: "GET",
      });

      links.push({
        rel: "ultima",
        href: ultima,
        type: "GET",
      });
    }
  } else {
    links.push({
      rel: "self",
      href: path,
      type: "GET",
    });
  }

  for (const idx in links) {
    const link = links[idx].href;
    if (
      link.charAt(link.length - 1) === "&" ||
      link.charAt(link.length - 1) === "?"
    ) {
      links[idx].href = link.substring(link.length - 1, 1);
    }
  }

  return links;
};
