import React, { useState } from "react";

import { AEButton, AECheckbox, ModalService } from "../../../../shared/components";

import "./styles.scss";

interface Props {
  aceiteStatus: boolean;
  handleAceite(valor: boolean): void;
}

export const ModalCessaoDireitos: React.FC<Props> = (props) => {
  const [aceite, setAceite] = useState(props.aceiteStatus);
  const [error, setError] = useState(false);

  return (
    <div data-modal-cessao-direitos>
      <h2>Termo de Cessão de Direitos</h2>

      <div data-modal-termo>
        <p>
          Eu, designado CEDENTE, firmo e celebro com a SOCIEDADE BENEFICENTE
          ISRAELITA BRASILEIRA HOSPITAL ALBERT EINSTEIN, por meio da ACADEMIA
          DIGITAL EINSTEIN, com sede na Cidade de São Paulo, Estado de São
          Paulo, na Av. Albert Einstein, 627/701, Morumbi, CEP 05652-900, ora
          designada CESSIONÁRIA, o presente TERMO DE AUTORIZAÇÃO DE USO DE
          IMAGEM E VOZ PRA FINS EDUCACIONAIS mediante às cláusulas e condições
          abaixo discriminadas, que voluntariamente aceitam e outorgam:
        </p>
        <p>
          1- por meio do presente instrumento, o CEDENTE autoriza a CESSIONÁRIA
          a utilizar a sua imagem e/ou voz, captada por meio de fotografias,
          gravações de áudios e/ou filmagens de depoimentos, declarações,
          videoconferência, conferência web, entrevistas e/ou ações outras
          realizadas a serem utilizados com fins educacionais em território
          nacional ou estrangeiro;
        </p>
        <p>
          2- O CEDENTE tem ciência que a transferência é concedida em caráter
          total, gratuito e não exclusivo;
        </p>
        <p>
          3- O CEDENTE declara que a CESSIONÁRIA está autorizada a ser
          proprietária dos resultados dos materiaos produzidos, com direito de
          utilização, de forma ilimitada e por prazo indeterminado no que se
          refere à concessão de direitos autorais, utilização e licenciamento a
          terceiros, para que façam uso, de qualquer forma, no todo ou em parte,
          deste material ou de qualquer reprodução do mesmo, declara, ainda, que
          renuncia a qualquer direito de fiscalização ou aprovação do uso da
          imagem e outras informações ou de utilizações decorrentes da mesma.
          Reconhece e concorda em não exigir qualquer indenização relacionada ao
          exercício das autorizações concedidas por meio deste instrumento;{" "}
        </p>
        <p>
          4- a cessão objeto deste Termo abrange o direito do CESSIONÁRIO de
          utilizar a IMAGEM E VOZ do CEDENTE sob as modalidades existentes, tais
          como reprodução, representação, tradução, distribuição, entre outras;{" "}
        </p>
        <p>
          5- a cessão dos direitos autorais relativos à IMAGEM E VOZ do CEDENTE
          é por prazo indeterminado. Fica designado o foro da Comarca de São
          Paulo, SP, para dirimir quaisquer dúvidas relativas ao cumprimento
          deste instrumento, desde que não possam ser superadas de comum acordo
          entre as partes.
        </p>
      </div>

      <label
        data-modal-aceite-checkbox
        htmlFor="aceitoTermo"
        className={error && !aceite ? "error" : ""}
      >
        <AECheckbox
          color="primary"
          name="aceitoTermo"
          id="aceitoTermo"
          checked={aceite}
          onChange={() => {
            setAceite(!aceite);
            setError(false);
          }}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <span>
          Aceito o termo de Cessão de Direitos para a Academia Digital Einstein
        </span>
      </label>

      <div data-buttons>
        <AEButton
          color="primary"
          variant="outlined"
          onClick={() => ModalService.hide()}
        >
          Voltar
        </AEButton>
        <AEButton
          id="btn-finaliza"
          color="primary"
          variant="contained"
          onClick={() => {
            props.handleAceite(aceite);
            if (!aceite) {
              setError(true);
              return;
            }
            ModalService.hide();
          }}
        >
          Finalizar cadastro
        </AEButton>
      </div>
    </div>
  );
};
