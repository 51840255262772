import axios from "axios";
import { createContext, useContext } from "react";
import { obterDadosUsuario } from "../../commom/obter-roles-usuario";
import { IUsuarioLogado } from "../../../contexts/Auth";

class UsuarioService {
  public isAuthenticated = false;
  public jwt: string = "";

  async login(
    usuario: string,
    senha: string
  ): Promise<{ usuarioLogado: IUsuarioLogado; authorization: string }> {
    try {
      const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace(
        "/graphql",
        ""
      )}/perfil-de-autors/auth`;

      const response = await axios.post(URL, {
        username: usuario,
        password: senha,
      });

      if (!response.data || response.status !== 200) {
        return Promise.reject();
      }

      const { areasAndRoles } = obterDadosUsuario(response.data);

      const usuarioLogado: IUsuarioLogado = {
        idUsuario: response.data?.user?.id,
        areasAndRoles,
        nomeUsuario: response.data?.user?.nome_completo,
        autor: response.data?.autor,
        jwt: response.data?.jwt,
        isAvatar: response.data?.user?.Avatar !== null,
        avatar: response.data?.user?.Avatar?.url,
        role: response.data?.user?.role?.name
      };

      return { usuarioLogado, authorization: `Bearer ${response.data?.jwt}` };
    } catch (error: any) {
      console.error(error.response.data);
      return Promise.reject(error.response.data);
    }
  }

  logout() {
    try {
      localStorage.removeItem("hea_token");
      axios.defaults.headers = {};
      this.isAuthenticated = false;

      const publicUrls = ["", "login", "nova-conta", "404", "trocar-senha"];

      if(!publicUrls.some(url => window.location.href.includes(url))) {
        window.location.href = "/login";
      }
    } catch (error) {
      this.isAuthenticated = false;
    }
  }
}

const instance = new UsuarioService();
const context = createContext(instance);

export default instance;

// custom hook de serviço
export const useUsuarioService = () => {
  return useContext(context);
};
