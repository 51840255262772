import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
	IconeMeusConteudosAtivo,
	IconeMeusConteudosInativo,
	// IconeAtividadeAtivo,
	// IconeAtividadeInativo,
} from '../../assets/icons';
import { RoleUser, useAuth } from '../../contexts/Auth';

import './styles.scss';

interface MenuVerticalProps {
	open: boolean;
}

export const MenuVertical: React.FC<MenuVerticalProps> = (props) => {
	const location = useLocation();
	const { usuarioLogado } = useAuth();

	const [menuConteudo, setMenuConteudo] = useState({
		link: '/meus-conteudos',
		icon: location.pathname.includes('conteudo') ? IconeMeusConteudosAtivo : IconeMeusConteudosInativo,
		name: 'Meus Conteúdos',
		path: 'conteudo',
	});

	useEffect(() => {
		if(!usuarioLogado) {
			return;
		}

		if(usuarioLogado.role === RoleUser.Gestão) {
			setMenuConteudo({
				link: '/conteudos',
				icon: location.pathname.includes('conteudo') ? IconeMeusConteudosAtivo : IconeMeusConteudosInativo,
				name: 'Conteúdos',
				path: 'conteudo',
			});
		}
	}, [usuarioLogado]);
	


	const menu = [
		menuConteudo,
		// {
		//   link: "/atividades",
		//   icon: location.pathname.includes("atividade")
		//     ? IconeAtividadeAtivo
		//     : IconeAtividadeInativo,
		//   name: "Atividades",
		//   path: "atividade",
		// },
	];

	return (
		<>
			{props.open && (
				<aside data-menu-vertical>
					<ul>
						{menu.map((item, idx) => (
							<li key={idx} data-menu className={location.pathname.includes(item.path) ? 'data-menu-active' : ''}>
								<a href={item.link}>
									<div data-menu>
										<img src={item.icon} alt="" />
									</div>
									<span>{item.name}</span>
								</a>
							</li>
						))}
					</ul>
				</aside>
			)}
		</>
	);
};
