import { useDispatch } from "react-redux";
import {
  useCreateHistoricoAvaliacaoObjetoEducacionalMutation,
  useGetStatusPorTituloLazyQuery,
  Enum_Statusdepublicacao_Enum_Status,
} from "../generated/graphql";

import { TOAST_ACTION, TOAST_SERVERITY } from "../redux/toast/toast.reducer";

interface CreateHistoricoStatusOptions {
  userId: string;
  oeId: string;
  statusEnum: Enum_Statusdepublicacao_Enum_Status;
}

export default function useCreateHistoricoStatusOe() {
  const dispatch = useDispatch();

  const [getStatus] = useGetStatusPorTituloLazyQuery({
    fetchPolicy: "network-only",
  });

  const [criarHistorico] = useCreateHistoricoAvaliacaoObjetoEducacionalMutation(
    {
      fetchPolicy: "network-only",
    }
  );

  const getStatusAndCreateHistorico = async (options: CreateHistoricoStatusOptions) => {
    try {
      const result = await getStatus({
        variables: {
          enum_status: options.statusEnum,
        },
      });

      const status =
        !!result.data?.statusDePublicacaos &&
        !!result.data?.statusDePublicacaos.length
          ? result.data?.statusDePublicacaos[0].id
          : "";

      if (!status) {
        throw new Error("Status not found");
      }

      await criarHistorico({
        variables: {
          input: {
            data: {
              objeto_educacional: options.oeId,
              status_de_publicacao: status,
              users_permissions_user: options.userId,
            },
          },
        },
      });
    } catch (error) {
      dispatch({
        type: TOAST_ACTION.SHOW,
        payload: {
          severity: TOAST_SERVERITY.ERROR,
          description: "Ocorreu um erro ao criar novo histórico.",
        },
      });
    }
  };

  return { getStatusAndCreateHistorico };
}
