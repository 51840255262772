import * as Yup from "yup";
import { validarURL } from "../AutorValidacao/util/validarUrl";


class OeAnexoDocumentos {
  criarSchemaValidacao() {
    return Yup.object().shape({
      link: Yup.string().test({
        message: 'Link inválido',
        test: (value) =>
          value ? validarURL(value || "") : true,
      }),
    });
  }
}


export default new OeAnexoDocumentos();
