import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { decryptLocalStorage } from "../hooks/persistedState";

export const TOKEN_SECRECT = "hea_token";

const usuarioLogado = decryptLocalStorage(TOKEN_SECRECT);

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const authLink = setContext((_, { headers }) => {
  const options: any = {
    headers: { ...headers },
  };

  if (!!usuarioLogado) {
    options.headers["authorization"] = `Bearer ${usuarioLogado.jwt}`;
  }

  return options;
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    resultCaching: true,
  }),
  headers: usuarioLogado
    ? {
        Authorization: usuarioLogado.jwt,
      }
    : ({} as Record<string, string>),
});

export default client;
