import { useRef } from "react";
import { useAuth } from "../contexts/Auth";
import { modalErro } from "../shared/Modals/modalError/modal-erro";
import { modalSucesso } from "../shared/Modals/modalSucesso/modal-sucesso";

interface DriveInsumoProps {
  autorId: string;
  objetoEducacionalId: string;
}

export default function useDriveInsumo(config: DriveInsumoProps) {
  const documentoInputRef = useRef<any | null>(null);
  const documentoAutorizacaoInputRef = useRef<any | null>(null);
  const {usuarioLogado} = useAuth();

  const handleUploadInsumo = async (isAutorizacao: boolean, inputRef: any) => {
    const files = _getFiles(inputRef)

    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("autorId", config.autorId);
      formData.append("objetoEducacionalId", config.objetoEducacionalId);
      formData.append("isAutorizacao", isAutorizacao.toString());

      const result = await _sendFormData("create-S3", formData);
      return result;
    }
  };

  const handleUploadInsumoDaPergunta = async (
    isAutorizacao: boolean,
    quizzId: string,
    listaLinksUrl: string[] = [],
    descricao: string,
    listaRespostas: any[]
  ) => {
    // const files = _getFiles()

    // for (const file of files) {
    //   const formData = new FormData();
    //   formData.append("file", file);
    //   formData.append("autorId", config.autorId);
    //   formData.append("objetoEducacionalId", config.objetoEducacionalId);
    //   formData.append("quizzId", quizzId);
    //   formData.append("isAutorizacao", isAutorizacao.toString());
    //   formData.append("descricao", descricao);
    //   formData.append("listaRespostas", JSON.stringify(listaRespostas));
    //   formData.append("listaLinksUrl", JSON.stringify(listaLinksUrl));

    //   const result = await _sendFormData("create-quiz-S3", formData);
    //   return result;
    // }

    documentoInputRef.current = null;
    documentoAutorizacaoInputRef.current = null;
  };

  const _getFiles = (inputRef: any) => {
    if (!inputRef || !inputRef.current) {
      return;
    }

    const files = inputRef.current.files;

    return files;
  }

  const _sendFormData = async (endPoint: "create-S3" | "create-quiz-S3", formDataAtual: BodyInit | null | undefined) => {
    const URL = `${process.env.REACT_APP_ROOT}/drive-insumo-objeto-educacionals/${endPoint}`;

    const headers = {
      Authorization: `Bearer ${usuarioLogado!.jwt}`
    }

    const upload = await fetch(URL, {
      method: "POST",
      headers,
      body: formDataAtual,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        modalSucesso({ titulo: data.message });
        return data;
      })
      .catch((err) => {
        modalErro("Erro ao fazer o upload", err);
      });

    return upload;
  }

  return { handleUploadInsumo, handleUploadInsumoDaPergunta, documentoInputRef, documentoAutorizacaoInputRef };
}
