import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import './styles.scss';
import { useEffect } from 'react';
import { AETextField, AECheckbox } from '../../components';
import { normalizeStr } from '../../../helpers';

interface listArray {
  id: string;
  nome: string;
  processos: string[];
}

interface Props {
  listArray: listArray[];
  callback(callback: string[]): void;
}

export const AEMultiSelectItems: React.ComponentType<Props> = ({
  listArray,
  callback,
}) => {
  const [itemsSelecionados, setItemsSelecionados] = useState<string[]>([]);
  const [itemsFiltrados, setItemsFiltrados] = useState<listArray[]>([]);

  useEffect(() => {
    setItemsFiltrados(listArray);
  }, [listArray]);

  useEffect(() => {
    callback(itemsSelecionados);
  }, [callback, itemsSelecionados]);

  const filterItems = (filtro: string) => {
    if (filtro !== '') {
      const res = listArray.filter((item) => {
        if (
          normalizeStr(item.nome.toLowerCase()).includes(
            normalizeStr(filtro.toLowerCase()),
          )
        ) {
          return true;
        }
        if (
          normalizeStr(item.processos.join(' ').toLowerCase()).includes(
            normalizeStr(filtro.toLowerCase()),
          )
        ) {
          return true;
        }
        return false;
      });
      setItemsFiltrados(res);
      return;
    }
    setItemsFiltrados(listArray);
    return new Promise((resolve) => {
      resolve(
        itemsFiltrados.map((item) => {
          return {
            value: item.id,
            label: item.nome,
          };
        }),
      );
    });
  };

  const handleTodosSelecionados = () => {
    if (itemsSelecionados.length === itemsFiltrados.length) {
      setItemsSelecionados([]);
      return;
    }
    const newItemsSelecionados: string[] = [];
    itemsFiltrados.map((item: listArray) => newItemsSelecionados.push(item.id));
    setItemsSelecionados(newItemsSelecionados);
    return;
  };

  const handleItemsSelecionados = (id: string) => {
    if (itemsSelecionados.includes(id)) {
      const copy = [...itemsSelecionados];
      const index = copy.indexOf(id);
      copy.splice(index, 1);
      setItemsSelecionados(copy);
      return;
    }
    setItemsSelecionados([...itemsSelecionados, id]);
  };

  return (
    <>
      <AETextField
        data-async-select
        name="busca"
        id="busca"
        onChange={(filtro) => filterItems(filtro.target.value)}
      />
      <div data-selecionar-avaliadores-table>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell scope="row">
                  <AECheckbox
                    color="primary"
                    checked={itemsFiltrados.length === itemsSelecionados.length}
                    onChange={() => handleTodosSelecionados()}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
                <TableCell colSpan={2} scope="row">
                  <p>
                    {itemsFiltrados.length > 1 ? 'Encontrados ' : 'Encontrado '}
                    <strong>
                      {itemsFiltrados.length > 1
                        ? `${itemsFiltrados.length} nomes `
                        : `${itemsFiltrados.length} nome `}
                    </strong>
                    para esse filtro
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsFiltrados.map((item) => (
                <TableRow key={item.id}>
                  <TableCell scope="row">
                    <AECheckbox
                      color="primary"
                      name={item.id}
                      id={item.id}
                      checked={itemsSelecionados.includes(item.id)}
                      onChange={() => handleItemsSelecionados(item.id)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                  <TableCell scope="row">{item.nome}</TableCell>
                  <TableCell scope="row">
                    {item.processos?.map((item: string) => `${item}; `)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div data-multi-select-total-selecionados>
        <strong>{itemsSelecionados.length}</strong> selecionados
      </div>
    </>
  );
};
