import { AEButton, ModalService } from "../../../shared/components";
import "./styles.scss";

interface Configs {
  tituloBotao?: string;
  titulo?: string;
  mensagens?: string[];
  callback?(): void;
}

export const modalSucesso = (configs: Configs) => {
  ModalService.show({
    notPossibleClose: true,
    content: (
      <div data-content-modal>
        {configs.titulo && <h1 id="modal-title" data-content-modal-title>{configs.titulo}</h1>}

        {configs.mensagens &&
          configs.mensagens.map((message, index) => (
            <p id="modal-description" data-content-modal-line key={index}>
              {message}
            </p>
          ))}

        <div data-buttons>
          <AEButton
            id="btn-modal-ok"
            color="primary"
            variant="contained"
            onClick={() => {
              ModalService.hide();
              configs.callback && configs.callback();
            }}
          >
            {configs.tituloBotao || "        OK        "}
          </AEButton>
        </div>
      </div>
    ),
  });
};
