import { withStyles } from '@material-ui/core/styles';
import { createStyles, IconButton, IconButtonProps } from '@material-ui/core';
import './styles.scss';

export interface IAEIconButton extends IconButtonProps {
  icon:
    | 'alterar_senha'
    | 'avaliadores'
    | 'configuracoes'
    | 'correto'
    | 'editar'
    | 'excecao_contorno'
    | 'exececao_full'
    | 'excluir'
    | 'excluir_cinza'
    | 'fechar'
    | 'fechar_branco'
    | 'info_contorno'
    | 'info_full'
    | 'logout'
    | 'logout_branco'
    | 'pagina_anterior'
    | 'pesquisar'
    | 'pesquisa_branco'
    | 'primeira_pagina'
    | 'processos'
    | 'provas'
    | 'proxima_pagina'
    | 'staff'
    | 'ultima_pagina';
}

/**
 * Customização do MaterialUI IconButton para nossa aplicação
 */
export const AEIconButton = withStyles(() =>
  createStyles({
    root: {},
  }),
)((props: IAEIconButton) => (
  <IconButton {...props}>
    <img
      data-img
      src={process.env.PUBLIC_URL + `/icons/${props.icon}.svg`}
      alt={props.icon}
    />
  </IconButton>
));
