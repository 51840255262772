import React, { useEffect, useState } from 'react';

import './styles.scss';

import { AutorConvidado, PerfilDeAutor, useGetAutorConvidadoByOeIdLazyQuery } from '../../../../generated/graphql';

import { IconePerfil } from '../../../../assets/icons';

interface AutoresComponentProps {
	autoresDaOe: PerfilDeAutor[];
	oeId: string;
}

export const AutoresComponent: React.FC<AutoresComponentProps> = (props) => {
	const [autoresConvidados, setAutoresConvidados] = useState<AutorConvidado[]>([]);

	const [getAutoresConvidado] = useGetAutorConvidadoByOeIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (response.findAutoresConvidadosByObjetoEducacional) {
				const autoresConvidados = response.findAutoresConvidadosByObjetoEducacional;
				const autoresJahExistentesNaOe = props.autoresDaOe.map((autor) => autor.email);

				const autoresFiltrados = autoresConvidados.filter((autorConvidado) =>
					autoresJahExistentesNaOe.some((email) => email !== autorConvidado.email),
				);

				setAutoresConvidados(autoresFiltrados as AutorConvidado[]);
			}
		},
	});

	useEffect(() => {
		if (!!props.oeId) {
			getAutoresConvidado({
				variables: {
					idOe: props.oeId,
				},
			});
		}
		//eslint-disable-next-line
	}, [props.oeId]);

	return (
		<>
			{props.autoresDaOe && !!props.autoresDaOe.length && (
				<div data-row data-autores>
					<div>
						<strong>Autor</strong>
						{props.autoresDaOe.map((autor) => (
							<div key={autor.id} data-box-autor>
								<img src={autor.foto && autor.foto.url ? autor.foto.url : IconePerfil} alt="Foto do Autor" />
								<div>
									<p>{autor.nome_exibicao}</p>
									<p>{autor.cargo}</p>
									<p>Editor</p>
								</div>
							</div>
						))}
						{autoresConvidados.map((autor) => (
							<div key={autor.id} data-box-autor>
								<img src={IconePerfil} alt="Foto do Autor" />
								<div>
									<p>{autor.nome}</p>
									<p>{autor.status === 'convite_enviado' ? 'Convite Enviado' : 'Co-autor'}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};
