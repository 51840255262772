import React, { useState, useEffect } from "react";
import { Button, MenuItem, Select } from "@material-ui/core";

import { IHateoas } from "../../../services/utils/response-data";
import {
  PaginaAnterior,
  PaginaAnteriorActive,
  PrimeiraPagina,
  PrimeiraPaginaActive,
  ProximaPagina,
  ProximaPaginaActive,
  UltimaPagina,
  UltimaPaginaActive,
} from "../../../assets/icons";

import "./styles.scss";

interface PaginationProps {
  limit?: number;
  handleChange: (ev: number) => void;
  handlePaginationClick: (hateoas: IHateoas) => void;
  links?: IHateoas[];
  total?: number;
  size: number;
}

export const Pagination: React.FC<PaginationProps> = (props) => {
  const [foward, setFoward] = useState(false);
  const [first, setFirst] = useState(false);
  const [next, setNext] = useState(false);
  const [last, setLast] = useState(false);

  useEffect(() => {
    setFirst(!!props.links?.find((item) => item.rel === "primeira"));
    setFoward(!!props.links?.find((item) => item.rel === "anterior"));
    setNext(!!props.links?.find((item) => item.rel === "proxima"));
    setLast(!!props.links?.find((item) => item.rel === "ultima"));
  }, [props.links]);

  const handle = (name: string) => () => {
    const hateoas = props.links?.find((item) => item.rel === name);
    hateoas && props.handlePaginationClick(hateoas);
  };

  return (
    <div data-pagination-header>
      <div data-title>Linhas por página</div>

      <Select
        data-pagination-select
        disableUnderline
        value={props.limit || -1}
        onChange={(e) => +props.handleChange(e.target.value as any) || 0}
      >
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={-1}>Todos</MenuItem>
      </Select>

      <strong data-total-records>{`${props.size || 0} de ${
        props?.total || 0
      }`}</strong>

      <Button disabled={!first} onClick={handle("primeira")}>
        {!first ? (
          <PrimeiraPagina data-svg-14px />
        ) : (
          <PrimeiraPaginaActive data-svg-gray-700 data-svg-14px />
        )}
      </Button>
      <Button disabled={!foward} onClick={handle("anterior")}>
        {!foward ? (
          <PaginaAnterior data-svg-14px />
        ) : (
          <PaginaAnteriorActive data-svg-gray-700 data-svg-14px />
        )}
      </Button>
      <Button disabled={!next} onClick={handle("proxima")}>
        {!next ? (
          <ProximaPagina data-svg-14px />
        ) : (
          <ProximaPaginaActive data-svg-gray-700 data-svg-14px />
        )}
      </Button>
      <Button disabled={!last} onClick={handle("ultima")}>
        {!last ? (
          <UltimaPagina data-svg-14px />
        ) : (
          <UltimaPaginaActive data-svg-gray-700 data-svg-14px />
        )}
      </Button>
    </div>
  );
};
