import { useRef, useState } from 'react';
import { AETextField } from '../../../shared/components';

import './styles.scss';

interface Props {
  onChangeFile(file: File): void;
  notLabel?: boolean;
  label?: string;
  placeholder?: string;
  accept?: string;
  disabled?: true | false;
}

export const AEFileInput: React.FC<Props> = ({
  onChangeFile,
  label,
  placeholder,
  accept,
  disabled,
  notLabel
}) => {
  const inputFile = useRef<any>(null);
  const [state, setState] = useState(
    placeholder || 'Selecione uma planilha...',
  );

  async function handleChange(e: any) {
    const inputFile = e.target.files[0];
    if (inputFile) {
      setState(inputFile.name);
      onChangeFile(inputFile);
    }
  }

  return (
    <>
      {notLabel ? <span data-file-label/> : <p data-file-label>{label || 'Selecionar arquivo'}</p>}
      <div data-file-box>
        <AETextField
          value={state}
          disabled
          onClick={() => inputFile.current?.click()}
        />
        <label htmlFor="arquivo" className={disabled ? 'disabled' : ''}>
          Carregar
        </label>
        <input
          ref={inputFile}
          type="file"
          id="arquivo"
          disabled={disabled}
          accept={
            accept ||
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          }
          onChange={handleChange}
        />
      </div>
    </>
  );
};
