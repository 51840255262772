import React, { useState, useEffect, useRef } from 'react';

import { useUsuarioService } from '../../../../shared/services/Usuario';
import { useAuth } from '../../../../contexts/Auth';

import { Logout, IconePerfilBlack, Perfil } from '../../../../assets/icons';

import './styles.scss';
import { useHistory } from 'react-router';

interface Props {
	user: 'avaliador' | 'administrador';
}

export const MenuUserLogged: React.FC<Props> = () => {
	const [submenuActive, setSubmenuActive] = useState(false);
	const [name, setName] = useState<string>('');
	const { usuarioLogado } = useAuth();
	const history = useHistory();

	const service = useUsuarioService();

	const menuRef = useRef<any>();

	const handleLogout = () => {
		localStorage.clear();
		window.location.href = '/';
		service.logout();
	};

	const handleOutsideClick = (e: any) => {
		if (menuRef.current && !menuRef.current.contains(e.target)) {
			setSubmenuActive(false);
		}
	};

	useEffect(() => {
		if (!usuarioLogado?.nomeUsuario) {
			return;
		}

		setName(`Olá ${usuarioLogado?.nomeUsuario.split(' ')[0]}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, []);

	return (
		<div data-user-logged>
			<div
				onClick={() => {
					setSubmenuActive(!submenuActive);
				}}
				data-user-info
			>
				<div>
					<span>{name}</span>
					<span>{usuarioLogado?.role}</span>
				</div>
				{!!usuarioLogado?.avatar ? (
					<img src={usuarioLogado.avatar} alt="User Avatar" />
				) : (
					<img src={Perfil} alt="User Avatar" />
				)}
			</div>

			<div data-header-submenu ref={menuRef} style={{ display: submenuActive ? 'flex' : 'none' }}>
				<ul>
					<li
						onClick={() => {
							setSubmenuActive(false);
							history.push('/anexo-de-foto');
						}}
					>
						<img src={IconePerfilBlack} alt="Logout" />
						<p>Minha Foto</p>
					</li>
					<li onClick={handleLogout}>
						<img src={Logout} alt="Logout" />
						<p>Sair</p>
					</li>
				</ul>
			</div>
		</div>
	);
};
