import React from "react";
import { Link } from "react-router-dom";

import { ProximaPaginaActive } from "../../../assets/icons";

import "./styles.scss";

interface BreadcrumbsProps {
  icon: any;
  menus: { name: string; link?: string | undefined, active?: boolean }[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  return (
    <nav data-breadcrumb>
      <div>
        <img alt="" src={props.icon} width="21px" height="18px" />
      </div>
      <ol>
        {props.menus.map((item, idx) => (
          <li data-breadcrumb-item key={idx}>
            <>
              {item.link && <a href={item.link}>{item.name}</a>}
              {!item.active && !item.link && (<span>{item.name}</span>)}
              {item.active && <strong>{item.name}</strong>}
              {idx < props.menus.length-1 && (<ProximaPaginaActive width={"14px"} height={"14px"} />)}
            </>
          </li>
        ))}
      </ol>
    </nav>
  );
};
