/* eslint-disable no-mixed-operators */
import { FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { ExcluiItem } from '../../../../assets/icons';

import { IUsuarioLogado, useAuth } from '../../../../contexts/Auth';
import {
	Enum_Driveinsumoobjetoeducacional_Tipo,
	ObjetoEducacionalFragment,
	useCreateDriveInsumoObjetoEducacionalMutation,
	useDeleteDriveInsumoMutation,
	useGetDriveInsumosByObjetoEducacionalIdLazyQuery,
	useUpdateObjetoEducacionalDriveInsumoMutation,
} from '../../../../generated/graphql';
import useDriveInsumo from '../../../../hooks/useDriveInsumo';
import { useToast } from '../../../../hooks/useToast';
import { TOAST_SERVERITY } from '../../../../redux/toast/toast.reducer';
import { converterStringBooleanParaBoolean } from '../../../../services/utils/converterStringBooleanParaBoolean';
import validator from '../../../../validators/OeValidacao/oeAnexoDocumentos';
import { AETextField, AEButton, AERadioGroup, AERadio } from '../../../../shared/components';

import './styles.scss';

interface AnexoProps {
	oe: ObjetoEducacionalFragment;
	user: IUsuarioLogado;
}

interface FormikValues {
	usoImagemTerceiro: boolean;
	link: string;
	toggleLink: boolean;
	comentario_observacao: string;
}

const defaultAnexoDocumentos: FormikValues = {
	usoImagemTerceiro: false,
	link: '',
	toggleLink: false,
	comentario_observacao: '',
};

interface ArquivoAnexo {
	url: string;
	id: string;
	nome: string;
	autor: string;
	extensao: string;
	objeto_educacional: string;
	tipo: 'documento' | 'link';
}

export const Anexo: React.FC<AnexoProps> = (props) => {
	const { usuarioLogado } = useAuth();
	const history = useHistory();
	const [arquivos, setArquivos] = useState<ArquivoAnexo[]>([]);
	const [arquivosAutorizacao, setArquivosAutorizacao] = useState<ArquivoAnexo[]>([]);
	const [links, setLinks] = useState<ArquivoAnexo[]>([]);
	const [labelEnviar, setLabelEnviar] = useState<'Avançar' | 'Salvando Dados...'>('Avançar');

	const { handleUploadInsumo, documentoInputRef, documentoAutorizacaoInputRef } = useDriveInsumo({
		autorId: usuarioLogado?.autor.id!,
		objetoEducacionalId: props.oe.id,
	});
	const showToast = useToast();

	const [deleteDriveInsumo] = useDeleteDriveInsumoMutation({
		fetchPolicy: 'network-only',
		onCompleted: () => {},
	});

	const handleDeleteDriveInsumo = async (
		index: number,
		tipo: 'documento' | 'link',
		id: string,
		isAutorizacao?: boolean,
	) => {
		if (tipo === 'documento') {
			try {
				await deleteDriveInsumo({ variables: { id: id || '' } });

				if (isAutorizacao) {
					const anexosArray = arquivosAutorizacao.filter((arquivo, idx) => arquivo.id !== id);
					setArquivosAutorizacao(anexosArray);
				} else {
					const anexosArray = arquivos.filter((arquivo, idx) => arquivo.id !== id);
					setArquivos(anexosArray);
				}
			} catch (error) {
				console.error(error);
			}
		}

		if (tipo === 'link') {
			if (!!id) {
				try {
					await deleteDriveInsumo({ variables: { id: id } });

					const linksArray = links.filter((link, idx) => link.id !== id);
					setLinks(linksArray);
				} catch (error) {
					console.error(error);
				}
			}
		}

		const linksArray = links.filter((link, idx) => index !== idx);
		setLinks(linksArray);
	};

	const [updateComentarioDriveInsumo] = useUpdateObjetoEducacionalDriveInsumoMutation({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			setLabelEnviar('Avançar');
			showToast({
				severity: TOAST_SERVERITY.SUCCESS,
				description: 'Requisição efetuada com sucesso',
			});
			history.push(`/conteudo/${props.oe.id}/concluir`);
		},
		onError: () => {
			setLabelEnviar('Avançar');
			showToast({
				severity: TOAST_SERVERITY.ERROR,
				description: 'Erro no envio da requisição, por favor tente novamente',
			});
		},
	});

	const [createLinkDriveInsumo] = useCreateDriveInsumoObjetoEducacionalMutation({
		fetchPolicy: 'network-only',
	});

	const formik = useFormik<FormikValues>({
		initialValues: defaultAnexoDocumentos,
		validationSchema: validator.criarSchemaValidacao(),
		onSubmit: (values) => {
			setLabelEnviar('Salvando Dados...');
			updateComentarioDriveInsumo({
				variables: {
					comentario: values.comentario_observacao,
					id: props.oe.id,
					uso_imagem_terceiro: values.usoImagemTerceiro,
				},
			});
		},
	});

	const [getAnexoDocumentos] = useGetDriveInsumosByObjetoEducacionalIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!!response.findDriveInsumosByObjetoEducacional) {
				const anexos = response.findDriveInsumosByObjetoEducacional;

				console.log('anexos', anexos);

				setArquivos(
					anexos
						.map(
							(item) =>
								({
									autor: item.autor?.id || '',
									id: item.id || '',
									nome: item.nome || '',
									tipo: item.tipo || 'documento',
									objeto_educacional: item.objeto_educacional?.id || '',
									url: item.url || '',
									extensao: item.extensao || '',
								} as ArquivoAnexo),
						)
						.filter((item) => item.tipo === 'documento') as unknown as ArquivoAnexo[],
				);

				console.log(
					anexos
						.filter((link) => link.tipo === 'link')
						.map((item) => ({
							autor: item.autor?.id || '',
							id: item.id || '',
							nome: item.nome || '',
							tipo: item.tipo || 'link',
							objeto_educacional: item.objeto_educacional?.id || '',
							url: item.url || '',
							extensao: item.extensao || '',
						})),
				);

				setLinks(
					anexos
						.filter((link) => link.tipo === 'link')
						.map((item) => ({
							autor: item.autor?.id || '',
							id: item.id || '',
							nome: item.nome || '',
							tipo: item.tipo || 'link',
							objeto_educacional: item.objeto_educacional?.id || '',
							url: item.url || '',
							extensao: item.extensao || '',
						})),
				);
			}
		},
	});

	const handleAddAnexo = (anexo: ArquivoAnexo, isAutorizacaoImagem: boolean) => {
		if (!isAutorizacaoImagem) {
			setArquivos([...arquivos, { ...anexo }]);
			return;
		}

		setArquivosAutorizacao([...arquivosAutorizacao, { ...anexo }]);
	};

	const handleAddLink = async () => {
		formik.setTouched({
			...formik.touched,
			link: true,
		});

		if (!formik.values.link || !!formik.errors.link) {
			return;
		}

		const result = await createLinkDriveInsumo({
			variables: {
				input: {
					data: {
						nome: '',
						url: formik.values.link,
						autor: usuarioLogado?.idUsuario!,
						extensao: 'link',
						objeto_educacional: props.oe.id,
						tipo: Enum_Driveinsumoobjetoeducacional_Tipo.Link,
					},
				},
			},
		});

		const newLink = result.data?.createDriveInsumoObjetoEducacional?.driveInsumoObjetoEducacional;
		const newLinkFormatted = {
			id: newLink?.id,
			url: newLink?.url,
			nome: newLink?.nome,
			autor: '',
			extensao: 'link',
			objeto_educacional: '',
			tipo: 'link',
		};
		if (newLink) {
			setLinks([...links, newLinkFormatted as ArquivoAnexo]);
			formik.setValues({ ...formik.values, link: '' });
			formik.setTouched({ ...formik.touched, link: false });
		}
		formik.setValues({ ...formik.values, link: '' });
		formik.setTouched({ ...formik.touched, link: false });
	};

	useEffect(() => {
		if (!!props.oe && !!props.oe.comentario_drive_insumo) {
			formik.setValues({
				...formik.values,
				usoImagemTerceiro: props.oe.uso_imagem_terceiro || false,
				comentario_observacao: props.oe.comentario_drive_insumo || '',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.oe]);

	useEffect(() => {
		if (!!props.oe.id) {
			getAnexoDocumentos({
				variables: {
					id: props.oe.id,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.oe]);

	useEffect(() => {
		console.log('links', links);
	}, [links]);

	return (
		<form data-form-anexo-documentos data-width="100">
			<div data-form-col>
				<label>
					<p>Arquivo*</p>
				</label>
				<div data-upload-buttons>
					<AETextField
						inputRef={documentoInputRef}
						style={{ display: 'none' }}
						id="arquivo"
						name="arquivo"
						type="file"
						data-form-row-arquivo
						label="Arquivo*"
						onChange={async () => {
							const result = await handleUploadInsumo(false, documentoInputRef);
							if (!!result && !!result.data) {
								handleAddAnexo(result.data, false);
							}
						}}
					/>
					<label data-form-button-anexar htmlFor="arquivo">
						<div>
							<p>Anexar arquivo</p>
						</div>
					</label>
					<AEButton
						color="primary"
						variant="outlined"
						onClick={() =>
							formik.setValues({
								...formik.values,
								toggleLink: !formik.values.toggleLink,
							})
						}
					>
						{formik.values.toggleLink ? 'Ocultar link' : 'Adicionar link'}
					</AEButton>
				</div>
				<span>
					<p>
						Anexe aqui o seu material referente ao Objeto Educacional. Você pode adicionar mais de um arquivo. Para
						arquivos muitos pesados, recomendamos o envio por meio do link do drive de sua preferência.
					</p>
				</span>
			</div>

			{!!arquivos && !!arquivos.length && (
				<div data-lista-anexo>
					{arquivos.map((anexo, index) => (
						<div data-row-anexo key={anexo.id}>
							<p>{anexo.nome}</p>
							<img
								src={ExcluiItem}
								alt="Excluir Item"
								onClick={() => {
									handleDeleteDriveInsumo(index, 'documento', anexo.id);
								}}
							/>
						</div>
					))}
				</div>
			)}

			{formik.values.toggleLink && (
				<div data-form-row>
					<div data-form-links>
						<AETextField
							id="link"
							color="primary"
							name="link"
							label="Link"
							value={formik.values.link}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.touched.link ? formik.errors.link : null)}
							helperText={formik.touched.link && formik.errors.link ? formik.errors.link : null}
						/>
						<AEButton color="primary" variant="contained" onClick={handleAddLink}>
							Adicionar
						</AEButton>
					</div>
				</div>
			)}

			<div data-lista-anexo>
				{!!links &&
					!!links.length &&
					links.map((link, index) => (
						<div data-row-anexo key={index}>
							<p>{link.url}</p>
							<img
								src={ExcluiItem}
								alt="Excluir Item"
								onClick={() => handleDeleteDriveInsumo(index, 'link', link.id)}
							/>
						</div>
					))}
			</div>

			<div data-form-col>
				<label>
					<p>O material apresenta uso de imagem de terceiros e/ou pacientes?*</p>
				</label>
				<div>
					<AERadioGroup
						data-radio-group
						name="usoImagemTerceiro"
						id="usoImagemTerceiro"
						value={formik.values.usoImagemTerceiro.toString()}
						onChange={(e) => {
							formik.setValues((values) => {
								values.usoImagemTerceiro = converterStringBooleanParaBoolean(e.target.value);
								return values;
							});
						}}
					>
						<FormControlLabel color="#000" value={'true'} control={<AERadio />} label="SIM" />
						<FormControlLabel value={'false'} control={<AERadio />} label="NÃO" />
					</AERadioGroup>
				</div>
			</div>

			{!!formik.values.usoImagemTerceiro && formik.values.usoImagemTerceiro === true && (
				<div data-form-col>
					<label>
						<p>Anexe o documento que comprove autorização de uso de imagem de terceiros ou pacientes*</p>
					</label>
					<div data-upload-buttons>
						<AETextField
							inputRef={documentoAutorizacaoInputRef}
							style={{ display: 'none' }}
							id="arquivoAutorizacao"
							name="arquivoAutorizacao"
							type="file"
							data-form-row-arquivo
							onChange={async () => {
								const result = await handleUploadInsumo(true, documentoAutorizacaoInputRef);
								if (!!result && !!result.data) {
									handleAddAnexo(result.data, true);
								}
							}}
						/>
						<label data-form-button-anexar htmlFor="arquivoAutorizacao">
							<div>
								<p>Selecionar documento</p>
							</div>
						</label>
					</div>

					{!!arquivosAutorizacao && !!arquivosAutorizacao.length && (
						<div data-lista-anexo>
							{arquivosAutorizacao.map((anexo, index) => (
								<div data-row-anexo key={anexo.id}>
									<p>{anexo.nome}</p>
									<img
										src={ExcluiItem}
										alt="Excluir Item"
										onClick={() => handleDeleteDriveInsumo(index, 'documento', anexo.id, true)}
									/>
								</div>
							))}
						</div>
					)}
				</div>
			)}

			<div data-form-row>
				<AETextField
					id="comentario_observacao"
					name="comentario_observacao"
					label="Comentários:"
					multiline
					placeholder="Se desejar, informe aqui comentários e observações referente ao seu material para a equipe de produção da Academia Digital Einstein"
					rows={4}
					value={formik.values.comentario_observacao}
					onChange={formik.handleChange}
				/>
			</div>

			<div data-buttons>
				<AEButton
					color="primary"
					variant="outlined"
					disabled={labelEnviar === 'Salvando Dados...'}
					onClick={() => history.push(`/conteudo/${props.oe.id}`)}
				>
					Voltar
				</AEButton>
				<AEButton
					color="primary"
					variant="contained"
					disabled={labelEnviar === 'Salvando Dados...'}
					onClick={() => formik.submitForm()}
				>
					{labelEnviar}
				</AEButton>
			</div>
		</form>
	);
};
