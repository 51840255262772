import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { FormControlLabel, MenuItem } from '@material-ui/core';
import Select from 'react-select';

import { IUsuarioLogado, useAuth } from '../../../../contexts/Auth';
import { GerenciarAutores } from '../GerenciarAutores';
import { ListaPalavrasChave } from '../ListaPalavrasChave';
import { InfoField } from './components/InfoField';
import {
	AESelect,
	AEButton,
	AEDatePicker,
	AETextFormikField,
	AERadioGroup,
	AERadio,
} from '../../../../shared/components';
import { oeTextoValidacao } from '../../../../validators/OeValidacao/oeTexto';
import { convertToArrayStrings } from '../../../../shared/commom/parser';
import {
	ObjetoEducacionalInput,
	AutorConvidado,
	useGetRotulosLazyQuery,
	useGetTipoObjetosLazyQuery,
	useCreateObjetoEducacionalMutation,
	useUpdateObjetoEducacionalMutation,
	AreaDeConhecimento,
	ObjetoEducacionalEdicaoFragment,
	Enum_Statusdepublicacao_Enum_Status,
	useGetPublicoAlvoLazyQuery,
	useGetPalavrasChaveByOeLazyQuery,
	useGetAutorConvidadoByOeIdLazyQuery,
} from '../../../../generated/graphql';

import { ISelect } from '../../../../shared/interfaces';

import { useAPI } from '../../../../hooks/useAPI';
import { TOAST_ACTION, TOAST_SERVERITY } from '../../../../redux/toast/toast.reducer';

import useCreateHistoricoStatusOe from '../../../../hooks/useCreateHistoricoStatusOe';

import './styles.scss';
import { formatarDataRequesicao } from '../../../../shared/commom/formatarData';
import { convertArrayToSelects } from '../../../../shared/commom/select';
import { CategoriasComponent, ISelectCategory } from './components/Categorias';
import { converterStringBooleanParaBoolean } from '../../../../services/utils/converterStringBooleanParaBoolean';

type TipoObjetoType = { TipoObjeto: string };
type ObjetoEducacionalWithoutTipoObjetoType = Omit<ObjetoEducacionalInput, 'TipoObjeto'>;
type ObjetoEducacionalInputType = TipoObjetoType & ObjetoEducacionalWithoutTipoObjetoType;

const defaultOe = {
	Titulo: '',
	Descricao: '',
	Resumo: '',
	AreasConhecimento: undefined,
	Rotulos: [],
	PublicoAlvo: [],
	RestricaoPublicoMedico: false,
	TipoObjeto: '',
	DataProducao: new Date(),
	ValidadeConteudo: new Date(),
	palavras_chave: [],
	DireitosUsoImagemTerceirosPacientes: undefined,
	TempoMedioDuracao: '0',
} as ObjetoEducacionalInputType;

interface DadosObjetoProps {
	oe?: ObjetoEducacionalEdicaoFragment;
	user: IUsuarioLogado;
	oeStatusRascunho: boolean;
}

export const DadosObjeto: React.FC<DadosObjetoProps> = (props) => {
	const { usuarioLogado } = useAuth();
	const dispatch = useDispatch();
	const history = useHistory();

	const [rotulos, setRotulos] = useState<ISelectCategory[]>([]);
	const [rotulosSelecionados, setRotulosSelecionados] = useState<ISelect[]>([]);
	const [publicoAlvo, setPublicoAlvo] = useState<ISelect[]>([]);
	const [publicoAlvoSelecionados, setPublicoAlvoSelecionados] = useState<ISelect[]>([]);
	const [tiposObjetos, setTiposObjetos] = useState<ISelect[]>([]);
	const [autoresConvidados, setAutoresConvidados] = useState<[]>([]);
	const [areasConhecimentos, setAreasConhecimentos] = useState<ISelect[]>([]);
	const [areasConhecimentoSelecionados, setAreasConhecimentoSelecionados] = useState<ISelect[]>([]);
	const [palavrasChave, setPalavrasChave] = useState<ISelect[]>([]);

	const { request } = useAPI<AreaDeConhecimento[]>();

	const [autores, setAutores] = useState<AutorConvidado[]>([]);

	const [processing, setProcessing] = useState(false);
	const [labelEnviar, setLabelEnviar] = useState<'Avançar' | 'Salvando Dados...'>('Avançar');

	const formik = useFormik<ObjetoEducacionalInputType>({
		initialValues: defaultOe,
		validationSchema: oeTextoValidacao,
		onSubmit: async (values) => {
			setProcessing(true);
			setLabelEnviar('Salvando Dados...');

			const functionPersist = !props.oe?.id ? criarObjetoEducacional : atualizarObjetoEducacional;

			const dataVariable: any = {
				variables: {
					input: {
						data: {
							...values,
							RestricaoPublicoMedico: converterStringBooleanParaBoolean(values.RestricaoPublicoMedico || false),
							TipoObjeto: [values.TipoObjeto],
							ValidadeConteudo: formatarDataRequesicao(values.ValidadeConteudo),
							DataProducao: formatarDataRequesicao(values.DataProducao),
							DataAtualizacaoProducao: formatarDataRequesicao(values.DataProducao),
							Autores: [usuarioLogado?.autor.id],
							created_by: usuarioLogado?.idUsuario,
							published_at: null,
						},
					},
				},
			};

			if (!!props.oe?.id) {
				dataVariable.variables.input.where = {
					id: props.oe?.id,
				};
			}

			await functionPersist(dataVariable);
		},
	});

	const [criarObjetoEducacional] = useCreateObjetoEducacionalMutation({
		fetchPolicy: 'network-only',
		onCompleted: async (response) => {
			try {
				setLabelEnviar('Avançar');
				if (!response.createObjetoEducacional || !response.createObjetoEducacional.objetoEducacional) {
					return;
				}

				const { objetoEducacional: objetoEducacionalCreated } = response.createObjetoEducacional;

				await convidarAutores(objetoEducacionalCreated.id, objetoEducacionalCreated.Titulo || '');

				await getStatusAndCreateHistorico({
					oeId: objetoEducacionalCreated.id,
					userId: usuarioLogado?.idUsuario!,
					statusEnum: Enum_Statusdepublicacao_Enum_Status.EmRascunho,
				});
				history.push(`/conteudo/${objetoEducacionalCreated.id}/anexo`);
			} catch (error: any) {
				dispatch({
					type: TOAST_ACTION.SHOW,
					payload: {
						severity: TOAST_SERVERITY.ERROR,
						description: error.message || error,
					},
				});
			}
		},
		onError: (error) => {
			setProcessing(false);
			setLabelEnviar('Avançar');
			dispatch({
				type: TOAST_ACTION.SHOW,
				payload: {
					severity: TOAST_SERVERITY.ERROR,
					description: 'Erro na criação do Objeto Educacional, por favor tente novamente',
				},
			});
		},
	});

	const [atualizarObjetoEducacional] = useUpdateObjetoEducacionalMutation({
		fetchPolicy: 'network-only',
		onCompleted: async (response) => {
			if (!response.updateObjetoEducacional || !response.updateObjetoEducacional.objetoEducacional) {
				return;
			}

			const { objetoEducacional: objetoEducacionalUpdated } = response.updateObjetoEducacional;

			setLabelEnviar('Avançar');

			await convidarAutores(
				response.updateObjetoEducacional?.objetoEducacional?.id!,
				response.updateObjetoEducacional?.objetoEducacional?.Titulo || '',
			);
			history.push(`/conteudo/${objetoEducacionalUpdated.id}/anexo`);
		},
		onError: () => {
			setLabelEnviar('Avançar');
			setProcessing(false);
			dispatch({
				type: TOAST_ACTION.SHOW,
				payload: {
					severity: TOAST_SERVERITY.ERROR,
					description: 'Erro ao atualizar Objeto Educacional, por favor tente novamente',
				},
			});
		},
	});

	const [getTipoObjeto] = useGetTipoObjetosLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!response.tipoOes) {
				return;
			}

			setTiposObjetos(
				response.tipoOes
					?.sort((a, b) => {
						if (a.titulo && b.titulo && a.titulo.normalize('NFD') < b.titulo.normalize('NFD')) return -1;
						if (a.titulo && b.titulo && a.titulo.normalize('NFD') > b.titulo.normalize('NFD')) return 1;
						return 0;
					})
					.map(
						(item) =>
							({
								value: item.id,
								label: item.titulo,
							} as ISelect),
					),
			);
		},
	});

	const [getRotulos] = useGetRotulosLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!response.rotulos) {
				return;
			}

			setRotulos(
				response.rotulos
					?.sort((a, b) => {
						if (a.titulo && b.titulo && a.titulo.normalize('NFD') < b.titulo.normalize('NFD')) return -1;
						if (a.titulo && b.titulo && a.titulo.normalize('NFD') > b.titulo.normalize('NFD')) return 1;
						return 0;
					})
					.map((item) => {
						return {
							value: item.id,
							label: item.titulo,
							descricao: item.descricao,
						} as ISelectCategory;
					}),
			);
		},
	});

	const [getPublicoAlvo] = useGetPublicoAlvoLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!response.publicoAlvos) {
				return;
			}

			setPublicoAlvo(
				response.publicoAlvos
					?.sort((a, b) => {
						if (a.titulo && b.titulo && a.titulo.normalize('NFD') < b.titulo.normalize('NFD')) return -1;
						if (a.titulo && b.titulo && a.titulo.normalize('NFD') > b.titulo.normalize('NFD')) return 1;
						return 0;
					})
					.map((item) => {
						return {
							value: item.id,
							label: item.titulo,
						} as ISelect;
					}),
			);
		},
	});

	const [getAutoresConvidados] = useGetAutorConvidadoByOeIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (response) => {
			if (!response.findAutoresConvidadosByObjetoEducacional || !response.findAutoresConvidadosByObjetoEducacional) {
				return;
			}
			
			setAutores(
				response.findAutoresConvidadosByObjetoEducacional.map(
					(item) =>
						({
							nome: item.nome || '',
							id: item.id || '',
							email: item.email || '',
							aceita_participacao: item.aceita_participacao,
						} as AutorConvidado),
				),
			);
		},
	});

	const [getPalavrasChaveByOe] = useGetPalavrasChaveByOeLazyQuery({
		nextFetchPolicy: 'network-only',
		onCompleted: (response) => {
			formik.setValues({
				...formik.values,
				palavras_chave: response?.findPalavraChavesByOe?.map((item) => item.id) || [],
			});

			setPalavrasChave(
				response?.findPalavraChavesByOe?.map((item) => {
					const iSelect: ISelect = {
						label: item?.titulo || '',
						value: item?.id || '',
					};
					return iSelect;
				}) || [],
			);
		},
	});

	const convidarAutores = async (idObjeto: string, nomeObjeto: string) => {
		try {
			const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace('/graphql', '')}/perfil-de-autors/convite`;

			const response = await fetch(URL, {
				method: 'POST',
				headers: new Headers({
					'content-type': 'application/json',
					Authorization: `Bearer ${usuarioLogado?.jwt}`,
				}),
				body: JSON.stringify({
					autorResponsavel: {
						id: usuarioLogado?.idUsuario,
						nome: usuarioLogado?.nomeUsuario,
					},
					autoresConvidados: autoresConvidados,
					objetoEducacional: {
						id: idObjeto,
						nome: nomeObjeto,
					},
				}),
			});

			return response;
		} catch (error) {
			setProcessing(false);
			dispatch({
				type: TOAST_ACTION.SHOW,
				payload: {
					severity: TOAST_SERVERITY.ERROR,
					description: 'Erro no envio da requisição, por favor tente novamente',
				},
			});
		}
	};

	const { getStatusAndCreateHistorico } = useCreateHistoricoStatusOe();

	useEffect(() => {
		request(`${process.env.REACT_APP_ROOT}/area-de-conhecimentos/v2`).then((data) => {
			setAreasConhecimentos((data || []).map((item: any) => ({ label: item.titulo, value: item.id } as ISelect)));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!!props.oe) {
			formik.setValues({
				...formik.values,
				Titulo: props.oe.Titulo,
				Descricao: props.oe.Descricao,
				Resumo: props.oe.Resumo,
				RestricaoPublicoMedico: props.oe.RestricaoPublicoMedico || false,
				TipoObjeto: !!props.oe.TipoObjeto ? props.oe.TipoObjeto[0].id : '',
				DataProducao: props.oe.DataProducao,
				ValidadeConteudo: props.oe.ValidadeConteudo,
				DireitosUsoImagemTerceirosPacientes: undefined,
				TempoMedioDuracao: '0',
				AreasConhecimento: convertToArrayStrings(props.oe.AreasConhecimento || [], 'id'),
				Rotulos: convertToArrayStrings(props.oe.Rotulos || [], 'id'),
				PublicoAlvo: convertToArrayStrings(props.oe.PublicoAlvo || [], 'id'),
			});

			setAreasConhecimentoSelecionados(
				convertArrayToSelects(props.oe.AreasConhecimento, [
					['label', 'titulo'],
					['value', 'id'],
				]),
			);

			setPalavrasChave(
				convertArrayToSelects(props.oe.palavras_chave, [
					['label', 'titulo'],
					['value', 'id'],
				]),
			);

			setRotulosSelecionados(
				convertArrayToSelects(props.oe.Rotulos, [
					['label', 'titulo'],
					['value', 'id'],
				]),
			);

			setPublicoAlvoSelecionados(
				convertArrayToSelects(props.oe.PublicoAlvo, [
					['label', 'titulo'],
					['value', 'id'],
				]),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.oe]);

	useEffect(() => {
		getRotulos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getPublicoAlvo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getTipoObjeto();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!!props.oe?.id) {
			getAutoresConvidados({
				variables: {
					idOe: props.oe.id,
				},
			});

			getPalavrasChaveByOe({
				variables: {
					oe: props.oe.id,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.oe?.id]);

	return (
		<form data-form data-width="100">
			<div data-col data-width="100">
				<AETextFormikField id="Titulo" label="Título*" formik={formik} />
				<InfoField
					desktop="Não utilize palavras muito extensas (mais de 13 caracteres). Detalhe
          o conteúdo na descrição."
					mobile={'Não utilize palavras muito extensas (13 caracteres).'}
					maxLength={50}
					length={formik.values.Titulo.length}
				></InfoField>
			</div>

			<div data-col data-width="100">
				<AETextFormikField
					formik={formik}
					id="Descricao"
					placeholder="Descrição com máximo de 500 caracteres"
					label="Descrição Completa*"
					multiline
					rows={5}
				/>

				<InfoField
					desktop="Texto livre sobre o objeto educacional. Será exibido na página do
          conteúdo."
					mobile={'Será exibido na página do conteúdo.'}
					maxLength={500}
					length={formik.values.Descricao.length}
				></InfoField>
			</div>

			<div data-col data-width="100">
				<AETextFormikField
					formik={formik}
					id="Resumo"
					placeholder="Descrição com máximo de 112 caracteres"
					label="Descrição Resumida*"
					multiline
					rows={2}
				/>

				<InfoField
					desktop="Texto resumido sobre o o objeto educacional. Será exibido se o
          conteúdo estiver no carrossel de Mais acessados."
					mobile={'Será exibido se o conteúdo estiver em "Mais acessados."'}
					maxLength={112}
					length={formik.values.Resumo.length}
				></InfoField>
			</div>

			<div data-col data-width="100">
				<div data-form-control>
					<label>Área de Conhecimento*</label>
					<Select
						isMulti
						placeholder=""
						name="AreasConhecimento"
						options={areasConhecimentos}
						classNamePrefix="select"
						className={formik.touched.AreasConhecimento && formik.errors.AreasConhecimento ? 'select-error' : ''}
						onChange={(va) => {
							setAreasConhecimentoSelecionados(
								va.map((item) => ({
									label: item.label,
									value: item.value,
								})),
							);

							formik.setValues({
								...formik.values,
								AreasConhecimento: va.map((item) => item.value),
							});
						}}
						value={areasConhecimentoSelecionados}
					/>

					<InfoField
						desktop="Informe a(s) área(s) de conhecimento a(s) qual(is) o material está
            relacionado."
						mobile={'Área(s) de conhecimento que o material está relacionado.'}
					></InfoField>
				</div>
			</div>

			<ListaPalavrasChave
				formikError={Boolean(formik.touched.palavras_chave && formik.errors.palavras_chave)}
				handleSelecionadas={(selecionados: string[]) => {
					if (!selecionados || !selecionados.length) {
						return;
					}

					formik.setValues({
						...formik.values,
						palavras_chave: selecionados,
					});
				}}
				palavrasChave={palavrasChave || []}
			/>

			<div data-row>
				<div data-form-control data-width="50">
					<label>Público-Alvo*</label>
					<Select
						isMulti
						placeholder="Lista com públicos-alvo"
						name="Rotulos"
						value={publicoAlvoSelecionados}
						options={publicoAlvo}
						classNamePrefix="select"
						onChange={(va) => {
							setPublicoAlvoSelecionados(
								va.map((item) => ({
									label: item.label,
									value: item.value,
								})),
							);

							formik.setValues({
								...formik.values,
								PublicoAlvo: va.map((item) => item.value),
							});
						}}
						className={formik.touched.PublicoAlvo && formik.errors.PublicoAlvo ? 'select-error' : ''}
					/>
				</div>

				<div data-form-control data-width="50">
					<label>Tipo de Mídia*</label>
					<AESelect
						name="TipoObjeto"
						variant="outlined"
						value={formik.values.TipoObjeto || ''}
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						error={Boolean(formik.touched.TipoObjeto ? formik.errors.TipoObjeto : null)}
						inputProps={{ 'aria-label': 'Without label' }}
					>
						<MenuItem value="0">Selecione</MenuItem>
						{tiposObjetos.map((item, idx) => (
							<MenuItem key={idx} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</AESelect>
				</div>
			</div>

			<div data-form-row>
				<div data-form-control data-form-restricao data-width="100">
					<p>
						<label>Há restrição de acesso do conteúdo para o público não-médico?*</label>
					</p>
					<AERadioGroup
						data-form-restricao-linha
						name="RestricaoPublicoMedico"
						id="RestricaoPublicoMedico"
						value={`${formik.values.RestricaoPublicoMedico}`}
						onChange={(e) => {
							formik.setValues((values) => {
								values.RestricaoPublicoMedico = converterStringBooleanParaBoolean(e.target.value);
								return values;
							});
						}}
					>
						<FormControlLabel
							color="#000"
							value={'true'}
							control={<AERadio />}
							label="SIM. Somente pode ser acessado por médicos"
						/>
						<FormControlLabel value={'false'} control={<AERadio />} label="NÃO. Pode ser acessado por todos" />
					</AERadioGroup>
				</div>
			</div>

			{rotulos && !!rotulos.length && (
				<div data-form-row>
					<div data-form-control data-width="100">
						<CategoriasComponent
							listaCategorias={rotulos}
							rotulosJaSelecionados={rotulosSelecionados}
							handleCategorias={(categorias) => {
								setRotulosSelecionados(
									categorias.map((item) => ({
										label: item.label,
										value: item.value,
									})),
								);

								formik.setValues({
									...formik.values,
									Rotulos: categorias.map((item) => item.value),
								});
							}}
							errors={Boolean(formik.touched.Rotulos ? formik.errors.Rotulos : null)}
						/>
					</div>
				</div>
			)}

			<div data-row>
				<AEDatePicker
					id="DataProducao"
					name="DataProducao"
					label="Data de Criação*"
					data-width="50"
					data-form-control
					value={formik.values.DataProducao}
					onChange={(ev: any) => {
						formik.setValues({
							...formik.values,
							DataProducao: format(ev, "yyyy-MM-dd'T'03:00:00'Z'"),
						});
					}}
					error={Boolean(formik.touched.DataProducao ? formik.errors.DataProducao : null)}
				/>

				<AEDatePicker
					id="ValidadeConteudo"
					name="ValidadeConteudo"
					label="Data de Validade*"
					data-width="50"
					data-form-control
					value={formik.values.ValidadeConteudo}
					onChange={(ev: any) => {
						formik.setValues({
							...formik.values,
							ValidadeConteudo: format(ev, "yyyy-MM-dd'T'03:00:00'Z'"),
						});
					}}
					error={Boolean(formik.touched.ValidadeConteudo ? formik.errors.ValidadeConteudo : null)}
				/>
			</div>

			<GerenciarAutores handleAutores={setAutoresConvidados} autores={autores} />

			<div data-buttons data-width="100">
				<AEButton
					color="primary"
					type="submit"
					variant="contained"
					disabled={processing}
					onClick={(ev) => {
						ev.preventDefault();
						formik.submitForm();
					}}
				>
					{labelEnviar}
				</AEButton>
			</div>
		</form>
	);
};
