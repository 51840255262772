import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

const defaultProps = {
  active: false,
};

interface BreadcrumbItemProps {
  to?: string;
  children?: ReactNode;
  active?: boolean;
  subActive?: boolean;
  subItem?: boolean;
}

const _BreadcrumSubbItem: React.FC<BreadcrumbItemProps> = (props) => {
  return (
    <>
      <li
        data-breadcrumb-item
        className={cx({
          "breadcrumb-active": props.active,
          "breadcrumb-sub-active": props.subActive,
          "breadcrumb-sub-item": props.subItem
        })}
      >
        {(props.to && <Link to={props.to}>{props.children}</Link>) || `${props.children}`}
      </li>
    </>
  );
}

_BreadcrumSubbItem.defaultProps = defaultProps;

export const BreadcrumbItem = _BreadcrumSubbItem;