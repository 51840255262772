import { combineReducers } from "redux";
import toastReducer from "./toast/toast.reducer";
import modalReducer from "../shared/components/Modal/model.reducer";

const rootReducer = combineReducers({
  modalReducer,
  toastReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
