import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

import { IToast } from "../../../redux/toast/toast.reducer";

import "./styles.scss";

export const Toast: React.FC = () => {
  const state = useSelector(
    (state: { toastReducer: IToast }) => state.toastReducer
  );

  const [open, setOpen] = React.useState(!!state && !!state.show);

  const handleCloseToast = () => setOpen(false);

  useEffect(() => {
    !!state && setOpen(state.show);
  }, [state]);

  return (
    <>
      {!!state && !!state.show && (
        <div data-notification-toast>
          <Snackbar
            className="snackbar"
            open={open}
            onClose={handleCloseToast}
            message={state.description}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity={state.severity}>{state.description}</Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};
