export const parserNumber = (value: any) => {
  const invalids = [undefined, null, "null"];

  if (invalids.some((invalid) => invalid === value)) {
    value = 0;
  }

  if (typeof value === "string") {
    value = +value;
  }

  if (isNaN(value)) {
    value = 0;
  }

  return value as number;
};

export const converterListaParaString = <
  DataType,
  KeyType extends keyof DataType
>(
  list: DataType[] | undefined,
  attributeName: KeyType
) => {
  return (list || []).map((item) => item[attributeName]).join(", ");
};

export const convertToArrayStrings = <T, KeyType extends keyof T>(array: T[], key: KeyType) => {
  const result: string[] = array.reduce((acc: string[], item) => {
    acc.push(parserNumber(item[key]).toString());
    return acc;
  }, []);
  return result;
};