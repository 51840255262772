import React from "react";
import { useHistory } from "react-router-dom";

import { AEButton } from "../../../../shared/components";

export const CriacaoConteudoButton: React.FC = () => {
  const history = useHistory();

  const handleRedirectNovoConteudo = () => {
    history.push("/conteudo");
  };

  return (
    <AEButton
      color="primary"
      variant="contained"
      onClick={handleRedirectNovoConteudo}
    >
      + Criar conteúdo
    </AEButton>
  );
};
