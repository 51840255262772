import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { pt } from "date-fns/locale";
import "./styles.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { withStyles } from "@material-ui/styles";

const {
  disable,
  secondaryText,
  error,
} = require("../../../ds/_palette.module.scss");

export const AEDatePicker: React.ComponentType<any> = withStyles(() => ({
  root: {
    "& *": {
      display: "flex",
      letterSpacing: 0,
    },

    "&.MuiFormControl-root": {
      "& input": {
        fontSize: "1.4rem",
        padding: "1.1rem 1.6rem",
        width: "100%",

        "&:valid + fieldset": {
          borderWidth: ".1rem",
        },

        "&:valid:focus + fieldset": {
          borderWidth: ".2rem",
        },

        "&:disabled": {
          fontWeight: "600",
        },
      },

      "& input:not(.Mui-disabled)": {},

      "& p": {
        margin: 0,
        marginTop: ".8rem",
      },

      "& label": {
        fontSize: "1.4em",
        fontWeight: "600",
        marginBottom: ".8rem",
        color: secondaryText,
      },

      "& legend": {
        width: 0,
      },

      "& .MuiInputLabel-formControl": {
        position: "relative",
        transform: "none",
      },

      "& .PrivateNotchedOutline-legendNotched-6": {
        width: 0,
      },

      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "none",
      },
      
      "&.MuiTextField-root": {
        minWidth: "2.5em",
        position: "relative",
      },

      "& .MuiFormHelperText-root": {
        fontSize: "1.2rem",
        fontStyle: "italic",
      },

      "& .MuiOutlinedInput-root": {
        borderRadius: ".8rem",
      },

      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: `.2rem solid ${error}`,
        position: "absolute",
        bottom: "0rem",
      },

      "& .MuiOutlinedInput-root.Mui-disabled": {
        background: disable,
      },

      "& .PrivateNotchedOutline-legendNotched-5": {
        maxWidth: 0,
      },
    },
  },
}))((props: any) => (
  <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
    <KeyboardDatePicker
      {...props}
      disableToolbar
      autoOk
      data-date-picker
      variant="inline"
      inputVariant="outlined"
      format="dd/MM/yyyy"
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />
  </MuiPickersUtilsProvider>
));
