import { createTheme } from "@material-ui/core/styles";

const {
  primary,
  primaryDark,
  secondary,
  secondaryDark,
  error,
  errorDark,
  success,
  successDark,
  alert,
  alertDark,
  backgroundColor,
  primaryText,
  secondaryText,
} = require('../ds/_palette.module.scss');


export default createTheme({
  palette: {
    primary: {
      main: primary,
      dark: primaryDark,
    },
    secondary: {
      main: secondary,
      dark: secondaryDark
    },
    error: {
      main: error,
      dark: errorDark
    },
    success: {
      main: success,
      dark: successDark
    },
    warning: {
      main: alert,
      dark: alertDark
    },
    background: {
      paper: backgroundColor,
      default: backgroundColor
    },
    text: {
      primary: primaryText,
      secondary: secondaryText,
    },
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      fontSize: '1.4em',
      letterSpacing: 0.7,
      font: 'unset',
      textTransform: 'none',
      fontWeight: 600,
      transform: 'none',
      whiteSpace: 'nowrap'
    },
    caption: {
      fontSize: '1.2em',
      letterSpacing: 0
    },
  }
});