import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MeusConteudosProvider from '../contexts/MeusConteudos';

import { ContainerPage } from '../layout';
import {
	HomePage,
	Http404Page,
	LoginPage,
	NovaContaPage,
	TrocarSenhaPage,
	AnexoFotoPage,
	MeusConteudosPage,
	MinhaContaPage,
	NovoConteudoPage,
} from '../pages';
import { VisualizacaoConteudoPage } from '../pages/VisualizacaoConteudo';

export const Routes: React.FC = () => {
	if (window.location.pathname === '/undefined') {
		window.location.href = '/';
	}

	const [isAutenticado] = useState<boolean>(!!localStorage.getItem('hea_token'));

	return (
		<BrowserRouter forceRefresh={false}>
			<Switch>
				<Route path="/" exact={true} component={LoginPage} />
				<Route path="/login" exact={true} component={LoginPage} />

				<ContainerPage>
					<Route path="/404" exact={true} component={Http404Page} />
					<Route path="/homepage" exact={true} component={HomePage} />
					<Route path="/nova-conta" exact={true} component={NovaContaPage} />
					<Route path="/trocar-senha/:hash" exact={true} component={TrocarSenhaPage} />

					{!!isAutenticado && (
						<>
							<Route path="/anexo-de-foto" exact={true} component={AnexoFotoPage} />
							<Route path="/minha-conta" exact={true} component={MinhaContaPage} />
							<MeusConteudosProvider>
								<Route path="/conteudos" exact={true} component={MeusConteudosPage} />
								<Route path="/meus-conteudos" exact={true} component={MeusConteudosPage} />
							</MeusConteudosProvider>
							<Route path="/conteudo/:id/visualizar" exact={true} component={VisualizacaoConteudoPage} />
							<Route path="/conteudo" exact={true} component={NovoConteudoPage} />
							<Route path="/conteudo/:id" exact={true} component={NovoConteudoPage} />
							<Route path="/conteudo/:id/anexo" component={NovoConteudoPage} exact={true} />
							<Route path="/conteudo/:id/concluir" component={NovoConteudoPage} exact={true} />
							<Route path="/conteudo/:id/concluido" component={NovoConteudoPage} exact={true} />
						</>
					)}
				</ContainerPage>
			</Switch>
		</BrowserRouter>
	);
};
