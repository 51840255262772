import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";

import { MeusConteudosState, useMeusConteudosContext } from "../../../../../../contexts/MeusConteudos";
import { ISelect } from "../../../../../../shared/interfaces";

export const FiltersSelected: React.FC = () => {
  const { state, handleChange, filtersSelected, setFiltersSelected } = useMeusConteudosContext();

  useEffect(() => {
    const lsFiltered = localStorage.getItem("ccc_filtro_meus_conteudos");

    if(!!lsFiltered) {
      setFiltersSelected(JSON.parse(lsFiltered));
    } else {
      setFiltersSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const push = <KeyType extends keyof MeusConteudosState>(
    key: KeyType,
    itemSelect: ISelect
  ) => {
    const selected = state[key] as ISelect;

    if (!!selected && !!itemSelect.value && selected.value !== "0") {
      const listFiltered = [
        ...(filtersSelected || []).filter(
          (item) =>
            item.type !== key ||
            (item.type === key && item.id !== itemSelect.value)
        ),
        {
          id: itemSelect.value,
          name: itemSelect.label,
          type: key,
        },
      ];

      setFiltersSelected(listFiltered);

      localStorage.setItem("ccc_filtro_meus_conteudos", JSON.stringify(listFiltered));
    }
  }

  const handleRemoveSelected = (selected: number) => {
    const itemRemoved = (filtersSelected || []).find((item, idx) => selected === idx);

    if(!!itemRemoved?.type) {
      handleChange(itemRemoved?.type, { value: "0" });
    }

    const result = (filtersSelected || []).filter((item, idx) => selected !== idx);

    localStorage.setItem("ccc_filtro_meus_conteudos", JSON.stringify(result));
    
    setFiltersSelected([...result]);
  }

  useEffect(() => {
    push("categoria", state.categoria);
    push("status", state.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.categoria, state.status]);

  return (
    <>
      {filtersSelected &&
        filtersSelected.map((item, idx) => {
          return (
            <div data-item-selected key={idx}>
              <span>{(item.name || "").toLocaleLowerCase()} </span>
              <div
                data-filter-button-remove
                onClick={() => {
                  handleRemoveSelected(idx);
                }}
              >
                <CloseIcon
                  style={{ height: "14px", width: "14px", color: "#B0B8BE" }}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};
