import React from "react";
import { makeStyles, Theme, Tooltip, TooltipProps } from "@material-ui/core";

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const AETooltip: React.FC<TooltipProps> = (props) => {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}