
/* eslint-disable no-lone-blocks */
import { useEffect, useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import WarningAmberIcon from '@material-ui/icons/WarningTwoTone';
import queryString from 'query-string';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { Pagination, AETooltip } from '../../../../../../shared/components';
import { buildHateos } from '../../../../../../services/hateoas.service';
import { useMeusConteudosContext } from '../../../../../../contexts/MeusConteudos';
import { splitTitle } from '../../../../../../shared/commom/formatText';
import { MessageBox } from '../../../../../../shared/components/MessageBox';
import { IHateoas } from "../../../../../../services/utils/response-data";

export const ResultData = () => {
	const { dataResult, state, setState } = useMeusConteudosContext();

	const [links, setLinks] = useState<IHateoas[]>([]);

	useEffect(() => {
		const _links = buildHateos('/meus-conteudos', dataResult.totalCount, state.limit, state.start);
		setLinks(_links);
	}, [state.limit, state.start, dataResult.totalCount]);

	const handleLimit = (limit: number) => {
		setState({ ...state, limit: limit === -1 ? 0 : limit, start: 0 });
	};

	const handlePagination = (hateoas: any) => {
		const items = queryString.parse(hateoas.href, { parseNumbers: true });
		const start = items.skip ? items.skip : (0 as number);
		setState({ ...state, start: start as number });
	};

	const handleSort = (e: any) => {
		e.preventDefault();
		const order = state.order === 'desc' ? 'asc' : 'desc';
		const sort = e.target.dataset.columns;

		setState({ ...state, start: 0, sort, order });
	};

	const config = [
		{
			name: 'ID',
			column: 'id',
			sortable: true,
		},
		{
			name: 'Título',
			column: 'titulo',
			sortable: true,
		},
		{
			name: 'Criação',
			column: 'data_criacao',
			sortable: true,
		},
		{
			name: 'Validade',
			column: 'data_validade',
			sortable: true,
		},
		{
			name: 'Tipo',
			column: 'tipo',
			sortable: true,
		},
		{
			name: 'Categoria',
			column: 'categoria',
			sortable: true,
		},
		{
			name: 'Status',
			column: 'status',
			sortable: true,
		},
	];

	return (
		<>
			{!!dataResult.error && <MessageBox data={dataResult.error} type="danger" />}

			{!!dataResult.data?.length && (
				<>
					<Pagination
						size={state.start}
						total={dataResult.totalCount}
						limit={state.limit}
						links={links}
						handleChange={handleLimit}
						handlePaginationClick={handlePagination}
					/>

					<TableContainer data-table-container>
						<Table size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<>
										{config.map((item, idx) => (
											<TableCell key={idx}>
												{item.sortable ? (
													<TableSortLabel
														data-columns={item.column}
														active={state.sort === item ? true : false}
														direction={state.order}
														onClick={handleSort}
													>
														{item.name}
													</TableSortLabel>
												) : (
													<>{item.name}</>
												)}
											</TableCell>
										))}
									</>
								</TableRow>
							</TableHead>
							<TableBody>
								{dataResult.data.map((item, idx) => (
									<TableRow key={idx}>
										<TableCell scope="row">{item.id}</TableCell>
										<TableCell scope="row">
											<div data-col-titulo>
												{item.expirado && (
													<AETooltip
														placement="top"
														title={item.titulo || ''}
														aria-label={`Faltam xx dias para o conteúdo expirar. Entre em contato com o autor`}
													>
														<WarningAmberIcon
															style={{
																width: '18px',
																height: '20px',
															}}
														/>
													</AETooltip>
												)}

												<AETooltip placement="top" title={item.titulo || ''} aria-label="Conteúdos">
													<Link
														to={`/conteudo/${item.id}/visualizar`}
														style={{
															marginLeft: item.expirado ? '0px' : '23px',
														}}
													>
														{splitTitle(item.titulo || '', 30)}
													</Link>
												</AETooltip>
											</div>
										</TableCell>
										<TableCell scope="row">{format(new Date(item.data_criacao), 'dd/MM/yyyy')}</TableCell>
										<TableCell scope="row">{format(new Date(item.data_validade), 'dd/MM/yyyy')}</TableCell>
										<TableCell scope="row">{item.tipo}</TableCell>
										<TableCell scope="row">{item.categoria}</TableCell>
										<TableCell scope="row">{item.status}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Pagination
						size={dataResult.data.length}
						total={dataResult?.totalCount}
						limit={state.limit}
						links={links}
						handleChange={handleLimit}
						handlePaginationClick={handlePagination}
					/>
				</>
			)}
		</>
	);
};
