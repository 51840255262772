/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import cx from "classnames";

import "./styles.scss";

interface AETabsProps {
  list: string[];
  callback(abaSelecionado: unknown): void;
}

export const AETabs: React.FC<AETabsProps> = (props) => {
  const [toggleTab, setToggleTab] = useState<string>(props.list[0]);

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: string) => {
    event.stopPropagation();
    setToggleTab(newValue);
    props.callback(newValue);
  };

  useEffect(() => {
    props.callback(props.list[0]);
  }, []);

  return (
    <div data-tabs>
      <div data-tab>
        <Tabs
          value={toggleTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
        >
          {props.list.map((item) => (
            <Tab
              value={item}
              key={item}
              label={item}
              className={cx({
                "data-tab-50": props.list.length === 2,
                "data-tab-33": props.list.length === 3,
              })}
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
};
