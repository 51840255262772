import React from "react";

import StepUnactivate from "../../../../assets/icons/step-unactivate.svg";
import StepChecked from "../../../../assets/icons/step-checked.svg";
import StepActivate from "../../../../assets/icons/step-activate.svg";

import "./styles.scss";

export type Steps = "person" | "professional" | "preference" | "register";

interface EtapasCadastroProps {
  formStep: number;
}

export const EtapasCadastro: React.FC<EtapasCadastroProps> = (props) => {
  return (
    <>
      <div data-steps-conteudo data-width="100">
        <div data-steps-container>
          <div data-step>
            <img src={props.formStep > 1 ? StepChecked : StepActivate} alt="" />
            <label className={props.formStep >= 1 ? "colorActive" : ""}>
              Dados do Objeto
            </label>
          </div>
          <div
            data-line
            className={props.formStep > 1 ? "colorLineActive" : ""}
          >
            {" "}
          </div>
          <div data-step>
            <img
              src={
                props.formStep < 2
                  ? StepUnactivate
                  : props.formStep > 2
                  ? StepChecked
                  : StepActivate
              }
              alt=""
            />
            <label className={props.formStep >= 2 ? "colorActive" : ""}>
              Anexo
            </label>
          </div>
          <div
            data-line
            className={props.formStep > 2 ? "colorLineActive" : ""}
          >
            {" "}
          </div>
          <div data-step>
            <img
              src={
                props.formStep < 3
                  ? StepUnactivate
                  : props.formStep > 3
                  ? StepChecked
                  : StepActivate
              }
              alt=""
            />
            <label className={props.formStep >= 3 ? "colorActive" : ""}>
              Concluir
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
