import React from "react";
import { Link } from "react-router-dom";

import useRedirect from "../../services/utils/redirect";
import { MenuUserLogged } from "./components/menu-logged";
import { IUsuarioLogado } from "../../contexts/Auth";
import {
  LogoEinstein,
  Logo_Einstein_Redondo,
  IconePerfil,
} from "../../assets/icons";

import "./styles.scss";

interface HeaderProps {
  usuarioLogado?: IUsuarioLogado;
  setArea(area: any): void;
  setOpenMenu(value: boolean): void;
  openMenu: boolean;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const redirectAfterLogin = useRedirect();

  return (
    <header data-header>
      <div data-header-title-mobile>
        <h2>Central de Cadastro de Conteúdo</h2>
      </div>

      <div data-wrapper>
        <div data-header-icons>
          {!!props.usuarioLogado && (
            <div
              data-header-hamburguer
              onClick={() => {
                props.setOpenMenu(!props.openMenu);
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
          <div data-header-logo>
            <img
              data-viewer-device="desktop"
              src={LogoEinstein}
              alt="Einstein"
              onClick={() => redirectAfterLogin()}
              width={225}
            />

            <img
              data-viewer-device="mobile"
              src={Logo_Einstein_Redondo}
              alt="Einstein"
            />
          </div>
        </div>

        <h2>CENTRAL DE CADASTRO DE CONTEÚDO</h2>

        {!!props.usuarioLogado ? (
          <MenuUserLogged user="administrador" />
        ) : (
          <div data-header-logon>
            <Link to="/">Entrar</Link>
            <img src={IconePerfil} alt="" />
          </div>
        )}
      </div>
    </header>
  );
};
