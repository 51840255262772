import { useEffect, useState } from 'react';

/* eslint-disable no-lone-blocks */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

import { AECheckbox, AETooltip } from '../../../../shared/components';
import { splitTitle } from '../../../../shared/commom/formatText';

import './styles.scss';
import { ConvitePendentesData, ConvitePendentesPayload } from '../../../../generated/graphql';
import { format } from 'date-fns/esm';
import { useSort } from '../../../../hooks/useSort';

interface SortColumn {
	name: string;
	column: string;
	sortable: boolean;
}

const config = [
	{
		name: '',
		column: '',
		sortable: false,
	},
	{
		name: 'ID',
		column: 'id',
		sortable: true,
	},
	{
		name: 'Título',
		column: 'titulo',
		sortable: true,
	},
	{
		name: 'Categoria',
		column: 'categoria',
		sortable: true,
	},
	{
		name: 'Tipo',
		column: 'tipo',
		sortable: true,
	},
	{
		name: 'Criação',
		column: 'data_criacao',
		sortable: true,
	},
];

interface ResultConvitesPendentesDataProps {
	handleSelecionados(values: string[]): void;
	result: ConvitePendentesPayload;
}

export const ResultConvitesPendentesData: React.FC<ResultConvitesPendentesDataProps> = (props) => {
	const [sort, setSort] = useState<SortColumn>(config[0]);
	const [order, setOrder] = useState<'asc' | 'desc'>('asc');
	const [selecionados, setSelecionados] = useState<string[]>([]);
	const [result, setResult] = useState(props.result.data || []);
	const sortList = useSort();

	useEffect(() => {
		props.handleSelecionados(selecionados);
	}, [selecionados]);

	const handleSort = (e: any) => {
		e.preventDefault();

		if (!props.result.data) return;

		const columnName = e.target.dataset.columns;
		setOrder(order === 'desc' ? 'asc' : 'desc');
		setSort(columnName);

		setResult(sortList<ConvitePendentesData>([...props.result.data], columnName, order === 'asc'));
	};

	const formatDate = (value?: string) => {
		let timestamp = 0;

		if (typeof value === 'string') {
			timestamp = parseInt(value);
		}

		const formated = timestamp ? format(new Date(timestamp), 'dd/MM/yyyy') : undefined;

		return formated;
	};

	return (
		<TableContainer data-table-container data-scrollbar>
			<Table size="small" aria-label="a dense table">
				<TableHead>
					<TableRow data-table-head>
						<>
							{config.map((item, idx) => (
								<TableCell key={idx}>
									{item.sortable ? (
										<TableSortLabel
											data-columns={item.column}
											active={sort === item ? true : false}
											direction={order}
											onClick={handleSort}
										>
											{item.name}
										</TableSortLabel>
									) : (
										<>{item.name}</>
									)}
								</TableCell>
							))}
						</>
					</TableRow>
				</TableHead>
				<TableBody>
					{result.map((item: ConvitePendentesData, idx) => (
						<TableRow key={idx}>
							<TableCell scope="row">
								<AECheckbox
									value={item.id}
									onClick={(ev: any) => {
										if (!!selecionados.some((selecionado) => selecionado === item.id) && !ev.target.checked) {
											setSelecionados([...selecionados.filter((selecionado) => selecionado !== item.id)]);
										} else if(!!ev.target.checked) {
											setSelecionados([...selecionados, item.id]);
										}
									}}
								></AECheckbox>
							</TableCell>
							<TableCell scope="row">{item.oe_id}</TableCell>
							<TableCell scope="row">
								<AETooltip placement="top" title={item.titulo || ''} aria-label="Conteúdos">
									<>{splitTitle(item.titulo || '', 30)}</>
								</AETooltip>
							</TableCell>
							<TableCell scope="row">{item.categoria}</TableCell>
							<TableCell scope="row">{item.tipo}</TableCell>
							<TableCell scope="row">{formatDate(item.data_criacao)}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
