import { useState, useEffect } from 'react';
import { MenuItem } from '@material-ui/core';

import { AESelect } from '../../../../../../shared/components';
import { useMeusConteudosContext } from '../../../../../../contexts/MeusConteudos';
import { StatusDePublicacao, useGetAllStatusPublicacoesLazyQuery } from '../../../../../../generated/graphql';

type StatusPublicacao = Pick<
	StatusDePublicacao,
	'id' | 'titulo' | 'enum_status' | 'status_pai' | 'substatus_de_publicacaos'
>;

export const SelectStatus = () => {
	const [list, setList] = useState<StatusPublicacao[]>([]);
	const [listSubStatus, setListSubStatus] = useState<StatusPublicacao[]>([]);
	const [subStatus] = useState('0');
	const { state, handleChange, setFiltersSelected, filtersSelected } = useMeusConteudosContext();

	const [getAll] = useGetAllStatusPublicacoesLazyQuery({
		onCompleted: (response) => {
			const values = (response?.statusDePublicacaosConnection?.values || []) as StatusPublicacao[];
			values && setList(values.filter((item) => item.status_pai) as StatusPublicacao[]);
		},
	});

	useEffect(() => {
		getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadSubStatus = (value: string) => {
		const statusPublicacao = list.find((item) => item.id.toString() === value);
		if (statusPublicacao && statusPublicacao.substatus_de_publicacaos) {
			setListSubStatus(statusPublicacao.substatus_de_publicacaos);
		}
	};

	return (
		<>
			<AESelect
				name="status"
				variant="outlined"
				data-combo-filter
				value={state.status.value}
				onChange={(ev) => {
					ev.preventDefault();
					const { value } = ev.target;

					if (state.status?.value === '0') {
						setListSubStatus([]);
					}

					handleChange('status', {
						value: (value as string) || '',
						label: list.find((item) => item.id.toString() === value)?.titulo || '',
					});

					setFiltersSelected([
						...(filtersSelected || []).filter(
							(item) => item.type !== 'status' || item.id !== value,
						),
						{
							id: (value as string) || '0',
							name: list.find((item) => item.id.toString() === value)?.titulo || '',
							type: 'status',
						},
					]);

					handleLoadSubStatus((value as string) || '');
				}}
				inputProps={{ 'aria-label': 'Without label' }}
			>
				<MenuItem value="0" selected={state.status.value === '0'}>
					Status
				</MenuItem>
				{list
					.filter((item) => item.status_pai)
					.map((item, idx) => (
						<MenuItem key={idx} value={item.id.toString()} selected={item.id.toString() === state.status.value}>
							{item.titulo}
						</MenuItem>
					))}
			</AESelect>

			{!!listSubStatus && !!listSubStatus.length && (
				<AESelect
					name="subStatus"
					variant="outlined"
					data-combo-filter
					value={subStatus}
					onChange={(ev) => {
						ev.preventDefault();
						const { value } = ev.target;

						setFiltersSelected([
							...(filtersSelected || []).filter((item) => item.type !== 'status' || item.id !== value),
							{
								id: (value as string) || '0',
								name: listSubStatus.find((item) => item.id.toString() === value)?.titulo || '',
								type: 'status',
							},
						]);
					}}
					inputProps={{ 'aria-label': 'Without label' }}
				>
					<MenuItem value="0" selected={subStatus === '0'}>
						Sub-status
					</MenuItem>
					{listSubStatus.map((item, idx) => (
						<MenuItem key={idx} value={item.id} selected={item.id.toString() === subStatus}>
							{item.titulo}
						</MenuItem>
					))}
				</AESelect>
			)}
		</>
	);
};
