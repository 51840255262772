import { useFormik } from "formik";
import * as Yup from "yup";

import { AEButton, AETextField } from "../../../../../../shared/components";
import { modalErro } from "../../../../../../shared/Modals/modalError/modal-erro";
import { AutorConvidado } from "../../../../../../generated/graphql";

import "./styles.scss";

interface Props {
  autoresConvidados: AutorConvidado[];
  handleAutoresConvidadosPorEmail(autores: AutorConvidado[]): void;
}

export const ConvidarAutores = (props: Props) => {
  const getAutor = async (email: string) => {
    const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace(
      "/graphql",
      ""
    )}/v2/perfil-de-autors?email_contains=${email}`;

    return await fetch(URL, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!!data.length) {
          return true;
        }
        return false;
      });
  };

  const formik = useFormik<{ nome: string; email: string }>({
    initialValues: { nome: "", email: "" },
    validationSchema: Yup.object().shape({
      nome: Yup.string().required("*campo obrigatório"),
      email: Yup.string()
        .email("*formato inválido")
        .required("*campo obrigatório"),
    }),
    onSubmit: async (values) => {
      if (!values.email) {
        return;
      }

      if (!values.nome) {
        return;
      }

      const autorExisteNaBase = await getAutor(values.email);

      if (autorExisteNaBase) {
        modalErro(
          "Não foi possível convidar esse autor",
          "Um autor já está cadastrado com esse e-mail."
        );
        return;
      }

      const autorJaFoiSelecionado = props.autoresConvidados.some(
        (autor) => autor.email === values.email
      );

      if (autorJaFoiSelecionado) {
        modalErro(
          "Não foi possível convidar esse autor",
          "Esse autor já foi adicionado a lista para ser convidado."
        );
        return;
      }

      const listaAutores: AutorConvidado[] = [...props.autoresConvidados];
      listaAutores.push({
        nome: values.nome,
        email: values.email,
        id: "",
        created_at: undefined,
        updated_at: undefined
      });
      props.handleAutoresConvidadosPorEmail(listaAutores);
    },
  });

  return (
    <div data-form-convidar-autores >
      <span className="error desktop-only">
        Autor não cadastrado. Envie o convite abaixo para que o mesmo possa
        efetuar o seu cadastro na Central de Cadastro de Conteúdo da Academia
        Digital Einstein. Após a finalização do cadastro ele será vinculado
        automaticamente a esse conteúdo.
      </span>
      <span className="error mobile-only">
        Autor não cadastrado. Envie o convite abaixo para que o mesmo possa
        efetuar o seu cadastro na Central de Cadastro de Conteúdo da Academia
        Digital Einstein.
      </span>
      <form data-form-convite-autor>
        <div data-form-row>
          <AETextField
            id="nome"
            name="nome"
            label="Nome*"
            color="primary"
            type="nome"
            value={formik.values.nome}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.nome ? formik.errors.nome : null)}
          />
        </div>
        <div data-form-row-email-convite>
          <AETextField
            id="email"
            name="email"
            label="E-mail*"
            color="primary"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email ? formik.errors.email : null)}
          />
          <AEButton
            color="primary"
            variant="contained"
            onClick={() => formik.submitForm()}
          >
            Enviar Convite
          </AEButton>
        </div>
      </form>
    </div>
  );
};
